import axios from "axios";
import qs from "qs";
import { CONFIG } from "../../config";
import {
  BetTicketInfoPayload,
  PlaceBetTicketPayload,
  GetBetTicketPayload,
  GetReportTicketPayload,
} from "../../types/betTicket";

class BetTicket {
  public getBetTickets = async (payload: GetBetTicketPayload) => {
    const result = await axios.post(
      `${CONFIG.API_URL}/c/m/runningList`,
      payload
    );

    return result?.data?.data;
  };

  public getReportTickets = async (payload: GetReportTicketPayload) => {
    const result = await axios.get(
      `${CONFIG.API_URL}/report/ticket?${qs.stringify(payload)}`
    );

    return result.data;
  };

  public placeBetTicket = async (payload: PlaceBetTicketPayload) => {
    const result = await axios.post(
      `${CONFIG.API_URL}/c/m/sb/placeBet`,
      payload
    );

    return result?.data;
  };

  public getBetTicketInfo = async (payload: BetTicketInfoPayload) => {
    const { leagueId, ...payloadWithoutLeagueId } = payload;

    const result = await axios.post(`${CONFIG.API_URL}/c/m/sb/betInfo`, {
      ...payloadWithoutLeagueId,
      matchId: `${leagueId}:${payload.matchId}`,
    });

    return result?.data?.data;
  };
}

export default BetTicket;
