import ModalUnstyled, { ModalUnstyledOwnProps } from "@mui/base/ModalUnstyled";
import classNames from "classnames";
import { FC, forwardRef, PropsWithChildren, HTMLAttributes } from "react";

const BackdropUnstyled = forwardRef<
  HTMLDivElement,
  { open?: boolean } & HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={classNames(
        { "MuiBackdrop-open": open },
        "fixed right-0 bottom-0 top-0 left-0",
        className
      )}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
      ref={ref}
      {...other}
    />
  );
});

const Modal: FC<PropsWithChildren<ModalUnstyledOwnProps>> = ({
  children,
  ...rest
}) => {
  return (
    <ModalUnstyled
      {...rest}
      slots={{ backdrop: BackdropUnstyled }}
      slotProps={{
        root: {
          className:
            "fixed right-0 bottom-0 top-0 left-0 flex align-items-center justify-content-center",
        },
      }}
      style={{ zIndex: 2000 }}
    >
      <div
        className="flex flex-col absolute p-4 rounded-xl bg-white"
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1000,
          maxHeight: "80%",
        }}
      >
        {children}
      </div>
    </ModalUnstyled>
  );
};

export default Modal;
