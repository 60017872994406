import query from "../../query";

export const mapBetTicketInfoQueries = (data: any[]) => {
  
  return data.map(
    ({
      type,
      table,
      sportType,
      betType,
      option,
      matchId,
      leagueId,
      riskLevel,
      specifiers,
      outcomeId,
      marketId,
    }) => {
 
      const betTicketInfoQuery = query.betTicketInfo({
        type,
        table,
        sportType,
        betType,
        option,
        matchId,
        leagueId,
        riskLevel,
        specifiers,
        outcomeId,
        marketId,
      });

      return {
        queryKey: betTicketInfoQuery.key,
        queryFn: betTicketInfoQuery.queryFn,
        keepPreviousData: true,
        refetchOnMount: false,
      };
    }
  );
};
