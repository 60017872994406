const betTypeDisplay = (options: number, betType: string, teamName: Record<string, any>): string => {
    // return `options : ${options} | betType : ${betType}`
  if (betType === "ah" || betType === "ah1st") {
    if (options === 0) {
      return teamName.h;
    } else if (options === 1) {
      return teamName.a;
    }
  }

  if (betType === "ou" || betType === "ou1st") {
    if (options === 0) {
      return "Over";
    } else if (options === 1) {
      return "Under";
    }
  }

  if (betType === "x12" || betType === "x121st") {
    if (options === 0) {
      return `${teamName.h} [Win]`;
    } else if (options === 1) {
      return `${teamName.a} [Win]`;
    } else if (options === 2) {
      return `Draw`;
    }
  }

  if (betType === "oe" || betType === "oe1st") {
    if (options === 0) {
      return `Odd`;
    } else if (options === 1) {
      return `Even`;
    }
  }

  return "";
};

export default betTypeDisplay;
