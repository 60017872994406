import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import query from "../../query";
import {
  BetTicketInfoForDisplay,
  BetTicketInfoPayload,
} from "../../types/betTicket";
import mutation from "../../mutation";
import produce from "immer";
import { toast } from "react-toastify";

export type pushToBetInfoPayload = (
  payload: BetTicketInfoPayload & BetTicketInfoForDisplay
) => void;

const useBetInfo = () => {
  const queryClient = useQueryClient();
  const betTypeQuery = query.betType();
  const betType = useQuery(betTypeQuery.key, betTypeQuery.queryFn, {
    refetchOnMount: false,
    keepPreviousData: true,
  });

  const updateBetTicketInfoPayload = useMutation(
    mutation.updateBetTicketInfoPayload,
    {
      onSuccess: (payload) => {
        queryClient.setQueryData(["betTicketInfoPayload"], payload);
      },
    }
  );

  const updateCurrentTabIndex = useMutation(mutation.updateCurrentTabIndex, {
    onSuccess: (index) => {
      queryClient.setQueryData(["currentTabIndex"], index);
    },
  });

  const updateIsBetSlipToggle = useMutation(mutation.updateIsBetSlipToggle, {
    onSuccess: (newIsBetSlipToggle) => {
      queryClient.setQueryData(["isBetSlipToggle"], newIsBetSlipToggle);
    },
  });

  const pushToBetInfoPayload: pushToBetInfoPayload = useCallback(
    async (payload: BetTicketInfoPayload & BetTicketInfoForDisplay) => {
      const betTicketInfoPayloads =
        queryClient.getQueryData<
          (BetTicketInfoPayload & BetTicketInfoForDisplay)[]
        >(["betTicketInfoPayload"]) ?? [];
      const index = betTicketInfoPayloads.findIndex((betTicketInfoPayload) => {
        return betTicketInfoPayload.matchId === payload.matchId;
      });

      const exactMatchIndex =
        index > -1
          ? betTicketInfoPayloads.findIndex((betTicketInfoPayload) => {
              return (
                betTicketInfoPayload.matchId === payload.matchId &&
                betTicketInfoPayload.option === payload.option &&
                betTicketInfoPayload.riskLevel === payload.riskLevel &&
                betTicketInfoPayload.betType === payload.betType
              );
            })
          : -1;

      if (betType?.data === "normal") {
        if (exactMatchIndex >= 0) {
          await updateBetTicketInfoPayload.mutate([]);
        } else {
          await updateBetTicketInfoPayload.mutate([payload]);
          updateCurrentTabIndex.mutate(0);
          updateIsBetSlipToggle.mutate(true);
        }
      } else {
        if (index >= 0) {
          const newBetTicketInfoPayload = produce(
            betTicketInfoPayloads,
            (draft) => {
              if (exactMatchIndex >= 0) {
                draft.splice(index, 1);
              } else {
                draft[index] = payload;
              }
            }
          );
          await updateBetTicketInfoPayload.mutate(newBetTicketInfoPayload);
        } else {
          if (betTicketInfoPayloads.length >= 12) {
            toast.error("This betting is reached the maximun limit");
            return;
          }

          await updateBetTicketInfoPayload.mutate([
            ...betTicketInfoPayloads,
            payload,
          ]);

          if (betTicketInfoPayloads.length === 11) {
            updateCurrentTabIndex.mutate(0);
            updateIsBetSlipToggle.mutate(true);
          }
        }
      }
    },
    [
      betType?.data,
      queryClient,
      updateBetTicketInfoPayload,
      updateCurrentTabIndex,
      updateIsBetSlipToggle,
    ]
  );

  return {
    pushToBetInfoPayload,
  };
};

export default useBetInfo;
