import { useChangeDetector } from "./useChangeDetector";

export type ChangedType = "increase" | "decrease" | undefined;

export type OddChangeDetectorProps = {
  homeHandicap: string;
  awayHandicap: string;
  drawHandicap?: string;
  homePrice: string;
  awayPrice: string;
  drawPrice?: string;
  type?: string;
};

export const TIMEOUT_DELEY = 10 * 1000;

const useOddChangeDetector = (asiaHandicap: OddChangeDetectorProps) => {
  const { priceChangedType: homePriceChangedType } = useChangeDetector(
    asiaHandicap["homePrice"],
    asiaHandicap["homeHandicap"]
  );
  const { priceChangedType: awayPriceChangedType } = useChangeDetector(
    asiaHandicap["awayPrice"],
    asiaHandicap["awayHandicap"]
  );
  const { priceChangedType: drawPriceChangedType } = useChangeDetector(
    asiaHandicap["drawPrice"],
    asiaHandicap["drawHandicap"]
  );

  return {
    homePriceChangedType,
    awayPriceChangedType,
    drawPriceChangedType,
  };
};

export default useOddChangeDetector;
