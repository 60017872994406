import { useState, useCallback } from "react";
import useToggleState from "../../hooks/useToggleState";
import { FormData } from "./LeagueFilterForm";

const defaultState = {
  isAllSelected: true,
  selectedLeagues: [],
};

const useLeagueFilterModal = () => {
  const { isToggled, toggle } = useToggleState();
  const [isAllSelected, setIsAllSelected] = useState<boolean>(
    defaultState.isAllSelected
  );
  const [selectedLeagues, setSelectedLeagues] = useState<string[]>(
    defaultState.selectedLeagues
  );

  const onSubmit = useCallback(
    (data: FormData) => {
      setIsAllSelected(data.isAllSelected);
      setSelectedLeagues(data.selectedLeagues);
      toggle();
    },
    [toggle]
  );

  const getSelectedLeaguesNo = (totalLeagueNo: number) =>
    isAllSelected
      ? totalLeagueNo - selectedLeagues.length
      : selectedLeagues.length;

  const filterLeagues = (leagues: any) =>
    leagues?.filter((league: any) => {
      if (isAllSelected) {
        return !selectedLeagues.includes(`${league.k}`);
      } else {
        return selectedLeagues.includes(`${league.k}`);
      }
    });

  const formatOptions = (leagues: any) =>
    leagues?.map((league: any) => ({
      label: league.nn.en,
      value: league.k,
    }));

  return {
    defaultState,
    filterLeagues,
    formatOptions,
    getSelectedLeaguesNo,
    isAllSelected,
    isToggled,
    onSubmit,
    selectedLeagues,
    setIsAllSelected,
    setSelectedLeagues,
    toggle,
  };
};

export default useLeagueFilterModal;
