import styled from "styled-components";

export const StyledInput = styled.input`
  border: 1px solid gray;
`;

export const Suffix = styled.div`
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const Wrapper = styled.form<{ isLive?: boolean }>`
  background-color: ${({ theme, isLive }) =>
    isLive
      ? theme.sportLive.TableBackground
      : theme.sportNonLive.TableBackground};
`;

export const ClickAbled = styled.span.attrs({
  className: "text-sm",
})<{ disabled?: boolean }>`
  text-decoration: ${(props) => (props.disabled ? "initial" : "underline")};
  cursor: ${(props) => (props.disabled ? "initial" : "pointer")};
  color: ${(props) => (props.disabled ? "initial" : "#2563EB")};
`;
