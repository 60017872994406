import { FC, HTMLAttributes, ReactNode } from "react";
import _ from "lodash"

export type BreadCrumbProps = {
  path: string; // example: "/football/today"
};

const BreadCrumb: FC<BreadCrumbProps & HTMLAttributes<HTMLDivElement>> = ({
  className = "",
  path,
}) => {
  const splitted = path.split("/");

  return (
    <nav className={`rounded-md w-full text-2xl ${className}`}>
      <ol className="list-reset flex">
        {splitted.filter(Boolean).reduce(
          (result: ReactNode[], each: string, index: number) =>
            splitted.filter(Boolean).length - 1 === index
              ? [
                  ...result,
                  <li key={each}>
                    <span className="text-gray-500">{_.capitalize(each)}</span>
                  </li>,
                ]
              : [
                  ...result,
                  <li key={each}>
                    <span>{_.capitalize(each)}</span>
                  </li>,
                  <li key={`/${each}`}>
                    <span className="text-gray-500 mx-2">/</span>
                  </li>,
                ],
          []
        )}
      </ol>
    </nav>
  );
};

export default BreadCrumb;
