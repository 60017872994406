import { useState, useCallback } from "react";
import { DateValueType } from "react-tailwindcss-datepicker/dist/types";

export const useDateRangeFilter = () => {
  const [value, setValue] = useState<DateValueType>({
    startDate: new Date(),
    endDate: new Date(),
  });

  const onChange = useCallback((dateRange: DateValueType) => {
    setValue(dateRange);
  }, []);

  return {
    value,
    onChange,
  };
};
