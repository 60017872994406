import styled from "styled-components";

export const StyledTable = styled.div`
  & th {
    background-color: ${({ theme }) => theme.sportNonLive.HeaderBackground};
    color: white; // TODO: we will add this one to theme object later
    font-size: 14px;
    border: 1px solid ${({ theme }) => theme.sportNonLive.Border};
    padding: 8px;
  }

  & td {
    border: 1px solid ${({ theme }) => theme.sportNonLive.Border};
    padding: 8px;
    font-size: 14px;
    white-space: pre-wrap;
  }

  & th.league {
    background-color: ${({ theme }) =>
      theme.sportNonLive.TitleLeagueBackground};
  }

  & tr.even-row {
    background-color: ${({ theme }) => theme.sportNonLive.TableBackground};
  }

  & tr.odd-row {
    background-color: ${({ theme }) => theme.sportNonLive.TableHardBackground};
  }

  & td:nth-last-child(-n + 2) {
    text-align: center;
    vertical-align: middle;
  }

  & td:first-child {
    vertical-align: middle;
  }
`;
