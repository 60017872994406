import moment from "moment";
import { useEffect, useState, useRef } from "react";

const Clock = () => {
  const intervalRef = useRef<NodeJS.Timer>();
  const [time, setTime] = useState(
    `${moment().format("DD/MM/YYYY HH:mm A")} GMT+7`
  );

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTime(`${moment().format("DD/MM/YYYY HH:mm A")} GMT+7`);
    }, 5000);

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);
  return <>{time}</>;
};

export default Clock;
