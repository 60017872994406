import { useCallback, useState } from "react";
import { OPTIONS } from "./config";

export const useBetTypeFilter = () => {
  const [selectedBetTypes, setSelectedBetTypes] = useState([OPTIONS[0].value]);
  const onChange = useCallback(
    (option: { label: string; value: string }) => {
      const isSelected = selectedBetTypes.includes(option.value);
      if (isSelected && option.value !== "all") {
        const newSelectedSports = selectedBetTypes.filter(
          (selectedSport) => selectedSport !== option.value
        );
        if (newSelectedSports.length === 0) {
          setSelectedBetTypes(["all"]);
        } else {
          setSelectedBetTypes(newSelectedSports);
        }
      } else if (option.value === "all") {
        setSelectedBetTypes([option.value]);
      } else {
        setSelectedBetTypes(
          [...selectedBetTypes, option.value].filter((each) => each !== "all")
        );
      }
    },
    [selectedBetTypes]
  );

  return {
    selectedBetTypes,
    setSelectedBetTypes,
    onChange,
  };
};
