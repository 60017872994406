import styled from "styled-components";

export const BetTicketInfoWrapper = styled.div<{ isLive?: boolean }>`
  background-color: ${({ theme, isLive }) =>
    isLive
      ? theme.sportLive.TableBackground
      : theme.sportNonLive.TableBackground};
`;

export const BetTicketInfoHeader = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.global.Primary};
  color: ${({ theme }) => theme.global.textColorInPrimary};
  padding: 10px;
  border-top: 1px solid ${({ theme }) => theme.global.textColorInPrimary};
  border-bottom: 1px solid ${({ theme }) => theme.global.textColorInPrimary};
  font-size: 14px;
  font-weight: bold;
`;

export const BetTicketInfoType = styled.div<{ isLive?: boolean }>`
  background-color: ${({ theme, isLive }) =>
    isLive
      ? theme.sportLive.HeaderBackground
      : theme.sportNonLive.HeaderBackground};
  color: ${({ theme }) => theme.global.textColorInPrimary};
  border-bottom: 1px solid ${({ theme }) => theme.global.textColorInPrimary};
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LeagueName = styled.div`
  color: ${({ theme }) => theme.global.textColorInPrimaryOpacity};
`;

export const PriceInfo = styled.div<{ isLive?: boolean }>`
  border: 1px solid
    ${({ theme, isLive }) =>
      isLive ? theme.sportLive.Border : theme.sportNonLive.Border};
  padding: 3px;
  border-radius: 8px;
  width: 100%;
  background-color: white;
`;

export const HorizontalLine = styled.hr<{ isLive?: boolean }>`
  border-top: 1px solid
    ${({ theme, isLive }) =>
      isLive
        ? theme.sportLive.HeaderBackground
        : theme.sportNonLive.HeaderBackground};
`;
