import styled from "styled-components";

export const Scroll = styled.div`
  overflow-y: auto;
`;

export const Wrapper = styled.div.attrs({
  className: "flex justify-center items-center",
})`
  background-color: ${({ theme }) => theme.global.SecondaryHard};
`;
