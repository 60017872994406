import classNames from "classnames";
import { FC, HTMLAttributes } from "react";
import Collapse from "@material-tailwind/react/components/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import useToggleState from "../../hooks/useToggleState";
import { CricketFeed } from "../../types/cricketFeed";
import CricketTable from "../../components/CricketTable";
import {
  BetTicketInfoForDisplay,
  BetTicketInfoPayload,
} from "../../types/betTicket";

export type LeagueCardProps = {
  isLive?: boolean;
  onClickOddPrice?: (
    payload: BetTicketInfoPayload & BetTicketInfoForDisplay
  ) => void;
} & CricketFeed &
  HTMLAttributes<HTMLDivElement>;

const LeagueCard: FC<LeagueCardProps> = (props) => {
  const { isToggled, toggle } = useToggleState(true);

  return (
    <div key={props.leagueId}>
      <div
        className={classNames(
          [
            "px-2 flex justify-between cursor-pointer border border-solid",
            props.className,
          ],
          {
            "border-green-700 bg-green-700": !props.isLive,
            "border-orange-700 bg-orange-700": props.isLive,
          }
        )}
        onClick={toggle}
      >
        <div>
          <span className="text-white">{props.leagueName.en}</span>
          <b className="text-xs bg-white px-1 rounded align-middle ml-4">
            {props.matches?.length ?? 0}
          </b>
        </div>
        <div>
          <ExpandLessIcon
            style={{
              transition: "transform 200ms",
            }}
            className={classNames("text-white", {
              "rotate-180": !isToggled,
              "rotate-0": isToggled,
            })}
          />
        </div>
      </div>
      <Collapse open={isToggled}>
        {props.matches?.map((match, index) => {
          return (
            <CricketTable
              leagueName={props.leagueName.en}
              leagueId={props.leagueId}
              className={classNames({
                "bg-green-100": !props.isLive && index % 2 === 1,
                "bg-green-50": !props.isLive && index % 2 === 0,
                "bg-orange-100": props.isLive && index % 2 === 1,
                "bg-orange-50": props.isLive && index % 2 === 0,
              })}
              battingTeam={
                `${match.stats.battingId}` === `${match.teams.home.id}`
                  ? "home"
                  : "away"
              }
              key={match.matchId}
              matchId={match.matchId}
              homeTeam={match.teams.home.en}
              awayTeam={match.teams.away.en}
              isLive={props.isLive}
              homeScore={match.stats.homeScore}
              awayScore={match.stats.awayScore}
              markets={match.markets}
              startTime={match?.startTime}
              onClickOddPrice={props.onClickOddPrice}
            />
          );
        })}
      </Collapse>
    </div>
  );
};

export default LeagueCard;
