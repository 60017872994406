import { FC, HTMLAttributes } from "react";

export type LoadingProps = {
  size?: number;
};

const Loading: FC<LoadingProps & HTMLAttributes<HTMLDivElement>> = ({
  size = 40,
  className = "",
}) => {
  return (
    <img
      className={`animate-spin ${className}`}
      src={`${process.env.PUBLIC_URL}/svg/loading.svg`}
      alt="loading"
      width={size}
      height={size}
    />
  );
};

export default Loading;
