import { FC } from "react";
import { Button, ButtonType } from "../../components/Button";
import { OPTIONS } from "./config";

export type SportFilterProps = {
  onChange: (option: { label: string; value: string }) => void;
  selected: string[];
};

const SportFilter: FC<SportFilterProps> = ({ selected, onChange }) => {
  return (
    <div className="flex space-x-3">
      {OPTIONS.map((option) => {
        const isSelected = selected.includes(option.value);
        return (
          <Button
            className="px-2"
            buttonType={isSelected ? ButtonType.PRIMARY : ButtonType.DEFAULT}
            key={option.value}
            onClick={() => {
              onChange(option);
            }}
          >
            {option.label}
          </Button>
        );
      })}
    </div>
  );
};

export default SportFilter;
