import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC, useEffect } from "react";
import mutation from "../../mutation";
import SportTableLiveAndToday from "../../contrainers/SportTableLiveAndToday";

const FootBallParlay: FC = () => {
  const queryClient = useQueryClient();
  const updateOddsType = useMutation(mutation.updateOddsType, {
    onSuccess: (value) => {
      queryClient.setQueryData(["oddsType"], value);
    },
  });
  const updateBetType = useMutation(mutation.updateBetType, {
    onSuccess: (value) => {
      queryClient.setQueryData(["betType"], value);
    },
  });

  const updateBetTicketInfoPayload = useMutation(
    mutation.updateBetTicketInfoPayload,
    {
      onSuccess: (payload) => {
        queryClient.setQueryData(["betTicketInfoPayload"], payload);
      },
    }
  );

  useEffect(() => {
    updateBetTicketInfoPayload.mutate([]);
    updateOddsType.mutate(2); // euro odds type
    updateBetType.mutate("step");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SportTableLiveAndToday
      todayTitle="Football Parlay/Today"
      liveTitle="Football Parlay/Live"
      sport="football"
    />
  );
};

export default FootBallParlay;
