import styled from "styled-components";

export const Handicap = styled.div`
  color: ${({ theme }) => theme.global.textColorTeamAndHandicap};
  font-size: 0.65rem;
  line-height: 1.5rem;
  font-weight: 700;
`;

export const TeamName = styled.div<{ isGoodTeam?: boolean; color?: string }>`
  color: ${({ theme, isGoodTeam, color }) =>
    isGoodTeam
      ? theme.global.textColorGoodTeamAndPrice
      : color
      ? color
      : "black"};
  font-weight: bold;
`;

export const OddPrice = styled.span<{
  isRedOdd?: boolean;
  isIncrease?: boolean;
  isDecrease?: boolean;
  offset?: number;
}>`
  line-height: 1.5rem;
  font-weight: 700;
  color: ${({ theme, isRedOdd }) =>
    isRedOdd ? theme.global.textColorGoodTeamAndPrice : "initial"};
  cursor: pointer;
  background-color: ${({ isIncrease, isDecrease, theme }) =>
    isIncrease
      ? theme.global.AlertSuccessBackground
      : isDecrease
      ? theme.global.AlertWarningBackground
      : "initial"};
  animation: ${({ isIncrease, isDecrease }) =>
    isIncrease || isDecrease ? "blink 0.5s linear infinite" : "none"};

  ${({ isDecrease, offset }) =>
    isDecrease
      ? `&::after {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: "";
    border-top: 6px solid red;
    position: absolute;
    right: ${offset ? offset + "px" : "-13px"};
    top: 8px;
    animation: bounce 0.6s infinite;
  };
  `
      : ""}

  ${({ isIncrease, offset }) =>
    isIncrease
      ? `&::after {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid green;
      content: "";
      position: absolute;
      right: ${offset ? offset + "px" : "-13px"};
      top: 8px;
      animation: bounce 0.6s infinite;
    };
    `
      : ""}
`;
