export const THEME = {
  global: {
    BackgroundInside: "#f1f2f8",
    BackgroundOutside: "#dee0ea",
    MenuHeader: "#ffffff",
    textColorInMenuHeader: "#7e8890",
    Primary: "#115e3f",
    PrimaryHard: "#186346",
    PrimaryOpacity: "#dae9e6",
    PrimaryOpacity2: "#f3f9f9",
    Secondary: "#fde60d",
    borderSecondary: "#f7ae18",
    SecondaryHard: "#e2cf27",
    Default: "#dee0ea",
    textColorInPrimary: "#ffffff",
    textColorInSecondary: "#000000de",
    textColorInPrimaryOpacity: "#636A70",
    textColorGoodTeamAndPrice: "#f44336", // new
    textColorTeamAndHandicap: "#0000ff", // new
    BackgroundColorThemeHome1: "#020024",
    BackgroundColorThemeHome2: "#115e3f",
    BackgroundColorThemeHome3: "#81d5e6",
    AlertSuccessBackground: "#c7fbc7",
    AlertWarningBackground: "#fac6c6",
  },
  sportLive: {
    Border: "#fbbca4",
    HeaderBackground: "#b63119",
    TitleLeagueBackground: "#f47f52", 
    TableBackground: "#fee9dc66",
    TableHardBackground: "#fdc",
    TitleTextColor: "#ffffff",
    TableTextColor: "#000000",
  },
  sportNonLive: {
    Border: "#5fd5b2",
    HeaderBackground: "#115e3f",
    TitleLeagueBackground: "#14966c",
    TableBackground: "#e5f9f2",
    TableHardBackground: "#8ef3d1",
    TitleTextColor: "#ffffff",
    TableTextColor: "#000000",
  },
  table: {
    evenRow: "#fafafa",
    oddRow: "#ffffff",
  },
};
