import { CellContext } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { FormattedFeed, WinLose } from "../../../types/feed";
import { OddPrice } from "../../../components/Typography";
import { CustomCellContext } from "../../../types/table";
import { isGoodTeam } from "../../../utils/isGoodTeam";
import useOddChangeDetector from "../../../hooks/useOddChangeDetector";

const X12 = (row: CellContext<FormattedFeed, unknown> & CustomCellContext) => {
  const { i18n } = useTranslation();
  const winLose = row.getValue<WinLose>();
  const id = row.column.id;
  const [leagueId, matchId] = row.row.original.match_id.split(":");
  const { match_n: matchName, tableType, sport } = row.row.original;
  const { homePriceChangedType, awayPriceChangedType, drawPriceChangedType } =
    useOddChangeDetector({
      homeHandicap: winLose?.hk || "",
      awayHandicap: winLose?.ak || "",
      drawHandicap: winLose?.dk || "",
      homePrice: winLose?.h || "0",
      awayPrice: winLose?.a || "0",
      drawPrice: winLose?.d || "0",
    });

  if (!winLose) {
    return "";
  }

  const betType = id === "FULL_TIME_X12" ? "x12" : "x121st";
  const betTypeKey = betType === "x12" ? "betPrice_x12" : "betPrice_x121st";

  return (
    <>
      <div className="font-bold text-center">
        <OddPrice
          className="relative"
          isIncrease={homePriceChangedType === "increase"}
          isDecrease={homePriceChangedType === "decrease"}
          isRedOdd={winLose?.h.includes("-")}
          onClick={() =>
            row?.onClickOddPrice?.({
              leagueId,
              matchId,
              option: 0,
              betType,
              sportType: sport.toUpperCase() as "FOOTBALL" | "BASKETBALL",
              table: tableType,
              type: row?.betType || "normal",
              home: matchName[i18n.language].h,
              away: matchName[i18n.language].a,
              isHomeGood: isGoodTeam(row.row.original?.betPrice_ah?.h),
              isAwayGood: isGoodTeam(row.row.original?.betPrice_ah?.a),
              league: row.row.original?.feed_nn,
              riskLevel: row.row.original?.betPrice_order,
              fhid: `${matchName.fhid}`,
              faid: `${matchName.faid}`,
              outcomeId: row.row.original?.[betTypeKey]?.och,
              specifiers: row.row.original?.[betTypeKey]?.sp,
              marketId: row.row.original?.[betTypeKey]?.mkid,
            })
          }
        >
          {Number(winLose?.h)?.toFixed(2)}
        </OddPrice>
      </div>
      <div className="font-bold text-center">
        <OddPrice
          className="relative"
          isIncrease={awayPriceChangedType === "increase"}
          isDecrease={awayPriceChangedType === "decrease"}
          isRedOdd={winLose?.a.includes("-")}
          onClick={() =>
            row?.onClickOddPrice?.({
              leagueId,
              matchId,
              option: 1,
              betType,
              sportType: sport.toUpperCase() as "FOOTBALL" | "BASKETBALL",
              table: tableType,
              type: row?.betType || "normal",
              home: matchName[i18n.language].h,
              away: matchName[i18n.language].a,
              isHomeGood: isGoodTeam(row.row.original?.betPrice_ah?.h),
              isAwayGood: isGoodTeam(row.row.original?.betPrice_ah?.a),
              league: row.row.original?.feed_nn,
              riskLevel: row.row.original?.betPrice_order,
              fhid: `${matchName.fhid}`,
              faid: `${matchName.faid}`,
              outcomeId: row.row.original?.[betTypeKey]?.oca,
              specifiers: row.row.original?.[betTypeKey]?.sp,
              marketId: row.row.original?.[betTypeKey]?.mkid,
            })
          }
        >
          {Number(winLose?.a)?.toFixed(2)}
        </OddPrice>
      </div>
      <div className="font-bold text-center">
        <OddPrice
          className="relative"
          isRedOdd={winLose?.d.includes("-")}
          isIncrease={drawPriceChangedType === "increase"}
          isDecrease={drawPriceChangedType === "decrease"}
          onClick={() =>
            row?.onClickOddPrice?.({
              leagueId,
              matchId,
              option: 2,
              betType,
              sportType: sport.toUpperCase() as "FOOTBALL" | "BASKETBALL",
              table: tableType,
              type: row?.betType || "normal",
              home: matchName[i18n.language].h,
              away: matchName[i18n.language].a,
              isHomeGood: isGoodTeam(row.row.original?.betPrice_ah?.h),
              isAwayGood: isGoodTeam(row.row.original?.betPrice_ah?.a),
              league: row.row.original?.feed_nn,
              riskLevel: row.row.original?.betPrice_order,
              fhid: `${matchName.fhid}`,
              faid: `${matchName.faid}`,
              outcomeId: row.row.original?.[betTypeKey]?.ocd,
              specifiers: row.row.original?.[betTypeKey]?.sp,
              marketId: row.row.original?.[betTypeKey]?.mkid,
            })
          }
        >
          {Number(winLose?.d)?.toFixed(2)}
        </OddPrice>
      </div>
    </>
  );
};

export default X12;
