import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { FC, memo, CSSProperties } from "react";
import Select from "react-select";
import styled from "styled-components";
import query from "../../query";
import mutation from "../../mutation";

const Indicator = styled.div`
  position: relative;
  cursor: pointer;
  width: 24px;
  &::after {
    position: relative;
    top: 14px;
    left: 5px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: "";
    border-top: 6px solid black;
  }
`;

const OddsTypeSelector: FC = () => {
  const oddsTypeQuery = query.oddsType();
  const betTypeQuery = query.betType();
  const queryClient = useQueryClient();
  const oddsType = useQuery(oddsTypeQuery.key, oddsTypeQuery.queryFn, {
    refetchOnMount: false,
    keepPreviousData: true,
  });
  const betType = useQuery(betTypeQuery.key, betTypeQuery.queryFn, {
    refetchOnMount: false,
    keepPreviousData: true,
  });
  const updateOddsType = useMutation(mutation.updateOddsType, {
    onSuccess: (value) => {
      queryClient.setQueryData(["oddsType"], value);
    },
  });

  const options = [
    {
      value: 0,
      label: (
        <>
          <img
            src="/svg/malaysia-icon.svg"
            alt="malaysia odds"
            width={25}
            height={25}
            className="inline align-bottom mr-1"
          />
          Malay Odds
        </>
      ),
      isDisabled: betType.data === "step" || betType.data === "comboStep",
    },
    {
      value: 1,
      label: (
        <>
          <img
            src="/svg/hongkong-icon.svg"
            alt="Hongkong odds"
            width={25}
            height={25}
            className="inline align-bottom mr-1"
          />
          Hongkong Odds
        </>
      ),
      isDisabled: betType.data === "step" || betType.data === "comboStep",
    },
    {
      value: 2,
      label: (
        <>
          <img
            src="/svg/euro-icon.svg"
            alt="Euro odds"
            width={25}
            height={25}
            className="inline align-bottom mr-1"
          />
          Euro Odds
        </>
      ),
      isDisabled: false,
    },
    // {
    //   value: 3,
    //   label: "Indo Odds",
    //   isDisabled: betType.data === "step" || betType.data === "comboStep",
    // },
  ];

  return (
    <Select
      menuPlacement="auto"
      className="basic-single"
      classNamePrefix="select"
      defaultValue={options[oddsType?.data || 0]}
      isSearchable={false}
      value={options[oddsType?.data || 0]}
      name="oddType"
      options={options}
      components={{
        DropdownIndicator: ({ cx: _cx, ...rest }) => <Indicator {...rest} />,
        IndicatorSeparator: () => null,
        Option: (props) => {
          const { data, innerProps, innerRef, getStyles } = props;
          return (
            <div
              {...innerProps}
              style={{
                ...(getStyles("option", props) as CSSProperties),
                cursor: "pointer",
              }}
              ref={innerRef as any}
            >
              {data.label}
            </div>
          );
        },
      }}
      styles={{
        control: () => ({
          height: 24,
          minHeight: 0,
          display: "flex",
        }),
        valueContainer: () => ({
          fontSize: 16,
          height: 24,
          width: 160,
        }),
        singleValue: (style) => ({
          ...style,
          cursor: "pointer",
          textAlign: "center",
        }),
        menu: () => ({
          position: "absolute",
          background: "white",
          borderRadius: "0 0 6px 6px",
          cursor: "pointer",
          top: 38,
          width: 180,
          boxShadow:
            "0 2px 4px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)",
        }),
        indicatorsContainer: () => ({
          width: 24,
        }),
      }}
      onChange={(option) => {
        updateOddsType.mutate(option?.value || 0);
      }}
    />
  );
};

export default memo(OddsTypeSelector);
