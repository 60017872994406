import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Clock from "./Clock";
import { Wrapper } from "./styled";
import OddsTypeSelector from "../OddsTypeSelector";
import { Button, ButtonType } from "../../components/Button";

const StatusBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Wrapper className="py-2 px-4 tablet:px-8">
      <div className="flex justify-between">
        <div>
          <Clock />
        </div>
        <div className="flex space-x-4">
          <OddsTypeSelector />
          <Button
            className="px-2"
            buttonType={ButtonType.PRIMARY}
            onClick={() => navigate("/my-bets")}
          >
            {t("My Bets")}
          </Button>
          <Button
            className="px-2"
            buttonType={ButtonType.PRIMARY}
            onClick={() => navigate("/statement")}
          >
            {t("Statement")}
          </Button>
          <Button
            className="px-2"
            buttonType={ButtonType.PRIMARY}
            onClick={() => navigate("/results")}
          >
            {t("All Result")}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default StatusBar;
