import classNames from "classnames";
import { FC, Fragment } from "react";

import { ML, Market as MarketType, OU, YN } from "../../types/cricketFeed";
import OverUnder from "./OverUnder";
import MoneyLine from "./MoneyLine";
import YesNo from "./YesNo";
import Collapse from "@material-tailwind/react/components/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import useToggleState from "../../hooks/useToggleState";
import {
  BetTicketInfoForDisplay,
  BetTicketInfoPayload,
} from "../../types/betTicket";

export type MarketProps = {
  leagueId: string;
  matchId: string;
  isLive?: boolean;
  homeTeam: string;
  awayTeam: string;
  leagueName: string;
  onClickOddPrice?: (
    payload: BetTicketInfoPayload & BetTicketInfoForDisplay
  ) => void;
} & MarketType;

const Market: FC<MarketProps> = ({
  name,
  leagueName,
  homeTeam,
  awayTeam,
  selection,
  isLive = false,
  leagueId,
  matchId,
  onClickOddPrice,
}) => {
  const { isToggled, toggle } = useToggleState(true);

  return (
    <div
      className={classNames("p-2 rounded border border-solid", {
        "border-orange-700": isLive,
        "border-green-700": !isLive,
      })}
    >
      <h6
        className="font-bold cursor-pointer mb-2 flex justify-between items-center"
        onClick={toggle}
      >
        <span>{name}</span>

        <ExpandLessIcon
          style={{
            transition: "transform 200ms",
          }}
          className={classNames({
            "rotate-180": !isToggled,
            "rotate-0": isToggled,
          })}
        />
      </h6>
      <Collapse open={isToggled}>
        <div className="grid grid-cols-2 gap-1">
          {selection.map((market) => {
            if (market.label === "ou") {
              const {
                specifiers,
                overOdds,
                underOdds,
                handicap,
                marketId,
                overId,
                underId,
              } = market as OU;
              return (
                <Fragment key={specifiers}>
                  <OverUnder
                    isLive={isLive}
                    leagueName={leagueName}
                    homeTeam={homeTeam}
                    awayTeam={awayTeam}
                    specifiers={specifiers}
                    leagueId={leagueId}
                    overId={overId}
                    underId={underId}
                    marketId={marketId}
                    onClickOddPrice={onClickOddPrice}
                    overOdds={overOdds}
                    underOdds={underOdds}
                    handicap={handicap}
                    matchId={matchId}
                  />
                </Fragment>
              );
            } else if (market.label === "yn") {
              const {
                label,
                noOdds,
                yesOdds,
                marketId,
                yesId,
                noId,
                isActive,
              } = market as YN;

              <Fragment key={label}>
                <YesNo
                  isLive={isLive}
                  label={label}
                  isActive={isActive}
                  matchId={matchId}
                  leagueId={leagueId}
                  leagueName={leagueName}
                  homeTeam={homeTeam}
                  awayTeam={awayTeam}
                  marketId={marketId}
                  yesId={yesId}
                  noId={noId}
                  onClickOddPrice={onClickOddPrice}
                  noOdds={noOdds}
                  yesOdds={yesOdds}
                />
              </Fragment>;
            }

            const {
              label,
              homeName,
              homeOdds,
              awayName,
              awayOdds,
              homeId,
              awayId,
              marketId,
              isActive,
            } = market as ML;

            return (
              <Fragment key={label}>
                <MoneyLine
                  isLive={isLive}
                  label={label}
                  isActive={isActive}
                  leagueId={leagueId}
                  homeName={homeName}
                  homeOdds={homeOdds}
                  awayName={awayName}
                  awayOdds={awayOdds}
                  homeId={homeId}
                  awayId={awayId}
                  leagueName={leagueName}
                  matchId={matchId}
                  marketId={marketId}
                  onClickOddPrice={onClickOddPrice}
                />
              </Fragment>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};

export default Market;
