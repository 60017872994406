import { Row } from "@tanstack/react-table";

export const renderSubComponent = ({ row }: { row: Row<any> }) => {
  if (row.original.feed_order !== 0)
    return <></>;
  return (
    <tr>
      <th colSpan={10} className="text-left league">
        <b>{row.original.leagueName}</b>
      </th>
    </tr>
  );
};
