import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import SportName from "./SportName";
import { SPORT_LISTS } from "./config";
import { Button, Wrapper } from "./styled";
import NumberOfMatch from "./NumberOfMatch";
import useSportsBar from "./useSportsBar";

const SportsBarMobile: FC = () => {
  const { numberOfMatchType, sport: currentSport } = useSportsBar();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Wrapper className="flex overflow-x-auto justify-center">
      {SPORT_LISTS.map((sport) => {
        return (
          !sport.isComingSoon && (
            <li
              key={sport.name}
              className="flex text-left text-sm mobile:text-xs tablet:text-xs"
            >
              <Button
                className={classNames(
                  "p-3 relative flex justify-evenly mobile:flex-col mobile:py-2 mobile:px-3 mobile:items-center",
                  { active: currentSport === sport.routeTo }
                )}
                disabled={!sport.routeTo}
                onClick={() => {
                  if (sport.collapse?.length) {
                    navigate(`/${sport.routeTo}/${sport.collapse[0].routeTo}`);
                  } else {
                    navigate(`/${sport.routeTo}`);
                  }
                }}
              >
                <SportName
                  iconSrc={sport.icon}
                  name={t(sport.name.replace("-", " "))}
                />
                <NumberOfMatch
                  className="mobile:font-bold mobile:bg-white mobile:rounded-md mobile:p-0.5 mobile:text-xs mobile:absolute mobile:top-0.5 mobile:left-2/4"
                  isLoading={numberOfMatchType.isLoading}
                  data={numberOfMatchType.data}
                  keyData={sport.keyData}
                />
              </Button>
            </li>
          )
        );
      })}
    </Wrapper>
  );
};

export default SportsBarMobile;
