export const PLACEBET_ERROR_CODE: Record<number, string> = {
  15012: "VALIDATION_ERROR",
  4290010: "RATE_LIMIT_REQUEST",
  5044: "CANNOT_GET_PROFILE",
  9900000: "ODDS_CHANGED",
  9900001: "MATCH_NOT_FOUND",
  990000101: "OVER_LITMIT_PER_MATCH",
  990000102: "OVER_LITMIT_PER_BILL",
  990000103: "OVER_LITMIT_PER_DAY",
  9900002: "ODDS_INVALID",
  9900004: "DUPLICATE_BET",
  9900005: "INSUFFICIENT_BALANCE",
  9900006: "LOCKED_ACCOUNT",
  9900007: "SUSSPENDED_ACCOUNT",
  9900009: "UNAVAILABLE_BET",
  9900010: "TICKET_REJECTED",
  997: "SYSTEM_ERROR",
  998: "SYSTEM_ERROR",
  9910: "SYSTEM_ERROR",
  "-999": "SYSTEM_ERROR",
  1000: "SUSSPENDED_ACCOUNT",
  1001: "INVALID_CURRENCY",
  1002: "INVALID_AMOUNT",
  1003: "LOCKED_ACCOUNT",
  1004: "INSUFFICIENT_BALANCE",
  1005: "SYSTEM_ERROR",
  1006: "SYSTEM_ERROR",
  1007: "SESSION_EXPIRED",
  1008: "DUPLICATE_BET",
  1009: "SYSTEM_ERROR",
  9918: "SYSTEM_ERROR",
};
