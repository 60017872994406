import { useCallback, useState } from "react";

const useToggleState = (defaultValue?: boolean) => {
  const [isToggled, setIsToggled] = useState(defaultValue ?? false);

  const toggle = useCallback(() => setIsToggled(!isToggled), [isToggled]);

  return {
    setIsToggled,
    isToggled,
    toggle,
  };
};

export default useToggleState;
