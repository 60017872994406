import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import axios, { AxiosRequestHeaders } from "axios";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import "tw-elements";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

axios.interceptors.request.use((config) => {
  let token;
  try {
    token = window?.localStorage.getItem("auth");
  } catch (error) {
    console.error("Cannot access localStorage:", error);
  }
  if (token && token !== "undefined") {
    (config.headers as AxiosRequestHeaders).Authorization = token;
  }

  return config;
});

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
