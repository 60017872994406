import { FC } from "react";
import Modal from "../../components/Modal";
import LeagueFilterForm from "./LeagueFilterForm";
import { LeagueFilterFormProps } from "./LeagueFilterForm";

export type LeagueFilterModalProps = {
  isToggled: boolean;
  onClose: () => void;
} & LeagueFilterFormProps;

const LeagueFilterModal: FC<LeagueFilterModalProps> = ({
  isToggled,
  defaultIsAllSelected,
  defaultSelectedLeagues,
  onClose,
  options,
  onSubmit,
}) => {
  return (
    <Modal open={isToggled} onClose={onClose}>
      <LeagueFilterForm
        defaultIsAllSelected={defaultIsAllSelected}
        defaultSelectedLeagues={defaultSelectedLeagues}
        options={options}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default LeagueFilterModal;
