import { FC, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import moment from "moment";
import { Sport } from "../../types/sport";
import classNames from "classnames";
import Table from "../../components/Table";
import { Button, ButtonType } from "../../components/Button";
import { COLUMNS } from "./ResultTable/columns/config";
import { useQuery } from "@tanstack/react-query";
import query from "../../query";
import { DateOptions } from "./types";
import useSelectorState from "../../hooks/useSelectorState";
import { StyledTable } from "./styled";
import { renderSubComponent } from "./renderSubComponent";
import {
  sportOptions,
  dateTypeOptions,
  defaultSportOption,
  defaultDateTypeOption,
  defaultState,
} from "./config";
import formatData from "./formatData";
import LeagueFilterModal from "../../contrainers/LeagueFilterModal";
import useLeagueFilterModal from "../../contrainers/LeagueFilterModal/useLeagueFilterModal";
import LeagueFilterButtonMobile from "../../components/LeagueFilterButton/LeagueFilterButtonMobile";
import useDevice from "../../hooks/useDevice";
import { Device } from "../../types/device";

const Results: FC = () => {
  const { i18n, t } = useTranslation();
  const leagueFilterModal = useLeagueFilterModal();
  const [dateOptions, setDateOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const device = useDevice();

  const [selectedDate, setSelectedDate] = useState<string>(
    defaultState.selectedDate
  );
  const { value: sportType, onChange: onChangeSportType } =
    useSelectorState<Sport>(defaultSportOption);

  const { value: dateType, onChange: onChangeDateType } =
    useSelectorState<DateOptions>(defaultDateTypeOption);

  useEffect(() => {
    leagueFilterModal.setIsAllSelected(
      leagueFilterModal.defaultState.isAllSelected
    );
    leagueFilterModal.setSelectedLeagues(
      leagueFilterModal.defaultState.selectedLeagues
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sportType, dateType]);

  const getResult = query.todayResult(
    sportType ? sportType.value : defaultSportOption.value
  );

  const getLastResult = query.lastResult(
    sportType ? sportType.value : defaultSportOption.value
  );

  const result = useQuery(getResult.key, getResult.queryFn);
  const lastResult = useQuery(getLastResult.key, getLastResult.queryFn, {
    enabled: dateType?.value === DateOptions.SEVEN_DAYS_AGO,
  });

  let displayData = result.data;

  if (dateType?.value === DateOptions.SEVEN_DAYS_AGO) {
    displayData = lastResult.data
      ? Object.values(lastResult.data)?.[Number(selectedDate)] ?? []
      : [];
  }

  const filteredDisplayData = leagueFilterModal.filterLeagues(displayData);
  const length = displayData?.length ?? 0;
  const selectedLeaguesNo = leagueFilterModal.getSelectedLeaguesNo(length);
  const formattedData = formatData(filteredDisplayData ?? [], i18n.language);

  useEffect(() => {
    const temp = new Array(7).fill(undefined);
    const newDateOptions = temp.map((_empty, index) => {
      const newDate = moment().subtract(index, "days");
      const label = newDate.format("DD/MM");
      return { label, value: `${index}` };
    });

    setDateOptions(newDateOptions);
    setSelectedDate(newDateOptions[0].value);
  }, []);

  const options = useMemo(
    () => leagueFilterModal.formatOptions(displayData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [displayData]
  );

  return (
    <>
      <div className="desktop:container desktop:mx-auto desktop:px-3 mobile:pt-3">
        <h3 className="text-2xl font-bold mb-3">{t("All Result")}</h3>
        <div>
          <div className="p-4 bg-white rounded-t-lg ">
            <div className="flex justify-between">
              <div className="flex space-x-3">
                <div>
                  <label>{t("Type")}</label>
                  <Select
                    options={sportOptions}
                    value={sportType}
                    isSearchable={false}
                    onChange={onChangeSportType}
                  />
                </div>
                <div className="w-40">
                  <label>{t("Date time")}</label>
                  <Select
                    options={dateTypeOptions}
                    value={dateType}
                    isSearchable={false}
                    onChange={onChangeDateType}
                  />
                </div>
              </div>

              <div className="flex flex-col desktop:justify-center mobile:justify-end">
                {device === Device.DESKTOP ? (
                  <Button
                    className="px-2"
                    disabled={
                      dateType?.value === DateOptions.SEVEN_DAYS_AGO
                        ? lastResult.isLoading
                        : result.isLoading
                    }
                    buttonType={ButtonType.SECONDARY}
                    onClick={leagueFilterModal.toggle}
                  >
                    <img
                      src="/svg/trophy.svg"
                      width={18}
                      className="inline align-sub mr-1"
                      alt="leagues"
                    />
                    {t("Leagues")} ({selectedLeaguesNo}/{length})
                  </Button>
                ) : (
                  <LeagueFilterButtonMobile className="py-2.5" onClick={() => {}} />
                )}
              </div>
            </div>
            {dateType?.value === DateOptions.SEVEN_DAYS_AGO && (
              <div
                className="flex flex-wrap"
                style={{ marginLeft: "-12px", marginRight: "-12px" }}
              >
                {dateOptions.map((option) => {
                  return (
                    <button
                      key={option.label}
                      className={classNames(
                        "m-3 inline-block px-4 py-2.5 bg-gray-200 font-medium leading-tight rounded-2xl shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out",
                        {
                          "text-gray-700": selectedDate !== option.value,
                          "text-green-700": selectedDate === option.value,
                        }
                      )}
                      onClick={() => setSelectedDate(option.value)}
                    >
                      {option.label}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
          <div>
            <StyledTable className="bg-white">
              <Table
                data={formattedData as any}
                columns={COLUMNS}
                renderSubComponent={renderSubComponent}
              />
            </StyledTable>
          </div>
        </div>
      </div>
      <LeagueFilterModal
        defaultIsAllSelected={leagueFilterModal.isAllSelected}
        defaultSelectedLeagues={leagueFilterModal.selectedLeagues}
        isToggled={leagueFilterModal.isToggled}
        onClose={leagueFilterModal.toggle}
        onSubmit={leagueFilterModal.onSubmit}
        options={options}
      />
    </>
  );
};

export default Results;
