import { FC, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BetType } from "../../types/common";
import { Button, ButtonType } from "../../components/Button";

export type BetTypeSelectorProps = {
  isPalayDisabled?: boolean;
  isComboDisabled?: boolean;
};

const BetTypeSelector: FC<BetTypeSelectorProps> = ({
  isPalayDisabled = false,
  isComboDisabled = false,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [betType, setBetType] = useState<BetType>("normal");

  useEffect(() => {
    switch (pathname) {
      case "/football-parlay":
        setBetType("step");
        break;
      case "/combo-step":
        setBetType("comboStep");
        break;
      default:
        break;
    }
  }, [pathname]);

  return (
    <>
      {!isPalayDisabled && (
        <>
          {betType !== "normal" && (
            <Button
              buttonType={ButtonType.SECONDARY}
              className="mr-3 text-sm px-5"
              onClick={() => {
                navigate("/football/live-today");
              }}
            >
              Single
            </Button>
          )}
          {betType !== "step" && (
            <Button
              buttonType={ButtonType.SECONDARY}
              className="mr-3 text-sm px-5"
              onClick={() => {
                navigate("/football-parlay");
              }}
            >
              Parlay
            </Button>
          )}
        </>
      )}

      {!isComboDisabled && betType !== "comboStep" && (
        <Button
          buttonType={ButtonType.SECONDARY}
          className="mr-3 text-sm px-5"
          onClick={() => {
            navigate("/combo-step");
          }}
        >
          Combo
        </Button>
      )}
    </>
  );
};

export default BetTypeSelector;
