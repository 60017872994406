import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import classNames from "classnames";
import { FC, PropsWithChildren, cloneElement, isValidElement } from "react";
import useToggleState from "../../../../hooks/useToggleState";

import { MarketProps } from "./Market";

export type MarketWrapperProps = {
  title: string;
};

const MarketWrapper: FC<PropsWithChildren<MarketWrapperProps>> = ({
  title,
  children,
}) => {
  const { isToggled, toggle } = useToggleState(true);

  return (
    <div className="odd:last:col-span-2">
      <div
        className="p-2 font-bold text-sm flex justify-between bg-gray-300"
        onClick={toggle}
      >
        <div>{title}</div>
        <div>
          <ArrowDropDownIcon
            className={classNames({
              "rotate-180": !isToggled,
              "rotate-0": isToggled,
            })}
          />
        </div>
      </div>
      <div
        className="flex flex-col"
        style={{
          borderTop: "1px solid rgb(221, 221, 221)",
        }}
      >
        {isValidElement<MarketProps>(children) &&
          cloneElement(children, { isCollapsed: isToggled })}
      </div>
    </div>
  );
};

export default MarketWrapper;
