import styled from "styled-components";

export const Button = styled.button<{
  marginArrow?: string;
  noBorder?: boolean;
}>`
  &.accordion-button:not(.collapsed) {
    color: initial;
    box-shadow: none;
    background-color: initial;
    border-bottom: ${(props) =>
      props.noBorder
        ? "none"
        : `1px solid ${props.theme.global.BackgroundOutside}`};
  }
  &.accordion-button::after {
    width: 1em;
    height: 1em;
    background-size: 1em;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: ${(props) => props.marginArrow || "auto"};
  }
`;

export const Wrapper = styled.ul`
  background-color: ${(props) => props.theme.global.Secondary};
  color: ${(props) => props.theme.global.textColorInSecondary};

  & > li > button.active {
    background-color: ${(props) => props.theme.global.borderSecondary};
  }
`;
