import { DateOptions } from "./types";

export const dateTypeOptions = [
  { label: "All dates", value: DateOptions.ALL_DAY },
  { label: "Tomorrow", value: DateOptions.TOMORROW },
  { label: "Today", value: DateOptions.TODAY },
];

export const defaultDateTypeOption = dateTypeOptions[0];

export const defaultState = {
  selectedDate: "0",
};
