import { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { THEME } from "../../constants/theme";

const options = [
  { label: "EN", value: "en" },
  { label: "TH", value: "th" },
];

const LanguageSelector = () => {
  const [defaultOption, setDefaultOption] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const value = options.find((option) => option.value === i18n.language);

    if (!value) {
      return;
    }
    setDefaultOption(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!defaultOption) {
    return <></>;
  }

  return (
    <Select
      isSearchable={false}
      options={options}
      defaultValue={defaultOption}
      components={{
        IndicatorSeparator: () => null,
      }}
      styles={{
        menu: (style) => ({
          ...style,
          zIndex: "9999",
          color: "black",
          fontWeight: "bold",
        }),
        valueContainer: (style) => ({
          ...style,
          padding: "2px 0px 2px 8px",
        }),
        indicatorsContainer: (style) => ({
          ...style,
          padding: 0,
          "&>div": {
            padding: 4,
          },
        }),
        option: (style) => ({
          ...style,
          cursor: "pointer",
          textAlign: "center",
          fontSize: 14,
        }),
        control: (style) => ({
          ...style,
          cursor: "pointer",
          borderRadius: 20,
          fontWeight: "bold",
          fontSize: 14,
          minHeight: 28,
          backgroundColor: THEME.global.Primary,
          color: THEME.global.textColorInPrimary,
        }),
        singleValue: (style) => ({
          ...style,
          color: THEME.global.textColorInPrimary,
        }),
        container: (style) => ({
          ...style,
          marginTop: "auto",
          marginBottom: "auto",
        }),
      }}
      onChange={(option) => {
        if (!option) {
          return;
        }

        i18n.changeLanguage(option.value);
      }}
    />
  );
};

export default LanguageSelector;
