import { BetPriceType, Option } from "../../types/common";
import { TeamName } from "../../components/Typography";

export type BettingTeamNameProps = {
  isGoodTeam: boolean;
  bettingTeamName: string;
  betPriceType: BetPriceType;
  option?: Option;
};

const BettingTeamName = (props: BettingTeamNameProps) => {
  switch (props.betPriceType) {
    case "oe":
      if (props.option === 0) {
        return <TeamName className="text-sm" isGoodTeam>Odd</TeamName>;
      }
      return <TeamName className="text-sm" isGoodTeam>Even</TeamName>;
    case "ou":
      if (props.option === 0) {
        return <TeamName className="text-sm" isGoodTeam>Over</TeamName>;
      }
      return <TeamName className="text-sm" isGoodTeam>Under</TeamName>;
    case "cr":
    case "ht/ft":
      return <></>;
    case "yn":
      if(props.option === 0) {
        return <TeamName className="text-sm" isGoodTeam>Yes</TeamName>;
      }
      return <TeamName className="text-sm" isGoodTeam>No</TeamName>;
    default:
      return (
        <TeamName className="text-sm" isGoodTeam={props.isGoodTeam}>
          {props.bettingTeamName}
        </TeamName>
      );
  }
};

export default BettingTeamName;
