import {
  useQuery,
  useMutation,
  useQueryClient,
  useQueries,
} from "@tanstack/react-query";
import {
  FC,
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  useCallback,
  FormEvent,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { PLACEBET_ERROR_CODE } from "../../constants/placebetErrorCode";
import { convertOdds } from "../../utils/convertOdds";
import mutation from "../../mutation";
import query from "../../query";
import {
  BetTicketInfoPayload,
  BetTicketInfoForDisplay,
} from "../../types/betTicket";
import PlaceBetForm from "./PlaceBetForm";
import Match from "./Match";
import { BetTicketInfoHeader, HorizontalLine } from "./styled";
import { formatMatch } from "./formatMatch";
import { mapBetTicketInfoQueries } from "./mapBetTicketInfoQueries";

export type BetTicketInfoProps = {
  payload: (BetTicketInfoPayload & BetTicketInfoForDisplay)[];
};

const BetTicketInfo: FC<BetTicketInfoProps> = ({ payload }) => {
  const queryClient = useQueryClient();
  const [amount, setAmount] = useState<number>(0);
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(0);
  const [oddsSummary, setOddsSummary] = useState<number>(1);
  const [isAcceptAnyOdds, setIsAcceptAnyOdds] = useState<boolean>(false);
  const [isForceToWaiting, setIsForceToWaiting] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>();
  const { t } = useTranslation();

  const updateCurrentTabIndex = useMutation(mutation.updateCurrentTabIndex, {
    onSuccess: (index) => {
      queryClient.setQueryData(["currentTabIndex"], index);
    },
  });

  const updateBetTicketInfoPayload = useMutation(
    mutation.updateBetTicketInfoPayload,
    {
      onSuccess: (payload) => {
        queryClient.setQueryData(["betTicketInfoPayload"], payload);
      },
    }
  );

  const profileQuery = query.profile();
  const profile = useQuery(profileQuery.key, profileQuery.queryFn);

  const placeBetTicket = useMutation(mutation.placeBetTicket, {
    onSuccess: () => {
      profile.refetch();
      updateBetTicketInfoPayload.mutate([]);
    },
  });

  const onChangeIsForceToWaiting = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setIsForceToWaiting(event.target.checked),
    []
  );

  const onChangeAcceptAnyOdds = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setIsAcceptAnyOdds(event.target.checked),
    []
  );

  const onClickMinMax = useCallback((amount: number) => {
    setAmount(amount);
  }, []);

  const onChangeAmount = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setAmount(Number(event.target.value.replace(/,/g, "")));
    } else {
      setAmount(0);
    }
  }, []);

  const onBlurAmount = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setAmount(0);
    }
  }, []);

  const betTypeQuery = query.betType();
  const betType = useQuery(betTypeQuery.key, betTypeQuery.queryFn, {
    refetchOnMount: false,
    keepPreviousData: true,
  });

  const betTicketInfoPayloadQuery = query.betTicketInfoPayload();
  const betTicketInfoPayload = useQuery(
    betTicketInfoPayloadQuery.key,
    betTicketInfoPayloadQuery.queryFn,
    {
      refetchOnMount: false,
      keepPreviousData: true,
    }
  );

  const betTicketInfoQueries = useMemo(
    () => mapBetTicketInfoQueries(betTicketInfoPayload?.data ?? []),
    [betTicketInfoPayload?.data]
  );

  const betInfoResults = useQueries({
    queries: betTicketInfoQueries as any,
  });

  const oddsTypeQuery = query.oddsType();
  const oddsType = useQuery(oddsTypeQuery.key, oddsTypeQuery.queryFn, {
    refetchOnMount: false,
    keepPreviousData: true,
  });

  const type = betType.data || "normal";

  useEffect(() => {
    if (inputRef.current && !placeBetTicket.isLoading) {
      setAmount(0);
      inputRef.current.focus();
    }
  }, [placeBetTicket.isLoading]);

  useEffect(() => {
    let max = 0;
    let min = 0;
    let sumOdds = 1;
    betInfoResults.forEach(({ data }: any) => {
      if (data?.min && data.min > min) {
        min = data?.min;
      }

      if (data?.max && data.max > max) {
        max = data?.max;
      }

      if (data?.price) {
        const newOdds = ["x12", "x121st"].includes(data?.betType)
          ? Number(data.price).toFixed(2)
          : convertOdds(data.price, oddsType?.data);
        sumOdds = Number((sumOdds * Math.abs(Number(newOdds))).toFixed(2));
      }
    });

    if (betInfoResults.length > 1) {
      max = Number((max / sumOdds).toFixed(2));
    }

    setMin(min);
    setMax(max);
    setOddsSummary(sumOdds);
  }, [oddsType?.data, betInfoResults]);

  const clearAllMatch = useCallback(() => {
    updateBetTicketInfoPayload.mutate([]);
  }, [updateBetTicketInfoPayload]);

  const removeMatch = useCallback(
    (matchId: string) => {
      const filtered =
        betTicketInfoPayload?.data?.filter(
          (data) => data.matchId !== matchId
        ) ?? [];
      updateBetTicketInfoPayload.mutate(filtered);
    },
    [betTicketInfoPayload?.data, updateBetTicketInfoPayload]
  );

  const placebet = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (amount === 0 || amount < min) {
        setAmount(min);
        return;
      } else if (amount > max) {
        setAmount(max);
        return;
      }

      if (!betTicketInfoPayload.data?.[0]) return;

      const formattedMatchs = formatMatch(
        betTicketInfoPayload.data,
        betInfoResults
      );

      try {
        const res = await placeBetTicket.mutateAsync({
          type: betType.data || "normal",
          amount: `${amount}`,
          oddsType: oddsType?.data || 0,
          acceptAnyOdds: isAcceptAnyOdds,
          isTestWaiting: isForceToWaiting,
          matches: formattedMatchs,
        });
        if (res.code !== 0) {
          const msg = t(PLACEBET_ERROR_CODE[res.code as number]);
          toast.error(msg);
          console.log(res);
        } else {
          toast.success(t("Place bet success"));
        }

        updateCurrentTabIndex.mutate(1);
      } catch (error) {
        toast.error("Bet failed");
        console.log(error);
      }
    },
    [
      amount,
      min,
      max,
      betTicketInfoPayload.data,
      betInfoResults,
      placeBetTicket,
      betType.data,
      oddsType?.data,
      isAcceptAnyOdds,
      isForceToWaiting,
      updateCurrentTabIndex,
      t,
    ]
  );

  const lastMatch =
    betTicketInfoPayload.data?.[betTicketInfoPayload.data.length - 1];

  return (
    <>
      <BetTicketInfoHeader>
        {type === "normal" ? "เดี่ยว" : type === "step" ? "พาร์เลย์" : "คอมโบ"}
      </BetTicketInfoHeader>

      <div className="overflow-y-auto scroll-smooth" style={{ minHeight: 210 }}>
        {payload.map((data) => {
          return (
            <Match
              key={data.matchId}
              oddsType={oddsType?.data || 0}
              onRemove={removeMatch}
              {...data}
            />
          );
        })}
      </div>

      <HorizontalLine isLive={lastMatch && lastMatch.table === "live"} />

      <PlaceBetForm
        isLive={lastMatch && lastMatch.table === "live"}
        maxReturn={amount * oddsSummary}
        amount={amount}
        min={min}
        max={max}
        isAcceptAnyOdds={isAcceptAnyOdds}
        isForceToWaiting={isForceToWaiting}
        InputRef={inputRef}
        onClickMinMax={onClickMinMax}
        onChangeAmount={onChangeAmount}
        onBlurAmount={onBlurAmount}
        onChangeAcceptAnyOdds={onChangeAcceptAnyOdds}
        onChangeIsForceToWaiting={onChangeIsForceToWaiting}
        onCancel={clearAllMatch}
        onSubmit={placebet}
        isLoading={placeBetTicket.isLoading || profile.isLoading}
        pairs={betTicketInfoPayload.data?.length}
        betType={betType.data}
        oddsSummary={oddsSummary}
        currency={profile.data?.cur[0]}
      />
    </>
  );
};

export default BetTicketInfo;
