import { ACCORDION_ID } from "../../constants/accordionId";

class SportBar {
  buttonId: string;
  accordionId: string;
  constructor() {
    this.buttonId = `${ACCORDION_ID}-button`;
    this.accordionId = ACCORDION_ID;
  }

  public getButtonId() {
    return this.buttonId;
  }

  public getAccordionId() {
    return this.accordionId;
  }

  public collapse() {
    const buttonEl = document.querySelector<HTMLButtonElement>(
      `#${this.buttonId}`
    );
    const accordionEl = document.querySelector<HTMLDivElement>(
      `#${this.accordionId}`
    );

    if (!buttonEl || !accordionEl) {
      return;
    }

    const accordionClassName = accordionEl.className;
    const buttonClassName = buttonEl.className;

    if (
      buttonClassName.includes("collapsed") ||
      !accordionClassName.includes("show")
    ) {
      return;
    }
    const offsetHeight = accordionEl.offsetHeight;

    accordionEl.classList.add("collapsing");
    accordionEl.style.height = offsetHeight + "px";

    setTimeout(() => {
      accordionEl.style.height = "";
      buttonEl.classList.add("collapsed");
    }, 100);

    setTimeout(() => {
      accordionEl.classList.remove("show");
      buttonEl.setAttribute("aria-expanded", "false");
    }, 500);
  }
}

export default SportBar;
