import { FC, HTMLAttributes } from "react";

export type LoadingProp = {
  size?: number;
  color?: string;
};

const Loading: FC<HTMLAttributes<HTMLDivElement> & LoadingProp> = ({
  className,
  size,
}) => {
  return (
    <svg
      className={"animate-spin" + (className ? " " + className : "")}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      fill="currentColor"
      width={size ? `${size}px` : "1em"}
      height={size ? `${size}px` : "1em"}
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="currentColor"
        strokeWidth="10"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
        transform="matrix(1,0,0,1,0,0)"
        style={{
          transform: "matrix(1, 0, 0, 1, 0, 0)",
        }}
      ></circle>
    </svg>
  );
};

export default Loading;
