import styled from "styled-components";

export const StyledDivWithBg = styled.div<{ sport: string }>`
  background-image: ${(props) =>
    props.sport === "basketball"
      ? `url("/basketball.jpeg")`
      : props.sport === "volleyball"
      ? `url("/volleyball.jpeg")`
      : `url("/soccer-ball-goal.webp")`};
  background-size: cover;
  min-height: 100px;
`;
