import { useTranslation } from "react-i18next";
import { CellContext } from "@tanstack/react-table";
import { FormattedFeed, AsiaHandicap } from "../../../types/feed";
import { Handicap, OddPrice } from "../../../components/Typography";
import { CustomCellContext } from "../../../types/table";
import { isGoodTeam } from "../../../utils/isGoodTeam";
import { convertOdds } from "../../../utils/convertOdds";
import { convertHDP } from "../../../utils/convertHDP";
import useOddChangeDetector from "../../../hooks/useOddChangeDetector";

const HDP = (row: CellContext<FormattedFeed, unknown> & CustomCellContext) => {
  const { i18n } = useTranslation();
  const asiaHandicap = row.getValue<AsiaHandicap>();
  const id = row.column.id;
  const { homePriceChangedType, awayPriceChangedType } = useOddChangeDetector({
    homeHandicap: asiaHandicap?.h || "",
    awayHandicap: asiaHandicap?.a || "",
    homePrice: asiaHandicap?.hp || "0",
    awayPrice: asiaHandicap?.ap || "0",
    type: id,
  });

  const [leagueId, matchId] = row.row.original.match_id.split(":");
  const { match_n: matchName, tableType, sport } = row.row.original;

  if (!asiaHandicap) {
    return "";
  }

  const home = convertOdds(Number(asiaHandicap?.hp), row?.oddsType);
  const away = convertOdds(Number(asiaHandicap?.ap), row?.oddsType);
  const betType = id === "FULL_TIME_HDP" ? "ah" : "ah1st";
  const betTypeKey = betType === "ah" ? "betPrice_ah" : "betPrice_ah1st";
  const noHandicap = asiaHandicap?.h === "0" && asiaHandicap?.a === "0";
  return (
    <>
      <div className="flex mr-3">
        <Handicap className="flex-1 text-right pr-1">
          {noHandicap
            ? "0"
            : asiaHandicap?.h.includes("-")
            ? convertHDP(asiaHandicap?.a)
            : ""}
        </Handicap>
        <div className="flex-1 justify-end flex items-center">
          <OddPrice
            className="relative"
            isRedOdd={home.includes("-")}
            isIncrease={homePriceChangedType === "increase"}
            isDecrease={homePriceChangedType === "decrease"}
            onClick={() => {
              row?.onClickOddPrice?.({
                leagueId,
                matchId,
                option: 0,
                betType,
                sportType: sport.toUpperCase() as
                  | "FOOTBALL"
                  | "BASKETBALL"
                  | "VOLLEYBALL",
                table: tableType,
                type: row?.betType || "normal",
                home: matchName[i18n.language].h,
                away: matchName[i18n.language].a,
                isHomeGood: isGoodTeam(row.row.original?.betPrice_ah?.h),
                isAwayGood: isGoodTeam(row.row.original?.betPrice_ah?.a),
                league: row.row.original?.feed_nn,
                riskLevel: row.row.original?.betPrice_order,
                fhid: `${matchName.fhid}`,
                faid: `${matchName.faid}`,
                outcomeId: row.row.original?.[betTypeKey]?.och,
                specifiers: row.row.original?.[betTypeKey]?.sp,
                marketId: row.row.original?.[betTypeKey]?.mkid,
              });
            }}
          >
            {home}
          </OddPrice>
        </div>
      </div>
      <div className="flex mr-3">
        <Handicap className="flex-1 text-right pr-1">
          {asiaHandicap?.a.includes("-") ? convertHDP(asiaHandicap?.h) : ""}
        </Handicap>
        <div className="flex-1 justify-end flex items-center">
          <OddPrice
            className="relative"
            isRedOdd={away.includes("-")}
            isIncrease={awayPriceChangedType === "increase"}
            isDecrease={awayPriceChangedType === "decrease"}
            onClick={() =>
              row?.onClickOddPrice?.({
                leagueId,
                matchId,
                option: 1,
                betType,
                sportType: sport.toUpperCase() as "FOOTBALL" | "BASKETBALL",
                table: tableType,
                type: row?.betType || "normal",
                home: matchName[i18n.language].h,
                away: matchName[i18n.language].a,
                isHomeGood: isGoodTeam(row.row.original?.betPrice_ah?.h),
                isAwayGood: isGoodTeam(row.row.original?.betPrice_ah?.a),
                league: row.row.original?.feed_nn,
                riskLevel: row.row.original?.betPrice_order,
                fhid: `${matchName.fhid}`,
                faid: `${matchName.faid}`,
                outcomeId: row.row.original?.[betTypeKey]?.oca,
                specifiers: row.row.original?.[betTypeKey]?.sp,
                marketId: row.row.original?.[betTypeKey]?.mkid,
              })
            }
          >
            {away}
          </OddPrice>
        </div>
      </div>
    </>
  );
};

export default HDP;
