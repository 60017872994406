import { Navigate, Route, Routes } from "react-router-dom";

import Announcement from "../pages/Announcement";
import BasketballLiveAndTodayPage from "../pages/Basketball";
import VolleyballLiveAndTodayPage from "../pages/Volleyball";
import Combo from "../pages/Combo";
import FootballLiveAndTodayPage from "../pages/FootballLiveAndToday";
import FootballPage from "../pages/Football";
import FootBallParlayPage from "../pages/FootBallParlay";
import MyBets from "../pages/MyBets";
import Results from "../pages/Results";
import Statement from "../pages/Statement";
// import MatchMarket from "../pages/MatchMarket";
import TabletennisLiveAndTodayPage from "../pages/TabletennisLiveAndToday";
import HandballLiveAndTodayPage from "../pages/HandballLiveAndToday";
import IcehockeyLiveAndTodayPage from "../pages/IcehockeyLiveAndToday";
import TennisLiveAndTodayPage from "../pages/TennisLiveAndToday";
import SnookerLiveAndTodayPage from "../pages/SnookerLiveAndToday";
import BaseballLiveAndTodayPage from "../pages/BaseballLiveAndToday";
import RugbyLiveAndTodayPage from "../pages/RugbyLiveAndToday";
import AmericanfootballLiveAndTodayPage from "../pages/AmericanfootballLiveAndToday";
import BadmintonLiveAndTodayPage from "../pages/BadmintonLiveAndToday";
import CricketPage from "../pages/Cricket";
import CricketLiveAndToday from "../pages/CricketLiveAndToday";

export const Router = () => {
  return (
    <Routes>
      <Route
        path="/football/live-today"
        element={<FootballLiveAndTodayPage />}
      />
      <Route
        path="/basketball/live-today"
        element={<BasketballLiveAndTodayPage />}
      />
      <Route
        path="/volleyball/live-today"
        element={<VolleyballLiveAndTodayPage />}
      />

      <Route
        path="/tabletennis/live-today"
        element={<TabletennisLiveAndTodayPage />}
      />

      <Route
        path="/handball/live-today"
        element={<HandballLiveAndTodayPage />}
      />

      <Route
        path="/icehockey/live-today"
        element={<IcehockeyLiveAndTodayPage />}
      />

      <Route path="/tennis/live-today" element={<TennisLiveAndTodayPage />} />

      <Route path="/snooker/live-today" element={<SnookerLiveAndTodayPage />} />

      <Route
        path="/baseball/live-today"
        element={<BaseballLiveAndTodayPage />}
      />

      <Route path="/rugby/live-today" element={<RugbyLiveAndTodayPage />} />

      <Route
        path="/americanfootball/live-today"
        element={<AmericanfootballLiveAndTodayPage />}
      />

      <Route
        path="/badminton/live-today"
        element={<BadmintonLiveAndTodayPage />}
      />

      <Route path="/cricket/live-today" element={<CricketLiveAndToday />} />

      <Route path="/cricket/live" element={<CricketPage type="live" />} />
      <Route path="/cricket/today" element={<CricketPage type="today" />} />

      <Route path="/football-parlay" element={<FootBallParlayPage />} />
      <Route path="/combo-step" element={<Combo />} />
      <Route path="/:sport/:type" element={<FootballPage />} />
      {/* <Route
        path="/:sport/:type/:leagueId/:matchId"
        element={<MatchMarket />}
      /> */}
      <Route path="/results" element={<Results />} />
      <Route path="/my-bets" element={<MyBets />} />
      <Route path="/statement" element={<Statement />} />
      <Route path="/announcement" element={<Announcement />} />

      <Route
        path="/"
        element={<Navigate to="/football/live-today" replace />}
      />
    </Routes>
  );
};
