import { useTranslation } from "react-i18next";
import Table from "../../components/Table";
import { useState } from "react";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { COLUMNS } from "./columns/config";
import query from "../../query";
import { StyledTable } from "./styled";
import TablePagination from "@mui/material/TablePagination";

const LIMIT_OPTIONS = [20, 50, 100];
const PAGE_DEFAULT = 0;

const Announcement = () => {
  const { i18n, t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(LIMIT_OPTIONS[0]);
  const [page, setPage] = useState(PAGE_DEFAULT);

  const filter = {
    page: page + 1,
    limit: rowsPerPage,
    startDate: moment().startOf("day").format("DD-MM-YYYY"),
    endDate: moment().endOf("day").format("DD-MM-YYYY"),
  };

  const announcementQuery = query.announcement(filter);

  const announcement = useQuery(
    announcementQuery.key,
    announcementQuery.queryFn
  );

  const formattedData =
    announcement.data?.docs.map((doc: any, index: number) => ({
      no: index + 1,
      date: moment.utc(doc.createdAt).format("DD/MM/YYYY hh:mm"),
      message: doc.msg[i18n.language],
    })) || [];

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(PAGE_DEFAULT);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <>
      <div className="container mx-auto px-3">
        <h3 className="text-2xl font-bold mb-3">{t("All Announcement")}</h3>
        <StyledTable>
          <Table data={formattedData} columns={COLUMNS} />
        </StyledTable>
        {!announcement.isLoading && (
          <TablePagination
            rowsPerPageOptions={LIMIT_OPTIONS}
            component="div"
            count={announcement.data?.totalDocs ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </div>
    </>
  );
};

export default Announcement;
