import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC, useEffect, useState } from "react";
import mutation from "../../mutation";
import ComboTabs from "../../contrainers/ComboTabs";
import SportTableLiveAndToday from "../../contrainers/SportTableLiveAndToday";

const Combo: FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const queryClient = useQueryClient();
  const updateOddsType = useMutation(mutation.updateOddsType, {
    onSuccess: (value) => {
      queryClient.setQueryData(["oddsType"], value);
    },
  });
  const updateBetType = useMutation(mutation.updateBetType, {
    onSuccess: (value) => {
      queryClient.setQueryData(["betType"], value);
    },
  });

  const updateBetTicketInfoPayload = useMutation(
    mutation.updateBetTicketInfoPayload,
    {
      onSuccess: (payload) => {
        queryClient.setQueryData(["betTicketInfoPayload"], payload);
      },
    }
  );

  useEffect(() => {
    updateBetTicketInfoPayload.mutate([]);
    updateOddsType.mutate(2); // euro odds type
    updateBetType.mutate("comboStep");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="desktop:container desktop:mx-auto">
      <ComboTabs
        currentIndex={tabIndex}
        data={[
          {
            label: (
              <>
                <img
                  className="w-5 inline mr-2 align-baseline"
                  src="/svg/soccer.svg"
                  alt="ฟุตบอล"
                />
                Football
              </>
            ),
            onClick: (index: number) => {
              setTabIndex(index);
            },
            component: (
              <SportTableLiveAndToday
                todayTitle="Football/Today"
                liveTitle="Football/Live"
                sport="football"
                isBetTypeSelectorDisabled
              />
            ),
          },
          {
            label: (
              <>
                <img
                  className="w-5 inline mr-2 align-baseline"
                  src="/svg/basketball.svg"
                  alt="บาสเก็ตบอล"
                />
                Basketball
              </>
            ),
            onClick: (index: number) => {
              setTabIndex(index);
            },
            component: (
              <SportTableLiveAndToday
                liveTitle="Basketball/Live"
                todayTitle="Basketball/Today"
                sport="basketball"
                isBetTypeSelectorDisabled
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default Combo;
