import { useState, useCallback } from "react";
import { OnChangeValue, ActionMeta } from "react-select";
export type Option<T extends string> = { label: string; value: T };

const useSelectorState = <T extends string>(defaultValue?: Option<T>) => {
  const [value, setValue] = useState<Option<T> | undefined>(defaultValue);

  const onChange = useCallback(
    (
      newValue: OnChangeValue<Option<T>, boolean>,
      _actionMeta: ActionMeta<Option<T>>
    ) => {
      if (newValue && !Array.isArray(newValue)) {
        setValue(newValue as Option<T>);
      }
    },
    []
  );

  return { value, onChange, setValue };
};

export default useSelectorState;
