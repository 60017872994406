import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import query from "../query";
import { Device } from "../types/device";
import { NumberOfMatchType } from "../types/feed";
import useDevice from "./useDevice";

const useRedirectLiveAndToday = (
  sport:
    | "football"
    | "basketball"
    | "volleyball"
    | "tabletennis"
    | "handball"
    | "icehockey"
    | "tennis"
    | "snooker"
    | "baseball"
    | "rugby"
    | "americanfootball"
    | "badminton"
) => {
  const device = useDevice();
  const isDesktop = device === Device.DESKTOP;

  const navigate = useNavigate();
  const numberOfMatchTypeQuery = query.numberOfMatchType();
  const numberOfMatchType = useQuery(
    numberOfMatchTypeQuery.key,
    numberOfMatchTypeQuery.queryFn,
    {
      refetchOnMount: false,
      keepPreviousData: true,
    }
  );

  if (!isDesktop) {
    const mapKey = {
      football: {
        live: "fbLive",
      },
      basketball: {
        live: "bsLive",
      },
      volleyball: {
        live: "vbLive",
      },
      tabletennis: {
        live: "ttLive",
      },
      handball: {
        live: "hbLive",
      },
      icehockey: {
        live: "ihLive",
      },
      tennis: {
        live: "tnLive",
      },
      snooker: {
        live: "snLive",
      },
      baseball: {
        live: "baseballLive",
      },
      rugby: {
        live: "rugbyLive",
      },
      americanfootball: {
        live: "americanFootballLive"
      },
      badminton: {
        live: "badmintonLive"
      }
    };

    const keyLive = mapKey[sport].live;

    if (
      Number(numberOfMatchType.data?.[keyLive as keyof NumberOfMatchType]) > 0
    ) {
      navigate(`/${sport}/live`);
    } else {
      navigate(`/${sport}/today`);
    }
  }
};

export default useRedirectLiveAndToday;
