import { FC } from "react";
import LockIcon from "@mui/icons-material/Lock";

import { OddPrice } from "../../components/Typography";
import { useChangeDetector } from "../../hooks/useOddChangeDetector/useChangeDetector";
import {
  BetTicketInfoForDisplay,
  BetTicketInfoPayload,
} from "../../types/betTicket";
import { OU } from "../../types/cricketFeed";

export type OverUnderProps = {
  isLive: boolean;
  homeTeam: string;
  awayTeam: string;
  leagueName: string;
  leagueId: string;
  matchId: string;
  onClickOddPrice?: (
    payload: BetTicketInfoPayload & BetTicketInfoForDisplay
  ) => void;
} & Partial<OU>;

const OverUnder: FC<OverUnderProps> = ({
  isLive,
  homeTeam,
  awayTeam,
  leagueName,
  leagueId,
  marketId,
  matchId,
  handicap,
  overOdds,
  underOdds,
  overId,
  underId,
  specifiers,
  isActive = true,
  onClickOddPrice,
}) => {
  const { priceChangedType: overChangedType } = useChangeDetector(
    overOdds,
    handicap
  );

  const { priceChangedType: underChangedType } = useChangeDetector(
    underOdds,
    handicap
  );

  if (!isActive) {
    return (
      <>
        <div className="bg-white rounded flex  justify-center items-center py-0.5 pl-2 pr-4 border border-slate-200">
          <LockIcon className="text-gray-500" />
        </div>
        <div className="bg-white rounded flex justify-center items-center py-0.5 pl-2 pr-4 border border-slate-200">
          <LockIcon className="text-gray-500" />
        </div>
      </>
    );
  }

  return (
    <>
      <div
        onClick={() => {
          onClickOddPrice?.({
            type: "normal",
            table: isLive ? "live" : "today",
            sportType: "CRICKET",
            betType: "ou",
            matchId,
            leagueId,
            marketId,
            outcomeId: overId,
            specifiers,
            home: homeTeam,
            away: awayTeam,
            league: leagueName,
            isAwayGood: false,
            isHomeGood: false,
            option: 0,
          });
        }}
        className="flex cursor-pointer justify-between items-center bg-white rounded py-0.5 pl-2 pr-4 border border-slate-2s00"
      >
        <span>
          Over<b className="pl-1">{handicap}</b>
        </span>

        <OddPrice
          className="relative"
          isIncrease={overChangedType === "increase"}
          isDecrease={overChangedType === "decrease"}
        >
          {overOdds}
        </OddPrice>
      </div>
      <div
        onClick={() => {
          onClickOddPrice?.({
            type: "normal",
            table: isLive ? "live" : "today",
            sportType: "CRICKET",
            betType: "ou",
            matchId,
            leagueId,
            marketId,
            outcomeId: underId,
            specifiers,
            home: homeTeam,
            away: awayTeam,
            league: leagueName,
            isAwayGood: false,
            isHomeGood: false,
            option: 1,
          });
        }}
        className="flex cursor-pointer justify-between items-center bg-white rounded py-0.5 pl-2 pr-4 border border-slate-200"
      >
        <span>
          Under<b className="pl-1">{handicap}</b>
        </span>

        <OddPrice
          className="relative"
          isIncrease={underChangedType === "increase"}
          isDecrease={underChangedType === "decrease"}
        >
          {underOdds}
        </OddPrice>
      </div>
    </>
  );
};

export default OverUnder;
