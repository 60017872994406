import { FC } from "react";
import Placeholder from "./Placeholder";
import { useQuery } from "@tanstack/react-query";
import query from "../../query";
import BetTicketInfo from "./BetTicketInfo";

const BetSlip: FC = () => {
  const betTicketInfoPayloadQuery = query.betTicketInfoPayload();
  const betTicketInfoPayload = useQuery(
    betTicketInfoPayloadQuery.key,
    betTicketInfoPayloadQuery.queryFn,
    { refetchOnMount: false, keepPreviousData: true }
  );

  return betTicketInfoPayload.data?.[0] ? (
    <BetTicketInfo payload={betTicketInfoPayload.data} />
  ) : (
    <Placeholder />
  );
};

export default BetSlip;
