import classNames from "classnames";

export type BetPriceType =
  | "ah"
  | "ou"
  | "oe"
  | "x12"
  | "ah1st"
  | "ou1st"
  | "x121st"
  | "ml"
  | "ml1st"
  | "dc"
  | "awn"
  | "hwn"
  | "yn"
  | "ato"
  | "cr"
  | "hto"
  | "ht/ft";

export type Option = 0 | 1 | 2; // 0: home, 1: away, 2: draw

export type BettingTeamNameProps = {
  isHomeGoodTeam: boolean;
  isAwayGoodTeam: boolean;
  home: string;
  away: string;
  betPriceType: BetPriceType;
  option?: Option;
};

const BettingTeamName = (props: BettingTeamNameProps) => {
  switch (props.betPriceType) {
    case "oe":
      if (props.option === 0) {
        return <div className="italic font-semibold">Odd</div>;
      }
      return <div className="italic font-semibold">Even</div>;
    case "ou":
    case "ou1st":
      if (props.option === 0) {
        return <div className="italic font-semibold">Over</div>;
      }
      return <div className="italic font-semibold">Under</div>;
    case "cr":
    case "ht/ft":
      return <></>;
    case "yn":
      if (props.option === 0) {
        return <div className="italic font-semibold">Yes</div>;
      }
      return <div className="italic font-semibold">No</div>;
    default:
      if (props.option === 0) {
        return (
          <div
            className={classNames("italic font-semibold", {
              "text-red-500": props.isHomeGoodTeam,
            })}
          >
            {props.home}
          </div>
        );
      } else if (props.option === 1) {
        return (
          <div
            className={classNames("italic font-semibold", {
              "text-red-500": props.isAwayGoodTeam,
            })}
          >
            {props.away}
          </div>
        );
      }
      return <div>Draw</div>;
  }
};

export default BettingTeamName;
