import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import TH from "../translation/TH";
import EN from "../translation/EN";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      th: {
        translation: TH,
      },
      en: {
        translation: EN,
      },
    },
    detection: {
      lookupQuerystring: "lang",
    },
    fallbackLng: "en",
    lng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
