import { CellContext } from "@tanstack/react-table";
import styled from "styled-components";
import { FormattedFeed, MatchName as MatchNameType } from "../../../types/feed";
import { TeamName } from "../../../components/Typography";
import { CustomCellContext } from "../../../types/table";
import { isGoodTeam } from "../../../utils/isGoodTeam";
import { useTranslation } from "react-i18next";

const Score = styled.b`
  min-width: 15px;
`;

const MatchName = (
  row: CellContext<FormattedFeed, unknown> & CustomCellContext
) => {
  const { i18n, t } = useTranslation();
  if (row.row.original.betPrice_order !== 0) {
    return "";
  }

  const matchName = row.getValue<MatchNameType>();

  if (!matchName) {
    return "";
  }

  return (
    <div className="space-y-1 p-1">
      <div className="flex space-x-1">
        {row.row.original.tableType === "live" && (
          <Score>{row.row.original?.match_i.h}</Score>
        )}

        <img
          src={`https://data.askmescore.live/static/images/teams/${row.row.original?.match_n?.fhid}?size=large`}
          alt=""
          className="w-5 h-5"
          onError={(event) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = `${process.env.PUBLIC_URL}/team.svg`;
          }}
        />

        <TeamName
          className="text-left"
          isGoodTeam={isGoodTeam(row.row.original?.betPrice_ah?.h)}
        >
          {matchName[i18n.language].h}

          {Boolean(Number(row.row.original?.match_i.hrc)) && (
            <span className="ml-2">
              {Array.from({
                length: Number(row.row.original?.match_i.hrc),
              }).map((_each, index) => (
                <img
                  key={index}
                  className="inline"
                  src="/red.png"
                  alt="red-card"
                  width={20}
                />
              ))}
            </span>
          )}
        </TeamName>
      </div>
      <div className="flex space-x-1">
        {row.row.original.tableType === "live" && (
          <Score>{row.row.original?.match_i.a}</Score>
        )}

        <img
          src={`https://data.askmescore.live/static/images/teams/${row.row.original?.match_n?.faid}?size=large`}
          alt=""
          className="w-5 h-5"
          onError={(event) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = `${process.env.PUBLIC_URL}/team.svg`;
          }}
        />

        <TeamName
          className="text-left"
          isGoodTeam={isGoodTeam(row.row.original?.betPrice_ah?.a)}
        >
          {matchName[i18n.language].a}
          {Boolean(Number(row.row.original?.match_i.arc)) && (
            <span className="ml-2">
              {Array.from({
                length: Number(row.row.original?.match_i.arc),
              }).map(() => (
                <img
                  className="inline"
                  src="/red.png"
                  alt="red-card"
                  width={20}
                />
              ))}
            </span>
          )}
        </TeamName>
      </div>
      <div className="font-bold text-left">{t("Draw")}</div>
    </div>
  );
};

export default MatchName;
