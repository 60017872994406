import { FC } from "react";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { Wrapper, Line } from "./styled";
import UserIcon from "../../components/Icons/UserIcon";
import ArrowPathIcon from "../../components/Icons/ArrowPathIcon";
import query from "../../query";
import { commaNumber } from "../../utils/commaNumber";
import LanguageSelector from "../../contrainers/LanguageSelector";
import useDevice from "../../hooks/useDevice";
import { Device } from "../../types/device";
import { Link } from "react-router-dom";

const TopNav: FC = () => {
  const profile = query.profile();
  const { data, isLoading, refetch } = useQuery(profile.key, profile.queryFn, {
    staleTime: 10000,
  });
  const currency = data?.cur[0];
  const profileClass = classNames(
    "m-2 flex font-bold flex items-center mobile:text-sm tablet:text-md",
    {
      "w-20 bg-gray-200 h-4 rounded-md animate-pulse": isLoading,
    }
  );
  const isBalanceShown = !isLoading && currency && data?.balance;

  const device = useDevice();
  const isMobile = device === Device.MOBILE;

  const showBalance = () => {
    if (!isBalanceShown) return "";

    const balance = data?.balance?.[currency]
      ? Number(data?.balance?.[currency].balance.$numberDecimal)
      : Number(data?.balance);

    if (Number.isNaN(balance)) return "";

    return `${commaNumber(balance)} ${currency}`;
  };

  return (
    <Wrapper>
      <div className="w-full flex items-center justify-between px-4 tablet:px-8">
        <Link to="/">
          <img
            src={`${process.env.PUBLIC_URL}/askmebet-logo.webp`}
            alt="logo"
            width={120}
          />
        </Link>
        <div className="flex">
          <div className={profileClass} style={{ lineHeight: "normal" }}>
            {showBalance()}
            {isBalanceShown && (
              <button
                className="ml-2"
                onClick={() => {
                  refetch();
                }}
              >
                <ArrowPathIcon />
              </button>
            )}
          </div>
          {!isMobile && (
            <>
              <Line />
              <div className={profileClass}>
                {!isLoading && <UserIcon className="mr-2 my-auto" />}
                {!isLoading && data?.nickname}
              </div>
              <div className="desktop:ml-3 mobile:ml-1 flex items-center">
                <LanguageSelector />
              </div>
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default TopNav;
