import { useEffect, useRef, useState, useCallback } from "react";

export type CountDownProps = {
  initialCounter: number;
  isLoading?: boolean;
  onTimeOut?: () => void;
};

export const useCountDown = ({
  initialCounter,
  isLoading = false,
  onTimeOut = () => {},
}: CountDownProps) => {
  const intervalId = useRef<NodeJS.Timer>();
  const [counter, setCounter] = useState(initialCounter);

  useEffect(() => {
    if (isLoading && intervalId.current) {
      clearInterval(intervalId.current);
    } else {
      intervalId.current = setInterval(() => {
        setCounter((prevState) => {
          if (prevState > 0) {
            return prevState - 1;
          }

          onTimeOut();
          return initialCounter;
        });
      }, 1000);
    }

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const resetCounter = useCallback(() => {
    setCounter(() => initialCounter);
  }, [initialCounter]);

  return {
    counter,
    resetCounter,
  };
};
