import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useLocation } from "react-router-dom";

import query from "../../query";

const useSportsBar = () => {
  const numberOfMatchTypeQuery = query.numberOfMatchType();
  const { pathname } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, sport, type] = pathname.split("/");

  const numberOfMatchType = useQuery(
    numberOfMatchTypeQuery.key,
    numberOfMatchTypeQuery.queryFn,
    {
      refetchInterval: 30 * 1000,
      refetchIntervalInBackground: false,
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          window.localStorage.removeItem("auth");
        }
      },
    }
  );

  return { numberOfMatchType, sport, type };
};

export default useSportsBar;
