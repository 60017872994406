import { forwardRef, HTMLAttributes, ChangeEventHandler } from "react";
import classNames from "classnames";
import styled from "styled-components";

const StyledInput = styled.input`
  &.checked {
    ${({ theme }) =>
      `
      background-color:${theme.global.Primary};
      border-color:${theme.global.Primary};
     `}
  }
`;

const Checkbox = forwardRef<
  HTMLInputElement,
  {
    onChange?: ChangeEventHandler<HTMLInputElement>;
    checked?: boolean;
  } & HTMLAttributes<HTMLInputElement>
>((props, ref) => {
  const { className, onChange, checked, ...other } = props;
  return (
    <StyledInput
      className={classNames(
        className,
        { checked },
        "form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
      )}
      type="checkbox"
      ref={ref}
      onChange={onChange}
      checked={checked}
      {...other}
    />
  );
});

export default Checkbox;
