import styled from "styled-components";

export enum ButtonType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  DEFAULT = "DEFAULT",
}

export type ButtonProps = {
  buttonType?: ButtonType;
};

export const Button = styled.button<ButtonProps>`
  filter: ${({ disabled }) => (disabled ? "opacity(0.5)" : "none")};
  border: 1px solid
    ${({ theme, buttonType }) =>
      buttonType === ButtonType.SECONDARY
        ? theme.global.borderSecondary
        : buttonType === ButtonType.PRIMARY
        ? theme.global.Primary
        : theme.global.Default};
  background-color: ${({ theme, buttonType }) =>
    buttonType === ButtonType.SECONDARY
      ? theme.global.Secondary
      : buttonType === ButtonType.PRIMARY
      ? theme.global.Primary
      : theme.global.Default};

  color: ${({ theme, buttonType }) =>
    buttonType === ButtonType.SECONDARY
      ? theme.global.textColorInSecondary
      : buttonType === ButtonType.PRIMARY
      ? theme.global.textColorInPrimary
      : theme.global.textColorInSecondary};
  border-radius: 0.25rem;
  min-height: 30px;
`;
