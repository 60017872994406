const TH = {
  Draw: "เสมอ",
  "All Announcement": "ประกาศทั้งหมด",
  "List of Sports": "รายการกีฬา",
  Football: "ฟุตบอล",
  Basketball: "บาสเก็ตบอล",
  "Coming Soon": "เร็วๆนี้",
  Cricket: "คริกเก็ต",
  Volleyball: "วอลเลย์บอล",
  Boxing: "มวย",
  Combo: "คอมโบ",
  "Table Tennis": "ปิงปอง",
  Tennis: "เทนนิส",
  "All Result": "ผลการแข่งขัน",
  "My Bet": "การเดิมพันทั้งหมด",
  Statement: "รายการบัญชี",
  "Date time": "ระยะเวลา",
  Type: "ประเภท",
  "Date & Time": "วันที่ & เวลา",
  "Live / Today": "สด / วันนี้",
  Live: "สดตอนนี้",
  Today: "วันนี้",
  Early: "ล่วงหน้า",
  ALL: "ทั้งหมด",
  Leagues: "ลีก",
  League: "ลีก",
  Search: "ค้นหา",
  "Select / Cancel all": "เลือก / ยกเลิก ทั้งหมด",
  selected: "เลือกแล้ว",
  "League is not found": "ไม่พบลีกที่ค้นหา",
  Confirm: "ยืนยัน",
  Cancel: "ยกเลิก",
  "No competition": "ไม่มีรายการแข่งขัน",
  "No statement on this date range": "ไม่พบรายการบัญชี ตามช่วงเวลาที่เลือกไว้",
  Competition: "การแข่งขัน",
  "Half-time": "ครึ่งแรก",
  "Full-time": "เต็มเวลา",
  Date: "วันที่",
  "No.": "ลำดับ",
  Message: "ข้อความ",
  "Bet amount": "จำนวนเงินเดิมพัน",
  "Win / Lose": "ชนะ / แพ้",
  Commission: "คอมมิชชั่น",
  Total: "รวม",
  "Place bet success": "การเดิม พันสำเร็จ",
  "Bet failed": "การเดิมพัน ไม่สำเร็จ",
  VALIDATION_ERROR: "มีค่าบ้างอย่างไม่ถูกต้อง",
  RATE_LIMIT_REQUEST: "คำขอเกินกำหนด โปรดลองใหม่อีกครั้งในภายหลัง",
  CANNOT_GET_PROFILE: "ไม่สามารถดึงข้อมูลส่วนบุคคลได้",
  ODDS_CHANGED: "ราคาค่าน้ำมีการเปลี่ยนแปลง โปรดลองใหม่อีกครั้ง",
  MATCH_NOT_FOUND: "ไม่พบแข่งขัน",
  OVER_LITMIT_PER_MATCH: "จำนวนเงินต่อการแข่งขัน เกินกำหนดที่ตั้งไว้แล้ว",
  OVER_LITMIT_PER_BILL: "จำนวนเงินต่อบิล เกินกำหนดที่ตั้งไว้แล้ว",
  OVER_LITMIT_PER_DAY: "จำนวนเงินต่อวัน เกินกำหนดที่ตั้งไว้แล้ว",
  ODDS_INVALID: "ค่าน้ำผิด",
  DUPLICATE_BET: "การเดิมพันซ้ำซ้อน",
  INSUFFICIENT_BALANCE: "จำนวนเงินของคุณไม่พอ",
  LOCKED_ACCOUNT: "การใช้งานของคุณถูกล็อก",
  SUSSPENDED_ACCOUNT: "การใช้งานของคุณถูกระงับ",
  UNAVAILABLE_BET: "ไม่พบการเดิมพันที่ระบุ",
  TICKET_REJECTED: "การเดิมพันถูกยกเลิก",
  SYSTEM_ERROR: "ระบบทำงานผิดพลาด โปรดแจ้งแอดมิน",
  INVALID_CURRENCY: "ค่าเงินที่ใช้มีปัญหา",
  INVALID_AMOUNT: "จำนวนที่ระบุมามีปัญหา",
  SESSION_EXPIRED: "การเข้าระบบหมดอายุ",
};

export default TH;
