import { useEffect, useRef, useState } from "react";
import { ChangedType, TIMEOUT_DELEY } from "./index";

export const useChangeDetector = (
  price?: number | string,
  handicap?: string
) => {
  const previousHandicap = useRef<string>();
  const previousPrice = useRef<number>();
  const timeoutId = useRef<NodeJS.Timeout>();
  const [priceChangedType, setPriceChangedType] = useState<ChangedType>();

  useEffect(() => {
    if (!price) return;

    if (timeoutId.current && previousPrice.current === Number(price)) {
      return;
    }
    if (timeoutId.current) clearTimeout(timeoutId.current);

    timeoutId.current = setTimeout(() => {
      setPriceChangedType(() => undefined);
      timeoutId.current = undefined;
    }, TIMEOUT_DELEY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceChangedType]);

  useEffect(() => {
    if (!price) {
      return;
    }

    const currentPrice = Number(price);
    const currentHandicap = handicap;
    const isOddPriceRed = currentPrice < 0;
    const isPreviousOddPriceRed = (previousPrice?.current || 0) < 0;

    if (previousHandicap.current !== currentHandicap) {
      previousHandicap.current = currentHandicap;

      return;
    }
    if (previousPrice.current) {
      if (!isPreviousOddPriceRed && isOddPriceRed) {
        setPriceChangedType("increase");
      } else if (isPreviousOddPriceRed && !isOddPriceRed) {
        setPriceChangedType("decrease");
      } else if (currentPrice > previousPrice.current) {
        setPriceChangedType("increase");
      } else if (currentPrice < previousPrice.current) {
        setPriceChangedType("decrease");
      }
    }

    previousHandicap.current = currentHandicap;
    previousPrice.current = currentPrice;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price]);

  return { priceChangedType };
};
