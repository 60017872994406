import _ from "lodash";
import moment from "moment";

const formatData = (data: any, language: string) =>
  _.flatten(
    data.map((each: any) => {
      return each.m.map((match: any, index: number) => {
        return {
          leagueId: each.k,
          leagueName: each.nn[language],
          date: moment(match.od).format("DD-MM-YYYY HH:mm"),
          match: `${match.n[language].h} \n ${match.n[language].a}`,
          halfTime: match.ht,
          fullTime: match.ft,
          feed_order: index,
        };
      });
    }) ?? []
  );

export default formatData;
