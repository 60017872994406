import { FC, HTMLAttributes } from "react";
import { NumberOfMatchType } from "../../types/feed";

export type NumberOfMatchProps = {
  isLoading: boolean;
  data?: NumberOfMatchType;
  keyData?: (keyof NumberOfMatchType)[];
};

const NumberOfMatch: FC<
  NumberOfMatchProps & HTMLAttributes<HTMLDivElement>
> = ({ isLoading, data, keyData, className = "" }) => {
  if (isLoading || !data) {
    return (
      <div
        className={`w-4 bg-gray-200 h-4 rounded-md animate-pulse ${className}`}
      />
    );
  }

  const totalNumberOfMatch = keyData?.reduce(
    (total, key) => total + data[key],
    0
  );

  return <div className={className}>{ `${totalNumberOfMatch}` }</div>;
};

export default NumberOfMatch;
