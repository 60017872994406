import moment from "moment";
import { FC } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import Match from "./Match";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyToClipboard from "react-copy-to-clipboard";
import { isGoodTeam } from "../../../utils/isGoodTeam";
import { Header, Status, Total } from "./styled";
import { BetPriceType } from "../../../types/common";
import { convertHDP } from "../../../utils/convertHDP";
import betTypeDisplay from "../../../utils/betTypeDisplay";
import BettingTeamName from "../../../components/BettingTeamName";
import { Currency } from "../../../types/member";

export type TicketProps = {
  betId: string;
  betAt: string | Date;
  status: string;
  matchs: any[];
  betType: string;
  amount: number;
  currency?: Currency;
};

const mappedBetPriceType: Record<BetPriceType, string> = {
  ah: "HDP",
  ah1st: "Half time HDP",
  oe: "Odd/Even",
  ou: "Over/Under",
  ou1st: "Half time Over/Under",
  x12: "1X2",
  x121st: "Half time 1X2",
  ml: "Money line",
  ml1st: "Half time Money line",
  dc: "Double Chance",
  awn: "Away Win to Nil",
  hwn: "Home Win to Nil",
  yn: "Both Teams to Score",
  ato: "Away Team Total Goal",
  cr: "Correct Score",
  hto: "Home Team Total Goal",
  "ht/ft": "Half Time / Full Time",
};

const Ticket: FC<TicketProps> = ({
  betId,
  betAt,
  status,
  matchs,
  betType,
  amount,
  currency = "THB",
}) => {
  const { i18n } = useTranslation();
  const tableType = () => {
    const isLive = matchs.some((match) => match.table === "live");
    const isToday = matchs.some((match) => match.table === "today");

    if (isLive && isToday) {
      return "parlay";
    } else if (isLive) {
      return "live";
    }
    return "today";
  };

  const totalOddPrice = matchs.reduce((total, match) => total * match.price, 1);

  return (
    <div>
      <Header tableType={tableType()}>
        <div>{betId}</div>
        <button className="text-sm svg-rem">
          <CopyToClipboard
            text={betId}
            onCopy={() => {
              window.alert(`Copied ${betId}`);
            }}
          >
            <ContentCopyIcon />
          </CopyToClipboard>
        </button>
      </Header>
      <div>
        <div className="flex justify-between p-2">
          <div className="font-bold text-xs">
            {moment(betAt).format("DD/MM/YYYY HH:mm")}
          </div>
          <Status type={status}>{_.capitalize(status)}</Status>
        </div>
        {matchs?.map((match: any) => {
          let isHomeTeamGood = false;
          let isAwayTeamGood = false;

          if (match.value !== "0" || match.option === 3) {
            if (match.option === 0 && isGoodTeam(match.value)) {
              isHomeTeamGood = true;
            }

            if (match.option === 0 && !isGoodTeam(match.value)) {
              isAwayTeamGood = true;
            }

            if (match.option === 1 && isGoodTeam(match.value)) {
              isAwayTeamGood = true;
            }

            if (match.option === 1 && !isGoodTeam(match.value)) {
              isHomeTeamGood = true;
            }
          }

          const handicap = convertHDP(match.value);
          const mappedTeamWithOption = {
            name: betTypeDisplay(match.option, match.betType, {
              h: match.matchName[i18n.language].h,
              a: match.matchName[i18n.language].a,
            }),
            isGoodTeam: true,
          };

          return (
            <Match
              currency={currency}
              date={match.d}
              isLive={match.table === "live"}
              liveScore={`${match.info.lt} (${match.info.h}:${match.info.a})`}
              key={match._id}
              homeTeam={match.matchName[i18n.language].h}
              awayTeam={match.matchName[i18n.language].a}
              isHomeTeamGood={isHomeTeamGood}
              isAwayTeamGood={isAwayTeamGood}
              league={match.leagueName[i18n.language]}
              sportType={match.sportType}
              betPriceType={mappedBetPriceType[match.betType as BetPriceType]}
              betType={betType}
              handicap={Number.isNaN(handicap) ? handicap : match.value}
              BettingTeamName={
                <BettingTeamName
                  betPriceType={match.betType}
                  option={match.option}
                  bettingTeamName={mappedTeamWithOption.name}
                  isGoodTeam={mappedTeamWithOption.isGoodTeam}
                />
              }
              oddPrice={match.price}
              oddsType={
                match.oddsType === "0"
                  ? "MY"
                  : match.oddsType === "1"
                  ? "HK"
                  : "EU"
              }
              amount={amount}
            />
          );
        })}
        {(betType === "step" || betType === "comboStep") && (
          <Total>
            <div>
              {_.capitalize(betType === "step" ? "parlay" : "comboStep")} @
              {Math.abs(totalOddPrice).toFixed(2)} EU
            </div>
            <div>
              {amount} {currency}
            </div>
          </Total>
        )}
      </div>
    </div>
  );
};

export default Ticket;
