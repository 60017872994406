import { FC, useRef, HTMLAttributes, memo } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Collapse from "@material-tailwind/react/components/Collapse";
import dayjs from "dayjs";
import classNames from "classnames";

import { Market } from "../../types/cricketFeed";
import MarketCard from "./MarketCard";
import useToggleState from "../../hooks/useToggleState";
import {
  BetTicketInfoForDisplay,
  BetTicketInfoPayload,
} from "../../types/betTicket";

export type CricketTableProps = {
  leagueId: string;
  matchId: string;
  homeTeam: string;
  awayTeam: string;
  leagueName: string;
  battingTeam?: "home" | "away";
  isLive?: boolean;
  homeScore?: string;
  awayScore?: string;
  markets: {
    others: Market[];
    mains: Market[];
  };
  startTime?: string;
  onClickOddPrice?: (
    payload: BetTicketInfoPayload & BetTicketInfoForDisplay
  ) => void;
} & HTMLAttributes<HTMLDivElement>;

const CricketTable: FC<CricketTableProps> = ({
  matchId,
  leagueId,
  homeTeam,
  awayTeam,
  battingTeam = "home",
  isLive = false,
  homeScore,
  awayScore,
  markets,
  startTime,
  className,
  leagueName,
  onClickOddPrice,
}) => {
  const { isToggled, toggle } = useToggleState();
  const collapseRef = useRef<HTMLDivElement>(null);
  const onClickExpendOther = () => {
    if (!isToggled) {
      collapseRef?.current?.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        toggle();
      }, 300);
    } else {
      toggle();
    }
  };

  return (
    <div className={classNames(["px-2 py-5", className])}>
      <div className="flex gap-x-9 mb-5 relative">
        <div className="flex-1">
          <h4 className="font-bold text-right">{homeTeam}</h4>
          <div
            className={classNames("text-right text-xs font-bold", {
              "text-orange-700": battingTeam === "home",
              "text-gray-500": battingTeam !== "home",
            })}
          >
            {homeScore}
          </div>
        </div>
        {isLive ? (
          <div className="flex items-start">
            <div className="bg-rose-600 px-2 text-white font-medium text-sm rounded">
              LIVE
            </div>
          </div>
        ) : (
          <pre className="text-center px-5 text-sm">
            {dayjs(startTime).utc().format("DD/MM\nHH:mm A")}
          </pre>
        )}
        <div className="flex-1">
          <h4 className="font-bold">{awayTeam}</h4>
          <div
            className={classNames("text-xs font-bold", {
              "text-orange-700": battingTeam === "away",
              "text-gray-500": battingTeam !== "away",
            })}
          >
            {awayScore}
          </div>
        </div>

        <button
          className={classNames(
            "text-xs font-bold flex items-center absolute right-0 top-0 rounded pl-1 border border-solid",
            { "border-green-700": !isLive, "border-orange-700": isLive }
          )}
          onClick={onClickExpendOther}
        >
          Other
          <b className="text-xs ml-1 bg-white px-1 rounded align-middle border border-solid border-gray-200">
            {markets.others?.length ?? 0}
          </b>
          <ExpandLessIcon
            style={{
              transition: "transform 200ms",
            }}
            className={classNames({
              "rotate-180": !isToggled,
              "rotate-0": isToggled,
            })}
          />
        </button>
      </div>
      {markets.mains?.length > 0 && (
        <div
          className={classNames("mb-5 -mx-2 px-2 border-b-2 border-solid", {
            "border-orange-700": isLive,
            "border-green-700": !isLive,
          })}
        >
          <div
            className={classNames(
              "px-2 h-8 w-40 rounded-t-lg text-center border border-solid",
              {
                "border-orange-700 bg-orange-700": isLive,
                "border-green-700 bg-green-700": !isLive,
              }
            )}
          >
            <span className="text-white align-middle">MAIN</span>
            <b className="text-xs ml-1 bg-white px-1 rounded align-middle">
              {markets.mains?.length ?? 0}
            </b>
          </div>
        </div>
      )}

      <MarketCard
        leagueName={leagueName}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
        onClickOddPrice={onClickOddPrice}
        matchId={matchId}
        leagueId={leagueId}
        data={markets.mains}
        isLive={isLive}
      />

      <Collapse
        open={isToggled}
        className="mt-5 -mx-2 px-2 box-content"
        ref={collapseRef}
      >
        <div
          className={classNames("mb-5 -mx-2 px-2 border-b-2 border-solid", {
            "border-orange-700": isLive,
            "border-green-700": !isLive,
          })}
        >
          <div
            className={classNames(
              "px-2 h-8 w-40 rounded-t-lg text-center border border-solid",
              {
                "border-orange-700 bg-orange-700": isLive,
                "border-green-700 bg-green-700": !isLive,
              }
            )}
          >
            <span className="text-white align-middle">OTHER</span>
            <b className="text-xs ml-1 bg-white px-1 rounded align-middle">
              {markets.others?.length ?? 0}
            </b>
          </div>
        </div>
        <MarketCard
          leagueName={leagueName}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          onClickOddPrice={onClickOddPrice}
          matchId={matchId}
          leagueId={leagueId}
          data={markets.others}
          isLive={isLive}
        />
      </Collapse>
    </div>
  );
};

export default memo(CricketTable);
