import { FC, useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import query from "../../../query";
import { SportTableProps } from "../index";
import capitalize from "lodash/capitalize";
import TableMobile from "./TableMobile";
import { useSearchParams } from "react-router-dom";
import {
  BetTicketInfoForDisplay,
  BetTicketInfoPayload,
} from "../../../types/betTicket";
import Drawer from "@mui/material/Drawer";
import mutation from "../../../mutation";
import MatchMarket from "../../../pages/MatchMarket";
import produce from "immer";
import { SportType, Option, BetPriceType } from "../../../types/common";
import { useTranslation } from "react-i18next";
import useToggleState from "../../../hooks/useToggleState";

export type NumberOfMatchValue = { key: string; path: string };

export type MapNumberOfMatchTypeKey = {
  live: NumberOfMatchValue;
  today: NumberOfMatchValue;
  early?: NumberOfMatchValue;
  palay?: NumberOfMatchValue;
  combo?: NumberOfMatchValue;
};

const SportTableMobile: FC<SportTableProps> = ({
  sport = "football",
  type = "today",
}) => {
  const { t } = useTranslation();
  const { isToggled, toggle } = useToggleState();
  const betTypeQuery = query.betType();
  const betType = useQuery(betTypeQuery.key, betTypeQuery.queryFn, {
    refetchOnMount: false,
    keepPreviousData: true,
  });

  const updateIsBetSlipToggle = useMutation(mutation.updateIsBetSlipToggle, {
    onSuccess: (newIsBetSlipToggle) => {
      queryClient.setQueryData(["isBetSlipToggle"], newIsBetSlipToggle);
    },
  });

  const updateCurrentTabIndex = useMutation(mutation.updateCurrentTabIndex, {
    onSuccess: (index) => {
      queryClient.setQueryData(["currentTabIndex"], index);
    },
  });

  let sportFeedQuery;

  switch (sport) {
    case "badminton": {
      sportFeedQuery = query.badmintonFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "americanfootball": {
      sportFeedQuery = query.americanfootballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "rugby": {
      sportFeedQuery = query.rugbyFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "baseball": {
      sportFeedQuery = query.baseballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "snooker": {
      sportFeedQuery = query.snookerFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "icehockey": {
      sportFeedQuery = query.icehockeyFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "handball": {
      sportFeedQuery = query.handballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "tennis": {
      sportFeedQuery = query.tennisFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "tabletennis": {
      sportFeedQuery = query.tabletennisFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "basketball": {
      sportFeedQuery = query.basketballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "volleyball": {
      sportFeedQuery = query.volleyballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    default:
      sportFeedQuery = query.footballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
  }

  const sportFeed = useQuery(sportFeedQuery.key, sportFeedQuery.queryFn);
  const queryClient = useQueryClient();

  const updateBetTicketInfoPayload = useMutation(
    mutation.updateBetTicketInfoPayload,
    {
      onSuccess: (payload) => {
        queryClient.setQueryData(["betTicketInfoPayload"], payload);
      },
    }
  );

  let [_searchParams, setSearchParams] = useSearchParams();

  const handleClose = useCallback(() => {
    toggle();
    setSearchParams({});
  }, [setSearchParams, toggle]);

  const onClickOddPrice = useCallback(
    async (payload: BetTicketInfoPayload & BetTicketInfoForDisplay) => {
      const betTicketInfoPayloads =
        queryClient.getQueryData<
          (BetTicketInfoPayload & BetTicketInfoForDisplay)[]
        >(["betTicketInfoPayload"]) ?? [];
      const index = betTicketInfoPayloads.findIndex((betTicketInfoPayload) => {
        return betTicketInfoPayload.matchId === payload.matchId;
      });

      const exactMatchIndex =
        index > -1
          ? betTicketInfoPayloads.findIndex((betTicketInfoPayload) => {
              return (
                betTicketInfoPayload.matchId === payload.matchId &&
                betTicketInfoPayload.option === payload.option &&
                betTicketInfoPayload.riskLevel === payload.riskLevel &&
                betTicketInfoPayload.betType === payload.betType
              );
            })
          : -1;

      if (betType?.data === "normal") {
        if (exactMatchIndex >= 0) {
          await updateBetTicketInfoPayload.mutate([]);
        } else {
          await updateBetTicketInfoPayload.mutate([payload]);
          updateCurrentTabIndex.mutate(0);
          updateIsBetSlipToggle.mutate(true);
        }
      } else {
        if (index >= 0) {
          const newBetTicketInfoPayload = produce(
            betTicketInfoPayloads,
            (draft) => {
              if (exactMatchIndex >= 0) {
                draft.splice(index, 1);
              } else {
                draft[index] = payload;
              }
            }
          );
          await updateBetTicketInfoPayload.mutate(newBetTicketInfoPayload);
        } else {
          if (betTicketInfoPayloads.length >= 12) {
            toast.error("This betting is reached the maximun limit");
            return;
          }

          await updateBetTicketInfoPayload.mutate([
            ...betTicketInfoPayloads,
            payload,
          ]);

          if (betTicketInfoPayloads.length === 11) {
            updateCurrentTabIndex.mutate(0);
            updateIsBetSlipToggle.mutate(true);
          }
        }
      }
    },

    [
      betType?.data,
      queryClient,
      updateBetTicketInfoPayload,
      updateCurrentTabIndex,
      updateIsBetSlipToggle,
    ]
  );

  const handle = (payload1: { leagueId: string; leagueName: string }) => {
    return (payload2: {
      matchId: string;
      homeName: string;
      awayName: string;
      fhid: string; // home flag
      faid: string; // away flag
    }) => {
      return (payload3: {
        option: Option;
        riskLevel: number;
        betType: BetPriceType;
        isHomeGood: boolean;
        isAwayGood: boolean;
        outcomeId: string;
        specifiers: string;
        marketId: string;
      }) => {
        onClickOddPrice({
          leagueId: payload1.leagueId,
          matchId: payload2.matchId,
          option: payload3.option,
          sportType: sport.toUpperCase() as SportType,
          table: type,
          type: betType?.data ?? "normal",
          betType: payload3.betType,
          home: payload2.homeName,
          away: payload2.awayName,
          fhid: payload2.fhid,
          faid: payload2.faid,
          isHomeGood: payload3.isHomeGood,
          isAwayGood: payload3.isAwayGood,
          league: payload1.leagueName,
          riskLevel: payload3.riskLevel,
          outcomeId: payload3.outcomeId,
          specifiers: payload3.specifiers,
          marketId: payload3.marketId,
        });
      };
    };
  };

  return (
    <>
      {/* <div className="flex px-2 justify-between bg-gray-800 text-white py-1 text-sm">
        <div>Asian Handicap</div>
        <div className="flex">
          <div className="w-16 text-center ">Home</div>
          <div className="w-16 text-center border-l border-gray-600">Away</div>
        </div>
      </div> */}
      <h1 className="text-xl my-2 ml-2">
        {capitalize(sport)}/{capitalize(type)}
      </h1>
      {sportFeed.data?.length ? (
        sportFeed.data?.map((league) => (
          <div key={league.k}>
            <TableMobile
              sport={sport}
              league={league}
              onClickOddPrice={handle}
              onClickMore={toggle}
              type={type}
            />
          </div>
        ))
      ) : (
        <div className="text-center text-md my-4">{t("No competition")}</div>
      )}

      <Drawer open={isToggled} onClose={handleClose} anchor="bottom">
        <div style={{ height: "80vh" }}>
          <MatchMarket onClose={handleClose} />
        </div>
      </Drawer>
    </>
  );
};

export default SportTableMobile;
