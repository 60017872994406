import styled from "styled-components";
import { SCREENS } from "../../constants/screens";

export const Wrapper = styled.div.attrs({
  className: "flex overflow-y-auto flex-col",
})`
  width: 240px;
  height: 100vh;
  padding-top: 140px;
  position: fixed;
  z-index: 500;

  @media (max-width: ${SCREENS.desktop}) {
    top: 100px;
    padding-top: 100px;
  }
`;

export const SportsBarWrapper = styled.div`
  background-color: white;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 0 0 4px 4px;
`;
