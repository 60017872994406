import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.global.Secondary};
  width: 100%;
  min-height: 65px;
  display: flex;
  justify-content: center;
`;

export const BalanceWrapper = styled.div`
  background-color: ${({ theme }) => theme.global.Secondary};
`;

export const Background = styled.div`
  background-color: ${({ theme }) => theme.global.BackgroundOutside};
`;