import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { FC, useCallback, useMemo, useEffect } from "react";
import produce from "immer";
import query from "../../query";
import mutation from "../../mutation";
import Table from "../../components/Table";
import { FormattedFeed } from "../../types/feed";
import BreadCrumb from "../../components/BreadCrumb";
import Refresh from "../../components/Refresh";
import LeagueFilterButton from "../../components/LeagueFilterButton";
import {
  BetTicketInfoPayload,
  BetTicketInfoForDisplay,
} from "../../types/betTicket";
import {
  SPORTS_WITHOUT_PALAY,
  SPORTS_WITHOUT_COMBO,
} from "../../constants/common";
import {
  COLUMNS,
  COLUMNS_WITH_ML,
  COLUMNS_FULLTIME_ONLY,
  COLUMNS_FULLTIME_ONLY_WITH_ML,
} from "./columns/config";
import { StyledTable, StyledButton } from "./styled";
import SportBarService from "../../services/sportBar";
import { formatFeeds } from "./formatFeeds";
import { TabelType } from "../../types/common";
import { renderSubComponent } from "./renderSubComponent";
// import { SpecialPriceComponent } from "./specialPriceComponent";
import BetTypeSelector from "../../contrainers/BetTypeSelector";
import LeagueFilterModal from "../../contrainers/LeagueFilterModal";
import useLeagueFilterModal from "../../contrainers/LeagueFilterModal/useLeagueFilterModal";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const sportBarService = new SportBarService();

export type SportTableProps = {
  sport:
    | "football"
    | "basketball"
    | "volleyball"
    | "tabletennis"
    | "handball"
    | "icehockey"
    | "tennis"
    | "snooker"
    | "baseball"
    | "rugby"
    | "americanfootball"
    | "badminton";
  type: TabelType;
  title: string;
};

const SportTable: FC<SportTableProps> = ({
  sport = "football",
  type = "today",
  title = "",
}) => {
  const { i18n } = useTranslation();

  const leagueFilterModal = useLeagueFilterModal();
  const queryClient = useQueryClient();
  const updateCurrentTabIndex = useMutation(mutation.updateCurrentTabIndex, {
    onSuccess: (index) => {
      queryClient.setQueryData(["currentTabIndex"], index);
    },
  });

  const updateBetTicketInfoPayload = useMutation(
    mutation.updateBetTicketInfoPayload,
    {
      onSuccess: (payload) => {
        queryClient.setQueryData(["betTicketInfoPayload"], payload);
      },
    }
  );

  useEffect(() => {
    updateBetTicketInfoPayload.mutate([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const betTypeQuery = query.betType();
  const betType = useQuery(betTypeQuery.key, betTypeQuery.queryFn, {
    refetchOnMount: false,
    keepPreviousData: true,
  });

  let sportFeedQuery;

  switch (sport) {
    case "badminton": {
      sportFeedQuery = query.badmintonFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "americanfootball": {
      sportFeedQuery = query.americanfootballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "rugby": {
      sportFeedQuery = query.rugbyFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "baseball": {
      sportFeedQuery = query.baseballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "snooker": {
      sportFeedQuery = query.snookerFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "tennis": {
      sportFeedQuery = query.tennisFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "icehockey": {
      sportFeedQuery = query.icehockeyFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "handball": {
      sportFeedQuery = query.handballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "tabletennis": {
      sportFeedQuery = query.tabletennisFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "basketball": {
      sportFeedQuery = query.basketballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "volleyball": {
      sportFeedQuery = query.volleyballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    default:
      sportFeedQuery = query.footballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
  }

  const sportFeed = useQuery(sportFeedQuery.key, sportFeedQuery.queryFn);

  const oddsTypeQuery = query.oddsType();
  const oddsType = useQuery(oddsTypeQuery.key, oddsTypeQuery.queryFn, {
    refetchOnMount: false,
    keepPreviousData: true,
  });

  const filteredLeagues = useMemo(
    () => leagueFilterModal.filterLeagues(sportFeed.data),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      sportFeed.data,
      leagueFilterModal.isAllSelected,
      leagueFilterModal.selectedLeagues,
    ]
  );

  const formattedFeeds = useMemo(
    () =>
      formatFeeds(
        filteredLeagues || [],
        sport,
        type as "today" | "live" | "early",
        i18n.language
      ),
    [filteredLeagues, sport, type, i18n.language]
  );

  const options = useMemo(
    () => leagueFilterModal.formatOptions(sportFeed.data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sportFeed.data]
  );

  const length = sportFeed.data?.length ?? 0;
  const selectedLeaguesNo = leagueFilterModal.getSelectedLeaguesNo(length);

  const onClickOddPrice = useCallback(
    async (payload: BetTicketInfoPayload & BetTicketInfoForDisplay) => {
      await updateCurrentTabIndex.mutateAsync(0);

      if (betType?.data === "normal") {
        await updateBetTicketInfoPayload.mutate([payload]);
        sportBarService.collapse();
      } else {
        const betTicketInfoPayloads =
          queryClient.getQueryData<
            (BetTicketInfoPayload & BetTicketInfoForDisplay)[]
          >(["betTicketInfoPayload"]) ?? [];
        const index = betTicketInfoPayloads.findIndex(
          (betTicketInfoPayload) => {
            return betTicketInfoPayload.matchId === payload.matchId;
          }
        );

        if (index >= 0) {
          const newBetTicketInfoPayload = produce(
            betTicketInfoPayloads,
            (draft) => {
              draft[index] = payload;
            }
          );
          await updateBetTicketInfoPayload.mutate(newBetTicketInfoPayload);
          sportBarService.collapse();
        } else {
          await updateBetTicketInfoPayload.mutate([
            ...betTicketInfoPayloads,
            payload,
          ]);
          sportBarService.collapse();
        }
      }
    },

    [
      betType?.data,
      queryClient,
      updateBetTicketInfoPayload,
      updateCurrentTabIndex,
    ]
  );

  const sportsWithMl = [
    "volleyball",
    "tabletennis",
    "handball",
    "icehockey",
    "tennis",
    "snooker",
    "badminton",
    "americanfootball",
  ];
  const isColumnsWithMl = sportsWithMl.includes(sport);

  const sportFullTimeOnlyList = [
    "snooker",
    "tennis",
    "handball",
    "badminton",
    "tabletennis",
    "icehockey",
  ];
  const isSportFullTimeOnly = sportFullTimeOnlyList.includes(sport);

  const column = isSportFullTimeOnly
    ? isColumnsWithMl
      ? COLUMNS_FULLTIME_ONLY_WITH_ML
      : COLUMNS_FULLTIME_ONLY
    : isColumnsWithMl
    ? COLUMNS_WITH_ML
    : COLUMNS;

  return (
    <>
      <div className="w-full mx-auto">
        <div className="flex mb-4 items-center flex-wrap xl:flex-nowrap">
          <>
            <BreadCrumb path={title} />
            <BetTypeSelector
              isPalayDisabled={SPORTS_WITHOUT_PALAY.includes(sport)}
              isComboDisabled={SPORTS_WITHOUT_COMBO.includes(sport)}
            />
            <StyledButton className="mr-3">
              <Refresh
                key={type}
                onClick={() => {
                  sportFeed.refetch();
                }}
                isLoading={sportFeed.isLoading}
                initialCounter={type === "live" ? 20 : 60}
              />
            </StyledButton>
            <LeagueFilterButton
              isDisabled={sportFeed.isLoading}
              onClick={leagueFilterModal.toggle}
              label={t("Leagues") as string}
              no={selectedLeaguesNo}
              max={length}
            />
          </>
        </div>

        <StyledTable isLive={type === "live"}>
          <Table<FormattedFeed>
            key={type}
            columns={column}
            data={formattedFeeds}
            isLoading={sportFeed.isLoading}
            oddsType={oddsType.data}
            betType={betType.data}
            onClickOddPrice={onClickOddPrice}
            renderSubComponent={renderSubComponent}
            // specialPriceComponent={SpecialPriceComponent}
          />
        </StyledTable>
      </div>

      <LeagueFilterModal
        defaultIsAllSelected={leagueFilterModal.isAllSelected}
        defaultSelectedLeagues={leagueFilterModal.selectedLeagues}
        isToggled={leagueFilterModal.isToggled}
        onClose={leagueFilterModal.toggle}
        onSubmit={leagueFilterModal.onSubmit}
        options={options}
      />
    </>
  );
};

export default SportTable;
