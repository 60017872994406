import { ColumnDef } from "@tanstack/react-table";

export const COLUMNS: ColumnDef<any>[] = [
  {
    header: "No.",
    accessorKey: "no",
  },
  {
    header: "Date",
    accessorKey: "date",
    cell: (row) => {
      const date = row.getValue() as string;

      return (
        <div
          style={{minWidth:100}}
          className="cursor-pointer text-blue-700 hover:underline"
          onClick={() => (row as any)?.onClickDate(date)}
        >
          {date}
        </div>
      );
    },
  },
  {
    header: "Bet amount",
    accessorKey: "betAmount",
  },
  {
    header: "Win / Lose",
    accessorKey: "winLose",
  },
  {
    header: "Total",
    accessorKey: "total",
  },
];
