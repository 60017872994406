import { FC,ReactNode } from "react";
import classNames from "classnames";

export type ComboTabsProps = {
  data: {
    label: ReactNode;
    onClick?: (index: number) => void;
    component: JSX.Element;
  }[];
  currentIndex?: number;
};

const ComboTabs: FC<ComboTabsProps> = ({ data, currentIndex = 0 }) => {
  return (
    <>
      <div className="flex bg-white desktop:mx-5 desktop:mb-5 justify-center">
        {data.map(({ label, onClick }, index) => (
          <button
            className={classNames("mobile:p-2  tablet:p-3 font-semibold cursor-pointer mobile:text-sm tablet:text-lg", {
              active: index === currentIndex,
            })}
            style={{
              fontWeight: index === currentIndex ? 600 : 500,
              borderBottom: index === currentIndex ? "2px solid #115e3f" : "none",
            }}
            key={index}
            onClick={() => {
              onClick?.(index);
            }}
          >
            {label}
          </button>
        ))}
      </div>
      <div>{data[currentIndex].component}</div>
    </>
  );
};

export default ComboTabs;
