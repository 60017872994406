import { FC } from "react";
import Collapse from "@mui/material/Collapse";
import { MapHeaderValue } from "./format";
import MarketButton from "./MarketButton";
import useBetInfo from "../../../../hooks/useBetInfo";
import {
  BetPriceType,
  BetType,
  Option,
  TabelType,
} from "../../../../types/common";
import { isGoodTeam } from "../../../../utils/isGoodTeam";
import query from "../../../../query";
import { useQuery } from "@tanstack/react-query";

const noDisplayPlus = ["ou", "ou1st", "ato", "hto"];

export type MarketProps = {
  data: any[];
  header: MapHeaderValue;
  isCollapsed?: boolean;
  marketType: string;
  leagueId: string;
  matchId: string;
  // option: number;
  // betType,
  sportType: string;
  tableType: string;
  betType: string;
  homeName: string;
  awayName: string;
  // isHomeGood: boolean;
  // isAwayGood: boolean;
  leagueName: string;
  // riskLevel: number;
  faid?: number | string;
  fhid?: number | string;
};

const Market: FC<MarketProps> = ({
  data = [],
  header,
  isCollapsed = true,
  leagueId,
  matchId,
  marketType,
  sportType,
  tableType,
  betType,
  homeName,
  awayName,
  leagueName,
  faid,
  fhid,
}) => {
  const betInfo = useBetInfo();
  const betTicketInfoPayloadQuery = query.betTicketInfoPayload();
  const betTicketInfoPayload = useQuery(
    betTicketInfoPayloadQuery.key,
    betTicketInfoPayloadQuery.queryFn,
    { refetchOnMount: false, keepPreviousData: true }
  );

  return (
    <Collapse in={isCollapsed}>
      {!!header.subHeader.length && (
        <div key={header.title} className="flex">
          {header.subHeader.map((subHeader: string) => {
            return (
              <div
                key={subHeader}
                className="pt-1 flex-1 text-center font-bold text-sm px-2 truncate"
              >
                {subHeader}
              </div>
            );
          })}
        </div>
      )}
      <div className="flex flex-col gap-2 p-2">
        {data.map((row: any, i: number) => {
          return (
            <div key={i} className="flex gap-2">
              {row.map((cell: any) => {
                const key = [cell?.label, cell?.hdp, cell?.price].join("-");

                return (
                  <MarketButton
                    key={key}
                    data={cell}
                    isDisplayPlus={!noDisplayPlus.includes(marketType)}
                    isActive={betTicketInfoPayload?.data?.some(
                      (ticket: any) =>
                        ticket.matchId === `${matchId}` &&
                        ticket.betType === marketType &&
                        ticket.riskLevel === cell.riskLevel &&
                        ticket.option === cell.option
                    )}
                    onClick={() => {
                      betInfo.pushToBetInfoPayload({
                        leagueId,
                        matchId,
                        option: cell.option as Option,
                        betType: marketType as BetPriceType,
                        sportType: sportType as
                          | "FOOTBALL"
                          | "BASKETBALL"
                          | "VOLLEYBALL",
                        table: tableType as TabelType,
                        type: (betType || "normal") as BetType,
                        home: homeName,
                        away: awayName,
                        isHomeGood: isGoodTeam(cell?.hdp),
                        isAwayGood: isGoodTeam(cell?.hdp),
                        league: leagueName,
                        riskLevel: cell.riskLevel,
                        faid: `${faid}`,
                        fhid: `${fhid}`,
                        outcomeId: cell.outcomeId,
                        marketId: cell.marketId,
                        specifiers: cell.sp,
                      });
                    }}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </Collapse>
  );
};

export default Market;
