import styled from "styled-components";

export const StyledTab = styled.button<{ isActive?: boolean }>`
  flex: 1;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.global.Primary : theme.global.PrimaryHard};
  color: ${({ theme }) => theme.global.textColorInPrimary};
  padding: 10px;
  position: relative;
`;

export const TabWrapper = styled.div`
  display: flex;
  & button:first-child {
    border-top-left-radius: 10px;
    border-right: 1px solid ${({ theme }) => theme.global.textColorInPrimary};
  }

  & button:last-child {
    border-top-right-radius: 10px;
  }
`;

export const Content = styled.div.attrs({ className: "flex flex-col" })`
  background-color: white;
  border-radius: 0 0 10px 10px;
  min-height: 100px;
`;
