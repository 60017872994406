import axios from "axios";
import { CONFIG } from "../../config";

export type AnnouncementFilter = {
  page: number;
  limit: number;
  startDate: string; // "DD-MM-YYYY",
  endDate: string; // "DD-MM-YYYY",
};

class AnnouncementService {
  public get = async (filter: AnnouncementFilter) => {
    const result = await axios.post(
      `${CONFIG.API_URL}/c/announcement/cAnnouncement`,
      filter
    );
   
    return result?.data?.data;
  };
}

export default AnnouncementService;
