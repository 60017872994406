import styled from "styled-components";

export const Header = styled.div.attrs({
  className:
    "text-left text-xs text-white p-1 flex justify-between items-center",
})<{ tableType?: "live" | "today" | "parlay" }>`
  background: ${({ theme, tableType }) =>
    tableType === "live"
      ? theme.sportLive.HeaderBackground
      : tableType === "today"
      ? theme.sportNonLive.HeaderBackground
      : `linear-gradient(
        127deg,
        ${theme.sportNonLive.HeaderBackground} 0%,
        ${theme.sportNonLive.HeaderBackground} 50%,
        ${theme.sportLive.HeaderBackground} 50%,
        ${theme.sportLive.HeaderBackground} 100%
      )`};
`;

export const Status = styled.div.attrs({
  className: "font-bold text-xs",
})<{ type?: string }>`
  color: ${({ type }) =>
    type === "waiting"
      ? "#b63119"
      : type === "running"
      ? "#198754"
      : type === "reject"
      ? "#dc3545"
      : "black"};
}
`;

export const Total = styled.div.attrs({
  className: "flex justify-between px-2 py-1 font-bold text-sm",
})`
  border-top: 1px dashed #C6BFBE
  ;
}
`;
