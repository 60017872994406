import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import "react-toastify/ReactToastify.min.css";

import { THEME } from "./constants/theme";
import AnnouncementBar from "./contrainers/AnnouncementBar";
import TopNav from "./contrainers/TopNav";
import SideNav from "./contrainers/SideNav";
import StatusBar from "./contrainers/StatusBar";
import ProfileProvider from "./contrainers/ProfileProvider";
import SportsBarMobile from "./contrainers/SportsBar/SportsBarMobile";
import FooterMenu from "./contrainers/FooterMenu";
import { Background, TopNavWrapper, FooterMenuWrapper } from "./styled";
import useDevice from "./hooks/useDevice";
import { Device } from "./types/device";
import { Router } from "./Router";
import "./App.css";
import "./i18n/index.ts";
import RightBar from "./contrainers/RightBar/index.tsx";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const device = useDevice();

  const isDesktop = device === Device.DESKTOP;

  return (
    <div className="App">
      <ToastContainer />
      <Helmet>
        <meta name="theme-color" content={THEME.global.Secondary} />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ProfileProvider>
          <ThemeProvider theme={THEME}>
            <Background>
              <TopNavWrapper>
                <TopNav />
                {!isDesktop && <SportsBarMobile />}
                <AnnouncementBar />
                {isDesktop && <StatusBar />}
              </TopNavWrapper>
              {isDesktop && <SideNav />}
              <div className="relative pb-16 overflow-x-auto desktop:pl-60 desktop:pr-60 desktop:pt-40 mobile:pt-36 min-h-screen">
                <Router />
              </div>
              {isDesktop && <RightBar />}
              {!isDesktop && (
                <FooterMenuWrapper>
                  <FooterMenu />
                </FooterMenuWrapper>
              )}
            </Background>
          </ThemeProvider>
        </ProfileProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
