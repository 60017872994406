import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { FC, HTMLAttributes } from "react";
import mutation from "../../mutation";
import query from "../../query";
import Badge from "../Badge";
import { StyledTab, TabWrapper, Content } from "./styled";

export type Tab = {
  name: string;
  component: JSX.Element;
  onClick?: (index:number) => void;
  badgeNo?: number;
};

export type TabsProps = {
  data: Tab[];
  currentTabIndex: number;

};

const Tabs: FC<TabsProps & HTMLAttributes<HTMLDivElement>> = ({
  data,
  className = "",
}) => {
  const queryClient = useQueryClient();
  const currentTabIndex = query.currentTabIndex();
  const { data: currentTabIndexData } = useQuery(
    currentTabIndex.key,
    currentTabIndex.queryFn,
    { refetchOnMount: false, keepPreviousData: true, initialData: 0 }
  );

  const updateCurrentTabIndex = useMutation(mutation.updateCurrentTabIndex, {
    onSuccess: (index) => {
      queryClient.setQueryData(["currentTabIndex"], index);
    },
  });

  return (
    <div className={className}>
      <TabWrapper>
        {data.map(({ name, onClick = () => {}, badgeNo }, index) => (
          <StyledTab
            key={name}
            isActive={index === currentTabIndexData}
            onClick={() => {
              updateCurrentTabIndex.mutate(index);
              onClick(index);
            }}
          >
            {name} {Boolean(badgeNo) && <Badge>{badgeNo}</Badge>}
          </StyledTab>
        ))}
      </TabWrapper>
      <Content>{data[currentTabIndexData].component}</Content>
    </div>
  );
};

export default Tabs;
