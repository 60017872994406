import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.global.Primary};
  color: ${(props) => props.theme.global.textColorInPrimary};
  height: 60px;
  display: flex;
`;

export const Line = styled.div`
  border-left: 1px solid ${(props) => props.theme.global.textColorInPrimary};
`;
