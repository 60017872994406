import { ColumnDef } from "@tanstack/react-table";

export const COLUMNS: ColumnDef<Record<string, string>>[] = [
  {
    header: "Date & Time",
    accessorKey: "date",
  },
  {
    header: "Competition",
    accessorKey: "match",
  },
  {
    header: "Half-time",
    accessorKey: "halfTime",
  },
  {
    header: "Full-time",
    accessorKey: "fullTime",
  },
];
