import axios from "axios";
import { CONFIG } from "../../config";

export const login = async (uuid: string) => {
  const result = await axios.post(`${CONFIG.API_URL}/c/m/getTokenByUuid`, {
    uuid,
  });

  return result?.data?.data?.token;
};
