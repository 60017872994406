import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestHeaders } from "axios";
import qs from "qs";
import { PropsWithChildren } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Loading from "../../components/Loading";
import query from "../../query";

const ProfileProvider = ({ children }: PropsWithChildren<{}>) => {
  const profile = query.profile();
  const { search } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_searchParams, setSearchParams] = useSearchParams();
  let token;
  try {
    token = window?.localStorage.getItem("auth");
  } catch (error) {
    console.error('Cannot access localStorage:', error);
  }
  
  const hasToken = Boolean(token && token !== "undefined");
  const { uuid } = qs.parse(search, { ignoreQueryPrefix: true });
  const hasUuid = Boolean(uuid);
  const login = query.login(uuid as string);

  const { isLoading: isLoginLoading } = useQuery(login.key, login.queryFn, {
    enabled: hasUuid,
    onSuccess: (token: string) => {
      if (token) {
        window?.localStorage.setItem("auth", token);
        setSearchParams("");

        axios.interceptors.request.use((config) => {
          if (token && token !== "undefined") {
            (config.headers as AxiosRequestHeaders).Authorization = token;
          }

          return config;
        });
      }
    },
    onError: (_error) => {
      window?.localStorage.removeItem("auth");
    },
  });

  const { data: profileData, isLoading: isProfileLoading } = useQuery(
    profile.key,
    profile.queryFn,
    {
      enabled: hasToken,
      onError: () => {
        window?.localStorage.removeItem("auth");
      },
    }
  );

  if ((isProfileLoading && hasToken) || (isLoginLoading && hasUuid)) {
    return <Loading className="m-auto" />;
  }

  if (!profileData) {
    return <div className="m-auto">Unauthorized</div>;
  }

  return <>{children}</>;
};

export default ProfileProvider;
