import { Wrapper, SportsBarWrapper } from "./styled";
import SportsBarDesktop from "../SportsBar/SportsBarDesktop";

const SideNav = () => {
  return (
    <Wrapper>
      <SportsBarWrapper>
        <SportsBarDesktop />
      </SportsBarWrapper>
    </Wrapper>
  );
};

export default SideNav;
