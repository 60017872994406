import { StyledTable } from "./styled";
import Table from "../../components/Table";
import { COLUMNS } from "./config";
import { FC, memo, NamedExoticComponent } from "react";
import Pagination from "../../components/Pagination";
import classNames from "classnames";

export type FooterProps = {
  betAmount: number;
  realbetAmount: number;
  tickets: number;
  winLose: number;
};

export const Footer: NamedExoticComponent<FooterProps> = memo(
  ({
    betAmount,
    // realbetAmount,
    winLose,
  }) => {
    return (
      <tr className="font-bold">
        <td colSpan={2}>Total</td>
        <td></td>
        {/* <td className="text-right">
          {realbetAmount &&
            Number(realbetAmount.toFixed(2)).toLocaleString("en-US")}
        </td> */}
        <td
          className={classNames("text-right", {
            "text-red-500": betAmount < 0,
          })}
        >
          {betAmount && Number(betAmount.toFixed(2)).toLocaleString("en-US")}
        </td>
        <td></td>
        <td
          className={classNames("text-right", { "text-red-500": winLose < 0 })}
        >
          {winLose && Number(winLose.toFixed(2)).toLocaleString("en-US")}
        </td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  }
);

const DisPlay: FC<{
  isLoading?: boolean;
  onClickPagination?: (page: number) => void;
  limit?: number;
  page?: number;
  tickets?: any;
}> = ({
  isLoading = false,
  onClickPagination = () => {},
  limit = 50,
  page = 1,
  tickets,
}) => {
  return (
    <StyledTable>
      <Table<any>
        isLoading={isLoading}
        data={tickets?.data || []}
        columns={COLUMNS}
        Footer={
          <Footer
            betAmount={tickets?.total?.betAmt || 0}
            realbetAmount={tickets?.total?.realBetAmt || 0}
            tickets={tickets?.total?.count || 0}
            winLose={tickets?.total?.winLose || 0}
          />
        }
        page={page}
        limit={limit}
        placeholder="Your tickets have not been found."
      />
      <Pagination
        page={page}
        limit={limit}
        total={tickets?.total?.count}
        onClick={onClickPagination}
      />
    </StyledTable>
  );
};

export default memo(DisPlay);
