import styled from "styled-components";

export const Wrapper = styled.div.attrs({
  className: "text-center",
})<{ isLive?: boolean }>`
  padding: 14px;
  background-color: ${({ theme, isLive }) =>
    isLive
      ? theme.sportLive.TableBackground
      : theme.sportNonLive.TableBackground};
`;

export const TeamMatchingWrapper = styled.div.attrs({
  className: "bg-white rounded-md p-2 mb-2 text-center",
})<{ isLive?: boolean }>`
  border: 1px solid
    ${({ theme, isLive }) =>
      isLive ? theme.sportLive.Border : theme.sportNonLive.Border};
`;
