import { FC } from "react";

import Cricket from "../Cricket/index";

export type CricketLiveAndTodayProps = {};

const CricketLiveAndToday: FC<CricketLiveAndTodayProps> = () => {
  return (
    <>
      <Cricket type="live" />
      <Cricket type="today" />
    </>
  );
};

export default CricketLiveAndToday;
