import React, { FC } from "react";
import { IconButton, ButtonGroup } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

export type PaginationProps = {
  total: number;
  page: number;
  limit: number;
  onClick: (page: number) => void;
};

const Pagination: FC<PaginationProps> = ({
  total = 0,
  page,
  limit,
  onClick,
}) => {
  const getItemProps = (index: number) => ({
    className: page === index ? "bg-black text-white font-bold" : "",
    onClick: () => {
      onClick(index);
    },
  });

  const next = () => {
    if (page === 5) return;

    onClick(page + 1);
  };

  const prev = () => {
    if (page === 1) return;

    onClick(page - 1);
  };

  return (
    <div className="p-2">
      <div className="flex justify-center">
        {(page - 1) * limit + 1} - {(page - 1) * limit + limit} of {total}
      </div>
      <ButtonGroup variant="outlined" className="justify-center">
        <IconButton disabled={page === 1} onClick={prev}>
          <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
        </IconButton>
        {Array.from(Array(Math.ceil((total ?? 0) / limit))?.keys())?.map(
          (_each, index) => {
            return (
              <IconButton key={index + 1} {...getItemProps(index + 1)}>
                {index + 1}
              </IconButton>
            );
          }
        )}

        <IconButton disabled={page * limit > total} onClick={next}>
          <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
        </IconButton>
      </ButtonGroup>
    </div>
  );
};

export default Pagination;
