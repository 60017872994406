import InputUnstyled, { InputUnstyledProps } from "@mui/base/InputUnstyled";
import { forwardRef } from "react";

const Input = forwardRef(
  (props: InputUnstyledProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    return (
      <InputUnstyled
        slotProps={{
          input: { className: "border border-gray-300 rounded-md px-3 py-2 w-full" },
        }}
        {...props}
        ref={ref}
      />
    );
  }
);

export default Input;
