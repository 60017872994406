import { FC } from "react";

export type SportNameProps = {
  iconSrc: string;
  name: string;
};

const SportName: FC<SportNameProps> = ({ iconSrc, name }) => (
  <div className="flex mobile:items-center mobile:flex-col desktop:flex-row">
    <img className="mobile:w-6 desktop:w-5 desktop:mr-2" src={iconSrc} alt={name} />
    <b className="whitespace-nowrap">{name}</b>
  </div>
);

export default SportName;
