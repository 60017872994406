import { FC } from "react";
import { Button, ButtonType } from "../Button";

export type LeagueFilterButtonProps = {
  isDisabled?: boolean;
  onClick?: () => void;
  label?: string;
  no?: number;
  max?: number;
};

const LeagueFilterButton: FC<LeagueFilterButtonProps> = ({
  isDisabled,
  onClick,
  label,
  no,
  max,
}) => {
  return (
    <Button
      className="px-2"
      disabled={isDisabled}
      style={{ minWidth: 180 }}
      buttonType={ButtonType.SECONDARY}
      onClick={onClick}
    >
      <img
        src="/svg/trophy.svg"
        width={18}
        className="inline align-sub mr-1"
        alt="leagues"
      />
      {label} ({no}/{max})
    </Button>
  );
};

export default LeagueFilterButton;
