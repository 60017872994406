import { CellContext } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { FormattedFeed, OverUnder } from "../../../types/feed";
import { Handicap, OddPrice } from "../../../components/Typography";
import { CustomCellContext } from "../../../types/table";
import { isGoodTeam } from "../../../utils/isGoodTeam";
import { convertOdds } from "../../../utils/convertOdds";
import { convertHDP } from "../../../utils/convertHDP";
import useOddChangeDetector from "../../../hooks/useOddChangeDetector";

const OU = (row: CellContext<FormattedFeed, unknown> & CustomCellContext) => {
  const { i18n } = useTranslation();
  const overUnder = row.getValue<OverUnder>();
  const id = row.column.id;
  const [leagueId, matchId] = row.row.original.match_id.split(":");
  const { match_n: matchName, tableType, sport } = row.row.original;
  const { homePriceChangedType, awayPriceChangedType } = useOddChangeDetector({
    homeHandicap: overUnder?.o || "",
    awayHandicap: overUnder?.u || "",
    homePrice: overUnder?.op || "0",
    awayPrice: overUnder?.up || "0",
  });

  if (!overUnder) {
    return "";
  }
  const over = convertOdds(Number(overUnder.op), row?.oddsType);
  const under = convertOdds(Number(overUnder.up), row?.oddsType);
  const betType = id === "FULL_TIME_OU" ? "ou" : "ou1st";
  const betTypeKey = betType === "ou" ? "betPrice_ou" : "betPrice_ou1st";
  return (
    <>
      <div className="flex mr-2">
        <Handicap className="flex-2 text-right">
          {convertHDP(overUnder?.o)}
        </Handicap>
        <div className="flex-1 font-bold text-xs text-right mr-2 flex justify-end items-center">
          o
        </div>
        <div className="flex-2 text-right">
          <OddPrice
            className="relative"
            isRedOdd={over.includes("-")}
            isIncrease={homePriceChangedType === "increase"}
            isDecrease={homePriceChangedType === "decrease"}
            onClick={() =>
              row?.onClickOddPrice?.({
                leagueId,
                matchId,
                option: 0,
                betType,
                sportType: sport.toUpperCase() as "FOOTBALL" | "BASKETBALL",
                table: tableType,
                type: row?.betType || "normal",
                home: matchName[i18n.language].h,
                away: matchName[i18n.language].a,
                isHomeGood: isGoodTeam(row.row.original?.betPrice_ah?.h),
                isAwayGood: isGoodTeam(row.row.original?.betPrice_ah?.a),
                league: row.row.original?.feed_nn,
                riskLevel: row.row.original?.betPrice_order,
                fhid: `${matchName.fhid}`,
                faid: `${matchName.faid}`,
                outcomeId: row.row.original?.[betTypeKey]?.oco,
                specifiers: row.row.original?.[betTypeKey]?.sp,
                marketId: row.row.original?.[betTypeKey]?.mkid,
              })
            }
          >
            {over}
          </OddPrice>
        </div>
      </div>
      <div className="flex mr-2">
        <Handicap className="flex-2 text-right" />
        <div className="flex-1 font-bold text-xs text-right mr-2 flex justify-end items-center">
          u
        </div>
        <div className="flex-2 text-right">
          <OddPrice
            className="relative"
            isRedOdd={under.includes("-")}
            isIncrease={awayPriceChangedType === "increase"}
            isDecrease={awayPriceChangedType === "decrease"}
            onClick={() =>
              row?.onClickOddPrice?.({
                leagueId,
                matchId,
                option: 1,
                betType,
                sportType: sport.toUpperCase() as "FOOTBALL" | "BASKETBALL",
                table: tableType,
                type: row?.betType || "normal",
                home: matchName[i18n.language].h,
                away: matchName[i18n.language].a,
                isHomeGood: isGoodTeam(row.row.original?.betPrice_ah?.h),
                isAwayGood: isGoodTeam(row.row.original?.betPrice_ah?.a),
                league: row.row.original?.feed_nn,
                riskLevel: row.row.original?.betPrice_order,
                fhid: `${matchName.fhid}`,
                faid: `${matchName.faid}`,
                outcomeId: row.row.original?.[betTypeKey]?.ocu,
                specifiers: row.row.original?.[betTypeKey]?.sp,
                marketId: row.row.original?.[betTypeKey]?.mkid,
              })
            }
          >
            {under}
          </OddPrice>
        </div>
      </div>
    </>
  );
};

export default OU;
