import { FC } from "react";
import classNames from "classnames";
import { OddPrice } from "../../../components/Typography";

export type ButtonProps = {
  label?: string;
  hdp?: string;
  price: string;
  priceChangedType?: "increase" | "decrease";
  onClick?: () => void;
  isActive?: boolean;
  className?: string;
  isDisabled?: boolean;
};

const Button: FC<ButtonProps> = ({
  label,
  hdp,
  price,
  priceChangedType,
  onClick = () => {},
  isActive = false,
  isDisabled = false,
  className,
}) => {
  return (
    <button
      disabled={isDisabled}
      onClick={onClick}
      style={{ borderColor: "inherit" }}
      className={classNames("flex flex-col", className, {
        "bg-yellow-300": isActive,
      })}
    >
      {!!label && (
        <small
          className="flex-1 w-full pt-1 flex justify-center items-center"
          style={{
            color: "rgb(47, 128, 237)",
            fontSize: 11
          }}
        >
          {label}
        </small>
      )}

      {!!hdp && (
        <small
          className="flex-1 w-full pt-1 flex justify-center items-center"
          style={{
            color: "rgb(47, 128, 237)",
            fontSize: 11
          }}
        >
          {hdp}
        </small>
      )}

      <OddPrice
        isIncrease={priceChangedType === "increase"}
        isDecrease={priceChangedType === "decrease"}
        className="text-sm relative flex-1 flex w-full justify-center items-center"
        isRedOdd={price?.includes("-")}
        offset={3}
      >
        {!!price ? price : "-"}
      </OddPrice>
    </button>
  );
};

export default Button;
