import { FC } from "react";
import LockIcon from "@mui/icons-material/Lock";

import { OddPrice } from "../../components/Typography";
import { useChangeDetector } from "../../hooks/useOddChangeDetector/useChangeDetector";
import { ML } from "../../types/cricketFeed";
import {
  BetTicketInfoForDisplay,
  BetTicketInfoPayload,
} from "../../types/betTicket";

export type MoneyLineProps = {
  isLive: boolean;
  matchId: string;
  leagueId: string;
  leagueName: string;
  onClickOddPrice?: (
    payload: BetTicketInfoPayload & BetTicketInfoForDisplay
  ) => void;
} & ML;

const MoneyLine: FC<MoneyLineProps> = ({
  isLive,
  homeName,
  homeOdds,
  awayName,
  awayOdds,
  matchId,
  leagueId,
  marketId,
  isActive = true,
  onClickOddPrice,
  leagueName,
  homeId,
  awayId,
}) => {
  const { priceChangedType: homeChangedType } = useChangeDetector(homeOdds);
  const { priceChangedType: awayChangedType } = useChangeDetector(awayOdds);

  if (!isActive) {
    return (
      <>
        <div className="bg-white rounded flex  justify-center items-center py-0.5 pl-2 pr-4 border border-slate-200">
          <LockIcon className="text-gray-500" />
        </div>
        <div className="bg-white rounded flex justify-center items-center py-0.5 pl-2 pr-4 border border-slate-200">
          <LockIcon className="text-gray-500" />
        </div>
      </>
    );
  }

  return (
    <>
      <div
        onClick={() => {
          onClickOddPrice?.({
            type: "normal",
            table: isLive ? "live" : "today",
            sportType: "CRICKET",
            betType: "ml",
            matchId,
            leagueId,
            marketId,
            outcomeId: homeId,
            home: homeName,
            away: awayName,
            league: leagueName,
            isAwayGood: false,
            isHomeGood: false,
            option: 0,
          });
        }}
        className="bg-white cursor-pointer rounded flex items-center justify-between py-0.5 pl-2 pr-4 border border-slate-200"
      >
        <span className="truncate flex-2">{homeName}</span>
        <OddPrice
          className="relative"
          isIncrease={homeChangedType === "increase"}
          isDecrease={homeChangedType === "decrease"}
        >
          {homeOdds}
        </OddPrice>
      </div>
      <div
        onClick={() => {
          onClickOddPrice?.({
            type: "normal",
            table: isLive ? "live" : "today",
            sportType: "CRICKET",
            betType: "ml",
            matchId,
            leagueId,
            marketId,
            outcomeId: awayId,
            home: homeName,
            away: awayName,
            league: leagueName,
            isAwayGood: false,
            isHomeGood: false,
            option: 1,
          });
        }}
        className="bg-white cursor-pointer rounded flex items-center justify-between py-0.5 pl-2 pr-4 border border-slate-200"
      >
        <span className="truncate flex-2">{awayName}</span>
        <OddPrice
          className="relative"
          isIncrease={awayChangedType === "increase"}
          isDecrease={awayChangedType === "decrease"}
        >
          {awayOdds}
        </OddPrice>
      </div>
    </>
  );
};

export default MoneyLine;
