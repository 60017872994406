import { Row } from "@tanstack/react-table";
import { FormattedFeed } from "../../types/feed";

export const renderSubComponent = ({ row }: { row: Row<FormattedFeed> }) => {
  if (row.original.feed_order !== 0 || row.original.betPrice_order !== 0)
    return <></>;
  return (
    <tr>
      <th colSpan={10} className="pl-6 py-1 text-left league">
        <b>{row.original.feed_nn}</b>
        <span className="ml-4 text-xs text-gray-600 rounded bg-white px-1">{row.original.feed_ms}</span>
      </th>
    </tr>
  );
};
