export const formatMatch = (data: any[], results: any[]) => {
  return data.map(
    (
      {
        sportType,
        table,
        matchId,
        leagueId,
        riskLevel,
        betType,
        option,
        outcomeId,
        marketId,
        handicap,
        specifiers,
      },
      index
    ) => {
      const betInfoData = results?.[index]?.data as any;
      let ahd = "a";
      switch (option) {
        case 0:
          ahd = "a";
          break;
        case 1:
          ahd = "h";
          break;

        default:
          ahd = "d";
          break;
      }

      return {
        sportType,
        table,
        matchId: `${leagueId}:${matchId}`,
        riskLevel,
        betType,
        option,
        price: `${betInfoData?.price}`,
        score: betInfoData?.score,
        value: betInfoData?.value,
        ahd,
        outcomeId,
        marketId,
        handicap,
        specifiers,
      };
    }
  );
};
