import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { useEffect, Fragment, useCallback, FC } from "react";
import classNames from "classnames";

import SportBarService from "../../services/sportBar";
import query from "../../query";
import mutation from "../../mutation";
import LeagueCard from "./LeagueCard";
import BreadCrumb from "../../components/BreadCrumb";
import Refresh from "../../components/Refresh";
import Loading from "../../components/Loading";
import {
  BetTicketInfoForDisplay,
  BetTicketInfoPayload,
} from "../../types/betTicket";

const sportBarService = new SportBarService();

export type CricketPageProps = {
  type?: "today" | "live";
};

const CricketPage: FC<CricketPageProps> = ({ type = "today" }) => {
  const queryClient = useQueryClient();
  const updateBetType = useMutation(mutation.updateBetType, {
    onSuccess: (value) => {
      queryClient.setQueryData(["betType"], value);
    },
  });

  const betTypeQuery = query.betType();
  const betType = useQuery(betTypeQuery.key, betTypeQuery.queryFn, {
    refetchOnMount: false,
    keepPreviousData: true,
  });

  const updateOddsType = useMutation(mutation.updateOddsType, {
    onSuccess: (value) => {
      queryClient.setQueryData(["oddsType"], value);
    },
  });

  const updateBetTicketInfoPayload = useMutation(
    mutation.updateBetTicketInfoPayload,
    {
      onSuccess: (payload) => {
        queryClient.setQueryData(["betTicketInfoPayload"], payload);
      },
    }
  );

  const updateCurrentTabIndex = useMutation(mutation.updateCurrentTabIndex, {
    onSuccess: (index) => {
      queryClient.setQueryData(["currentTabIndex"], index);
    },
  });

  useEffect(() => {
    updateBetTicketInfoPayload.mutate([]);
    updateBetType.mutate("normal");
    updateOddsType.mutate(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickOddPrice = useCallback(
    async (payload: BetTicketInfoPayload & BetTicketInfoForDisplay) => {
      await updateCurrentTabIndex.mutateAsync(0);

      if (betType?.data === "normal") {
        await updateBetTicketInfoPayload.mutate([payload]);
        sportBarService.collapse();
      }
    },

    [betType?.data, updateBetTicketInfoPayload, updateCurrentTabIndex]
  );

  const cricketFeedQuery = query.cricketFeed(
    type as "today" | "live" | "early"
  );

  const cricketFeed = useQuery(cricketFeedQuery.key, cricketFeedQuery.queryFn);

  return (
    <div className="mb-10 desktop:ml-3 desktop:mr-3">
      <div className="flex justify-between items-center mb-5">
        <BreadCrumb path={`cricket/${type}`} />

        <Refresh
          className="mr-3 border border-solid border-blue-500 text-blue-500 rounded flex justify-center items-center"
          style={{
            width: 120,
            minHeight: 30,
          }}
          key={type}
          onClick={() => {
            cricketFeed.refetch();
          }}
          isLoading={cricketFeed.isLoading}
          initialCounter={type === "live" ? 20 : 60}
        />
      </div>
      {cricketFeed.isLoading && <Loading size={30} className="m-auto" />}
      {!cricketFeed.isLoading &&
        cricketFeed.data?.map((league, index) => {
          return (
            <Fragment key={league.leagueId}>
              <LeagueCard
                isLive={type === "live"}
                leagueId={league.leagueId}
                leagueName={league.leagueName}
                matches={league.matches}
                className={classNames({ "rounded-t-md": index === 0 })}
                onClickOddPrice={onClickOddPrice}
              />
            </Fragment>
          );
        })}
    </div>
  );
};

export default CricketPage;
