import { FC, useState, useCallback } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { dateTypeOptions, defaultDateTypeOption } from "./config";
import useSelectorState from "../../hooks/useSelectorState";
import { DateOptions } from "./types";
import SportFilter from "../../contrainers/SportFilter";
import { useBetTypeFilter } from "../../contrainers/SportFilter/useBetTypeFilter";
import TicketTable from "../../contrainers/TicketTable";
import moment from "moment";

const STATUS = ["running", "waiting"];

const MyBets: FC = () => {
  const { t } = useTranslation();
  const { value: dateType, onChange: onChangeDateType } =
    useSelectorState<DateOptions>(defaultDateTypeOption);
  const { selectedBetTypes, onChange } = useBetTypeFilter();
  const [page, setPage] = useState(1);
  const [limit] = useState(50);
  let startDate = "";
  let endDate = "";
  if (dateType?.value === "TOD") {
    startDate = moment().startOf("day").toISOString();
    endDate = moment().endOf("day").toISOString();
  } else if (dateType?.value === "TMR") {
    startDate = moment().add(1, "days").startOf("day").toISOString();
    endDate = moment().add(1, "days").endOf("day").toISOString();
  }

  const onChangePage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const betType = selectedBetTypes.includes("all")
    ? []
    : (selectedBetTypes as any);

  return (
    <>
      <div className="mobile:pt-3">
        <h3 className="text-2xl font-bold mb-3 px-3">{t("My Bets")}</h3>
        <div className="bg-white desktop:mx-3 rounded-t-lg px-3">
          <div className="p-4">
            <div className="flex items-center space-x-4 mb-4">
              <div>{t("Date time")}:</div>
              <Select
                options={dateTypeOptions}
                value={dateType}
                isSearchable={false}
                onChange={onChangeDateType}
              />
            </div>
            <div className="flex items-center space-x-4">
              <div>{t("Type")} :</div>
              <div>
                <SportFilter onChange={onChange} selected={selectedBetTypes} />
              </div>
            </div>
          </div>
        </div>
        <div className="desktop:mx-3">
          <div className="w-full overflow-x-scroll bg-white ">
            <TicketTable
              betType={betType}
              startDate={startDate}
              endDate={endDate}
              page={page}
              limit={limit}
              onClickPagination={onChangePage}
              status={STATUS}
              
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyBets;
