import { FC, HTMLAttributes } from "react";
import { Button, ButtonType } from "../Button";
import { LeagueFilterButtonProps } from ".";

const LeagueFilterButtonMobile: FC<
  LeagueFilterButtonProps & HTMLAttributes<HTMLButtonElement>
> = ({ isDisabled, onClick, className }) => {
  return (
    <Button
      disabled={isDisabled}
      style={{ width: 38, height: 38, color: "white" }}
      buttonType={ButtonType.PRIMARY}
      onClick={onClick}
      className={className}
    >
      <svg width={15} height={15} viewBox="0 0 35 35" fill="currentColor" className="mx-auto">
        <path d="M5.582 2.678H1.367a1.367 1.367 0 100 2.734h4.215A4.051 4.051 0 009.388 8.09a4.051 4.051 0 003.806-2.678h20.439a1.367 1.367 0 100-2.734H13.194A4.051 4.051 0 009.388 0a4.051 4.051 0 00-3.806 2.678zm5.116 1.367c0 .722-.587 1.31-1.31 1.31-.722 0-1.31-.588-1.31-1.31 0-.723.588-1.31 1.31-1.31.723 0 1.31.587 1.31 1.31zM21.805 16.133H1.367a1.367 1.367 0 100 2.734h20.438a4.051 4.051 0 003.807 2.678 4.052 4.052 0 003.806-2.678h4.215a1.367 1.367 0 100-2.734h-4.215a4.052 4.052 0 00-3.806-2.678 4.051 4.051 0 00-3.806 2.678zm5.117 1.367c0 .723-.588 1.31-1.31 1.31-.723 0-1.31-.587-1.31-1.31 0-.723.587-1.31 1.31-1.31.723 0 1.31.587 1.31 1.31zM5.582 29.588H1.367a1.367 1.367 0 100 2.734h4.215A4.052 4.052 0 009.388 35a4.051 4.051 0 003.806-2.678h20.439a1.367 1.367 0 100-2.734H13.194a4.051 4.051 0 00-3.806-2.678 4.051 4.051 0 00-3.806 2.678zm5.116 1.367c0 .723-.587 1.31-1.31 1.31-.722 0-1.31-.587-1.31-1.31 0-.722.588-1.31 1.31-1.31.723 0 1.31.588 1.31 1.31z"></path>
      </svg>
    </Button>
  );
};

export default LeagueFilterButtonMobile;
