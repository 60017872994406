import { FC, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Collapse from "@mui/material/Collapse";

import { HeaderWrapper } from "./styled";
import MatchTable from "./NewMatchTable";

export type TableMobileProps = {
  league: any;
  type: "live" | "today" | "early";
  sport:
    | "football"
    | "basketball"
    | "volleyball"
    | "tabletennis"
    | "handball"
    | "icehockey"
    | "tennis"
    | "snooker"
    | "baseball"
    | "rugby"
    | "americanfootball"
    | "badminton";
  onClickOddPrice?: (
    payload1: any
  ) => (payload2: any) => (payload2: any) => void;
  onClickMore?: () => void;
};

const TableMobile: FC<TableMobileProps> = ({
  league,
  onClickOddPrice,
  type,
  sport,
  onClickMore,
}) => {
  const { i18n } = useTranslation();
  const [isCollapse, setIsCollapse] = useState(true);
  const isLive = type === "live";

  const handle = onClickOddPrice?.({
    leagueId: `${league.k}`,
    leagueName: league.nn[i18n.language],
  });

  return (
    <>
      <HeaderWrapper
        isLive={isLive}
        onClick={() => {
          setIsCollapse(!isCollapse);
        }}
        className="flex cursor-pointer p-2 mb-2"
      >
        <div className="flex justify-between flex-1 items-center text-sm">
          <div>{league.nn[i18n.language]}</div>
          <div className="px-2">
            <svg
              width="12"
              height="8"
              viewBox="0 0 8 5"
              fill="currentColor"
              className={classNames("transition-transform align-baseline", {
                "rotate-180": !isCollapse,
                "rotate-0": isCollapse,
              })}
            >
              <path d="M7.369 0H.199l3.585 4.2L7.369 0z"></path>
            </svg>
          </div>
        </div>

        {/* <div className="flex">
          <div className="w-16 flex items-center justify-center text-sm">Home</div>
          <div className="w-16 flex items-center justify-center text-sm">Away</div>
        </div> */}
      </HeaderWrapper>
      <Collapse in={isCollapse}>
        {league.m.map((match: any) => (
          <MatchTable
            key={match.k}
            leagueId={`${league.k}`}
            onClickOddPrice={handle}
            onClickMore={onClickMore}
            match={match}
            type={type}
            sport={sport}
          />
        ))}
      </Collapse>
    </>
  );
};

export default TableMobile;
