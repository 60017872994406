import { Sport } from "../../types/sport";
import { DateOptions } from "./types";

export const sportOptions = [
  { label: "ฟุตบอล", value: Sport.FOOTBALL },
  { label: "บาสเก็ตบอล", value: Sport.BASKETBALL },
];

export const dateTypeOptions = [
  { label: "วันนี้", value: DateOptions.TODAY },
  { label: "7 วันที่แล้ว", value: DateOptions.SEVEN_DAYS_AGO },
];

export const defaultSportOption = sportOptions[0];
export const defaultDateTypeOption = dateTypeOptions[0];

export const defaultState = {
  selectedDate: "0",
};
