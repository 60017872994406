const TH = {
  Draw: "Draw",
  "All Announcement": "All Announcement",
  "List of Sports": "List of Sports",
  Football: "Football",
  Basketball: "Basketball",
  "Coming Soon": "Coming Soon",
  Cricket: "Cricket",
  Volleyball: "Volleyball",
  Boxing: "Boxing",
  Combo: "Combo",
  "Table Tennis": "Table Tennis",
  Tennis: "Tennis",
  "All Result": "All Result",
  "My Bet": "My Bet",
  Statement: "Statement",
  "Date time": "Date time",
  Type: "Type",
  "Date & Time": "Date & Time",
  "Live / Today": "Live / Today",
  Live: "Live",
  Today: "Today",
  Early: "Early",
  ALL: "ALL",
  Leagues: "Leagues",
  League: "League",
  Search: "Search",
  "Select / Cancel all": "Select / Cancel all",
  selected: "selected",
  "League is not found": "League is not found",
  Confirm: "Confirm",
  Cancel: "Cancel",
  "No competition": "No competition",
  "No statement on this date range": "No statement on this date range",
  Competition: "Competition",
  "Half-time": "Half-time",
  "Full-time": "Full-time",
  Date: "Date",
  "No.": "No.",
  Message: "Message",
  "Bet amount": "Bet amount",
  "Win / Lose": "Win / Lose",
  Commission: "Commission",
  Total: "Total",
  "Place bet success": "Place bet success",
  "Bet failed": "Bet failed",
  VALIDATION_ERROR: "Validation error",
  RATE_LIMIT_REQUEST: "Reached your rate limit request",
  CANNOT_GET_PROFILE: "Cannot get your profile",
  ODDS_CHANGED: "Odds price has been changed",
  MATCH_NOT_FOUND: "Match not found",
  OVER_LITMIT_PER_MATCH: "Reached your rate limit per match",
  OVER_LITMIT_PER_BILL: "Reached your rate limit per bill",
  OVER_LITMIT_PER_DAY: "Reached your rate limit per day",
  ODDS_INVALID: "Invalid odds",
  DUPLICATE_BET: "Duplicated betting",
  INSUFFICIENT_BALANCE: "Your balance isn't enough",
  LOCKED_ACCOUNT: "Account is locked",
  SUSSPENDED_ACCOUNT: "Account is susspended",
  UNAVAILABLE_BET: "Betting is unavailable",
  TICKET_REJECTED: "Ticket was rejected",
  SYSTEM_ERROR: "system error please contact admin",
  INVALID_CURRENCY: "Invalid currency",
  INVALID_AMOUNT: "Invalid amount",
  SESSION_EXPIRED: "Session was expired",
};

export default TH;
