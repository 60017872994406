import styled from "styled-components";

export const HeaderWrapper = styled.div.attrs({})<{ isLive?: boolean }>`
  background-color: ${({ theme, isLive }) =>
    isLive
      ? theme.sportLive.HeaderBackground
      : theme.sportNonLive.HeaderBackground};
  color: ${({ theme, isLive }) =>
    isLive
      ? theme.sportLive.TitleTextColor
      : theme.sportNonLive.TitleTextColor};
  position: relative;
`;

export const DateWrapper = styled.div.attrs({})<{ isLive?: boolean }>`
  background-color: ${({ theme, isLive }) =>
    isLive
      ? theme.sportLive.HeaderBackground
      : theme.sportNonLive.HeaderBackground};
  color: ${({ theme, isLive }) =>
    isLive
      ? theme.sportLive.TitleTextColor
      : theme.sportNonLive.TitleTextColor};
  position: relative;

  &:before {
    border-top: 35px solid transparent;
    border-left: 26px solid
      ${({ theme, isLive }) =>
        isLive
          ? theme.sportLive.HeaderBackground
          : theme.sportNonLive.HeaderBackground};
    right: -26px;
  }
  &:after {
    width: 5px;
    height: 100%;
    right: -24px;
    transform: skew(0.60turn, 0deg);
    background: ${({ theme, isLive }) =>
      isLive
        ? theme.sportLive.HeaderBackground
        : theme.sportNonLive.HeaderBackground};
  }
  &:before,
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0px;
  }
`;

export const TableWrapper = styled.div`
  background-color: ${({ theme }) => theme.global.BackgroundInside};
`;
