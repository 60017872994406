import _ from "lodash";
import dayjs from "dayjs";
import { Feed, FormattedFeed } from "../../types/feed";

export const formatFeeds = (
  feeds: Feed[],
  sport:
    | "football"
    | "basketball"
    | "volleyball"
    | "tabletennis"
    | "handball"
    | "icehockey"
    | "tennis"
    | "snooker"
    | "baseball"
    | "rugby"
    | "americanfootball"
    | "badminton",
  type: "today" | "live" | "early",
  language: string
): FormattedFeed[] => {
  const betPriceKey = type === "live" ? "bpl" : "bp";
  const result = feeds.map((feed) => {
    return feed.m.map((match, matchIndex) => {
      const betPriceTotol = match?.[betPriceKey]?.length || 0;
      if (betPriceTotol === 0) {
        return {
          sport,
          tableType: type,
          feed_k: feed.k,
          feed_isSpecial: feed.isSpecial,
          feed_ms: feed.ms,
          feed_nn: feed.nn[language],
          feed_order: matchIndex,
          match_isChannel: match.isChannel,
          match_isSpecial: match.isSpecial,
          match_id: `${feed.k}:${match.k}`,
          match_k: match.k,
          match_pvd: match.pvd,
          match_d: match.d,
          match_m_a: match.n.en.a.replaceAll(" ", "-"),
          match_m_h: match.n.en.h.replaceAll(" ", "-"),
          match_YYYY_MM_DD: match.d
            ? dayjs(match.od).format("YYYY-MM-DD")
            : dayjs().format("YYYY-MM-DD"),
          match_hasParlay: match.hasParlay,
          match_n: match.n,
          match_md5: match.md5,
          match_liveTv: match.liveTv,
          match_liveTv2: match.liveTv2,
          match_st: match.st,
          match_betRadarTV: match.betRadarTV,
          match_i: match.i,
          match_special_count: match.msp,
          betPrice_totol: betPriceTotol,
          betPrice_order: 0,
        };
      }

      return (
        match?.[betPriceKey]?.map((betPrice, betPriceIndex) => ({
          sport,
          tableType: type,
          feed_k: feed.k,
          feed_isSpecial: feed.isSpecial,
          feed_ms: feed.ms,
          feed_nn: feed.nn[language],
          feed_order: matchIndex,
          match_isChannel: match.isChannel,
          match_isSpecial: match.isSpecial,
          match_id: `${feed.k}:${match.k}`,
          match_k: match.k,
          match_pvd: match.pvd,
          match_d: match.d,
          match_m_a: match.n.en.a.replaceAll(" ", "-"),
          match_m_h: match.n.en.h.replaceAll(" ", "-"),
          match_YYYY_MM_DD: match.d
            ? dayjs(match.od).format("YYYY-MM-DD")
            : dayjs().format("YYYY-MM-DD"),
          match_hasParlay: match.hasParlay,
          match_n: match.n,
          match_md5: match.md5,
          match_liveTv: match.liveTv,
          match_liveTv2: match.liveTv2,
          match_st: match.st,
          match_betRadarTV: match.betRadarTV,
          match_i: match.i,
          match_special_count: match.msp,
          betPrice_ah: betPrice.ah,
          betPrice_ou: betPrice.ou,
          betPrice_x12: betPrice.x12,
          betPrice_ml: betPrice.ml,
          betPrice_oe: betPrice.oe,
          betPrice_ah1st: betPrice.ah1st,
          betPrice_ou1st: betPrice.ou1st,
          betPrice_x121st: betPrice.x121st,
          betPrice_ml1st: betPrice.ml1st,
          betPrice_order: betPriceIndex,
          betPrice_totol: betPriceTotol,
        })) || []
      );
    });
  });

  return _.flatten(_.flatten(result));
};
