import { ColumnDef } from "@tanstack/react-table";

export const COLUMNS: ColumnDef<Record<string, string>>[] = [
  {
    header: "No.",
    accessorKey: "no",
  },
  {
    header: "Date & Time",
    accessorKey: "date",
  },
  {
    header: "Message",
    accessorKey: "message",
  },
];
