import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import mutation from "../../mutation";
import SportTableLiveAndToday from "../../contrainers/SportTableLiveAndToday";
import useRedirectLiveAndToday from "../../hooks/useRedirectLiveAndToday"

const TabletennisLiveAndTodayPage = () => {
  useRedirectLiveAndToday("tabletennis")
  const queryClient = useQueryClient();

  const updateOddsType = useMutation(mutation.updateOddsType, {
    onSuccess: (value) => {
      queryClient.setQueryData(["oddsType"], value);
    },
  });

  const updateBetTicketInfoPayload = useMutation(
    mutation.updateBetTicketInfoPayload,
    {
      onSuccess: (payload) => {
        queryClient.setQueryData(["betTicketInfoPayload"], payload);
      },
    }
  );

  const updateBetType = useMutation(mutation.updateBetType, {
    onSuccess: (value) => {
      queryClient.setQueryData(["betType"], value);
    },
  });

  useEffect(() => {
    updateBetTicketInfoPayload.mutate([]);
    updateOddsType.mutate(0); // malay odds type
    updateBetType.mutate("normal");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SportTableLiveAndToday
      liveTitle="Tabletennis/Live"
      todayTitle="Tabletennis/Today"
      sport="tabletennis"
    />
  );
};

export default TabletennisLiveAndTodayPage;
