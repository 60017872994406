import { BetType } from "../types/common";
import BetTicket from "../services/betTicket";

import {
  BetTicketInfoPayload,
  BetTicketInfoForDisplay,
  PlaceBetTicketPayload,
} from "../types/betTicket";

const betTicket = new BetTicket();

const mutation = {
  placeBetTicket: (payload: PlaceBetTicketPayload) => {
    return betTicket.placeBetTicket(payload);
  },

  updateBetTicketInfoPayload: async (
    payload: (BetTicketInfoForDisplay & BetTicketInfoPayload)[]
  ) => {
    return payload;
  },

  updateCurrentTabIndex: async (currentIndex: number) => {
    return currentIndex;
  },

  updateOddsType: async (oddsType: number) => {
    return oddsType;
  },

  updateBetType: async (betType: BetType) => {
    return betType;
  },

  updateIsBetSlipToggle: async (isBetSlipToggle: boolean) => {
    return isBetSlipToggle;
  },

  updateIsMenuFooterToggle: async (isMenuFooterToggle: boolean) => {
    return isMenuFooterToggle;
  },

  updateIsLanguageToggle: async (isLanguageToggle: boolean) => {
    return isLanguageToggle;
  },

  undateIsOddsTypeToggle: async (isOddsTypeToggle: boolean) => {
    return isOddsTypeToggle;
  },
};

export default mutation;
