import BetsTabs from "../SideNav/BetsTabs";
import { Wrapper } from "./styled";

export default function RightBar() {
  return (
    <Wrapper>
      <BetsTabs />
    </Wrapper>
  );
}
