import { FC, memo, NamedExoticComponent } from "react";
import { useQuery } from "@tanstack/react-query";
import query from "../../query";
import { BetType } from "../../types/common";
import Display from "./Display";

export type FooterProps = {
  betAmount: number;
  realbetAmount: number;
  tickets: number;
  winLose: number;
};

export const Footer: NamedExoticComponent<FooterProps> = memo(
  ({
    betAmount,
    // realbetAmount,
    winLose,
  }) => {
    return (
      <tr className="font-bold">
        <td colSpan={2}>Total</td>
        <td></td>
        {/* <td className="text-right">
          {realbetAmount &&
            Number(realbetAmount.toFixed(2)).toLocaleString("en-US")}
        </td> */}
        <td className="text-right">
          {betAmount && Number(betAmount.toFixed(2)).toLocaleString("en-US")}
        </td>
        <td></td>
        <td className="text-right">
          {winLose && Number(winLose.toFixed(2)).toLocaleString("en-US")}
        </td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  }
);

const TicketTable: FC<{
  page?: number;
  limit?: number;
  betType?: BetType[];
  startDate?: string;
  endDate?: string;
  status?: string[];
  dateField?:string;
  onClickPagination?: (page: number) => void;
}> = ({
  page = 1,
  limit = 50,
  betType = [],
  startDate,
  endDate,
  onClickPagination = () => {},
  status,
  dateField
}) => {
  const getReportTickets = query.getReportTickets({
    page,
    limit,
    betType,
    startDate,
    endDate,
    username: "CURRENT",
    // status: ["running", "waiting"],
    status,
    dateField
  });

  const { data: tickets, isLoading } = useQuery(
    getReportTickets.key,
    getReportTickets.queryFn
  );

  return (
    <Display
      isLoading={isLoading}
      onClickPagination={onClickPagination}
      limit={limit}
      page={page}
      tickets={tickets}
    />
  );
};

export default TicketTable;
