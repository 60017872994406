export enum Sport {
  FOOTBALL = "FOOTBALL",
  BASKETBALL = "BASKETBALL",
  TABLETENNIS = "TABLETENNIS",
  HANDBALL = "HANDBALL",
  ICEHOCKEY = "ICEHOCKEY",
  TENNIS = "TENNIS",
  SNOOKER = "SNOOKER",
  BASEBALL = "BASEBALL",
  RUGBY = "RUGBY"
}
