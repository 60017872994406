import { FC } from "react";

const Placeholder: FC = () => {
  return (
    <div className="p-5">
      Your single Bet Slip is empty Click on odds in the table to add bets
    </div>
  );
};

export default Placeholder;
