import { FC, PropsWithChildren } from "react";
import { useQuery } from "@tanstack/react-query";
import capitalize from "lodash/capitalize";
// import Drawer from "@mui/material/Drawer";
// import CheckIcon from "@mui/icons-material/Check";
import { useNavigate, useLocation } from "react-router-dom";
// import BetTypeSelectorMobileButton from "../../../contrainers/BetTypeSelector/BetTypeSelectorMobile/BetTypeSelectorMobileButton";
// import { StyledButtonMobile } from "../../../contrainers/SportTable/styled";
// import RefreshMobile from "../../../components/Refresh/RefreshMobile";
import { NumberOfMatchType } from "../../../types/feed";
import query from "../../../query";
import classNames from "classnames";

// import useToggleState from "../../../hooks/useToggleState";
// import classNames from "classnames";
// import Button from "@material-tailwind/react/components/Button";

export type NumberOfMatchValue = { key: string[]; path: string };

export type MapNumberOfMatchTypeKey = {
  live: NumberOfMatchValue;
  today: NumberOfMatchValue;
  "live-today"?: NumberOfMatchValue;
  early?: NumberOfMatchValue;
  palay?: NumberOfMatchValue;
  combo?: NumberOfMatchValue;
};

export type SportTableMobileProps = {
  isBetTypeSelectorDisabled?: boolean;
  sport: string;
  type: string;
  title: string;
};

const BetTypeSelectorMobile: FC<PropsWithChildren<SportTableMobileProps>> = ({
  sport = "football",
  type = "today",
  isBetTypeSelectorDisabled = false,
  children,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const numberOfMatchTypeQuery = query.numberOfMatchType();
  const numberOfMatchType = useQuery(
    numberOfMatchTypeQuery.key,
    numberOfMatchTypeQuery.queryFn,
    {
      refetchOnMount: false,
      keepPreviousData: true,
    }
  );

  const betTypeQuery = query.betType();
  const betType = useQuery(betTypeQuery.key, betTypeQuery.queryFn, {
    refetchOnMount: false,
    keepPreviousData: true,
  });

  let sportFeedQuery;
  let mapNumberOfMatchTypeKey: MapNumberOfMatchTypeKey;
  // let numberOfMatchTypeKey: any;

  switch (sport) {
    case "badminton": {
      sportFeedQuery = query.badmintonFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );
      mapNumberOfMatchTypeKey = {
        live: { key: ["badmintonLive"], path: "/badminton/live" },
        today: { key: ["badmintonToday"], path: "/badminton/today" },
      };
      // numberOfMatchTypeKey =
      //   mapNumberOfMatchTypeKey[type as keyof MapNumberOfMatchTypeKey]?.key;
      break;
    }

    case "americanfootball": {
      sportFeedQuery = query.americanfootballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );
      mapNumberOfMatchTypeKey = {
        live: { key: ["americanFootballLive"], path: "/americanfootball/live" },
        today: {
          key: ["americanFootballToday"],
          path: "/americanfootball/today",
        },
      };
      // numberOfMatchTypeKey =
      //   mapNumberOfMatchTypeKey[type as keyof MapNumberOfMatchTypeKey]?.key;
      break;
    }

    case "rugby": {
      sportFeedQuery = query.rugbyFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );
      mapNumberOfMatchTypeKey = {
        live: { key: ["rbLive"], path: "/rugby/live" },
        today: { key: ["rbToday"], path: "/rugby/today" },
      };
      // numberOfMatchTypeKey =
      //   mapNumberOfMatchTypeKey[type as keyof MapNumberOfMatchTypeKey]?.key;
      break;
    }

    case "baseball": {
      sportFeedQuery = query.baseballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );
      mapNumberOfMatchTypeKey = {
        live: { key: ["bbLive"], path: "/baseball/live" },
        today: { key: ["bbToday"], path: "/baseball/today" },
      };
      // numberOfMatchTypeKey =
      //   mapNumberOfMatchTypeKey[type as keyof MapNumberOfMatchTypeKey]?.key;
      break;
    }

    case "snooker": {
      sportFeedQuery = query.snookerFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );
      mapNumberOfMatchTypeKey = {
        live: { key: ["skLive"], path: "/snooker/live" },
        today: { key: ["skToday"], path: "/snooker/today" },
      };
      // numberOfMatchTypeKey =
      //   mapNumberOfMatchTypeKey[type as keyof MapNumberOfMatchTypeKey]?.key;
      break;
    }

    case "icehockey": {
      sportFeedQuery = query.icehockeyFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );
      mapNumberOfMatchTypeKey = {
        live: { key: ["ihLive"], path: "/icehockey/live" },
        today: { key: ["ihToday"], path: "/icehockey/today" },
      };
      // numberOfMatchTypeKey =
      //   mapNumberOfMatchTypeKey[type as keyof MapNumberOfMatchTypeKey]?.key;
      break;
    }

    case "handball": {
      sportFeedQuery = query.handballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );
      mapNumberOfMatchTypeKey = {
        live: { key: ["hbLive"], path: "/handball/live" },
        today: { key: ["hbToday"], path: "/handball/today" },
      };
      // numberOfMatchTypeKey =
      //   mapNumberOfMatchTypeKey[type as keyof MapNumberOfMatchTypeKey]?.key;
      break;
    }

    case "tennis": {
      sportFeedQuery = query.tennisFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );
      mapNumberOfMatchTypeKey = {
        live: { key: ["tnLive"], path: "/tennis/live" },
        today: { key: ["tnToday"], path: "/tennis/today" },
      };
      // numberOfMatchTypeKey =
      //   mapNumberOfMatchTypeKey[type as keyof MapNumberOfMatchTypeKey]?.key;
      break;
    }

    case "tabletennis": {
      sportFeedQuery = query.tabletennisFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );
      mapNumberOfMatchTypeKey = {
        live: { key: ["ttLive"], path: "/tabletennis/live" },
        today: { key: ["ttToday"], path: "/tabletennis/today" },
      };
      // numberOfMatchTypeKey =
      //   mapNumberOfMatchTypeKey[type as keyof MapNumberOfMatchTypeKey]?.key;
      break;
    }

    case "basketball": {
      sportFeedQuery = query.basketballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );
      mapNumberOfMatchTypeKey = {
        live: { key: ["bsLive"], path: "/basketball/live" },
        today: { key: ["bsToday"], path: "/basketball/today" },
        combo: { key: ["comboStep"], path: "/combo-step" },
      };
      // numberOfMatchTypeKey =
      //   mapNumberOfMatchTypeKey[type as keyof MapNumberOfMatchTypeKey]?.key;
      break;
    }
    case "volleyball": {
      sportFeedQuery = query.basketballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );
      mapNumberOfMatchTypeKey = {
        live: { key: ["vbLive"], path: "/volleyball/live" },
        today: { key: ["vbToday"], path: "/volleyball/today" },
        // combo: { key: ["comboStep"], path: "/combo-step" },
      };
      // numberOfMatchTypeKey =
      //   mapNumberOfMatchTypeKey[type as keyof MapNumberOfMatchTypeKey]?.key;
      break;
    }

    default:
      sportFeedQuery = query.footballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );
      mapNumberOfMatchTypeKey = {
        live: { key: ["fbLive"], path: "/football/live" },
        today: { key: ["fbToday"], path: "/football/today" },
        early: { key: ["fbEarly"], path: "/football/early" },
        palay: { key: ["mixParlay"], path: "/football-parlay" },
        combo: { key: ["comboStep"], path: "/combo-step" },
      };
      // numberOfMatchTypeKey =
      //   mapNumberOfMatchTypeKey[type as keyof MapNumberOfMatchTypeKey]?.key;
      break;
  }

  const sportFeed = useQuery(sportFeedQuery.key, sportFeedQuery.queryFn);
  // const { isToggled, toggle } = useToggleState();
  // const isLive = type === "live";
  // const [sportTitle, typeTitle] = title.split("/");
  return (
    <>
      <div className="mobile:pt-5">
        {!isBetTypeSelectorDisabled && (
          <div className="overflow-x-scroll flex pb-2">
            {Object.keys(mapNumberOfMatchTypeKey).map((key) => (
              <button
                className={classNames(
                  "rounded-full bg-white px-3 text-sm mx-2 py-1 flex flex-nowrap",
                  {
                    "font-bold text-green-800":
                      mapNumberOfMatchTypeKey[
                        key as keyof MapNumberOfMatchTypeKey
                      ]?.path === location.pathname,
                  }
                )}
                key={key}
                style={{ border: "1px solid #115e3f" }}
                onClick={() => {
                  navigate(
                    mapNumberOfMatchTypeKey[
                      key as keyof MapNumberOfMatchTypeKey
                    ]?.path ?? ""
                  );
                }}
              >
                <span className="mr-1">{capitalize(key)}</span>
                <span>
                  (
                  {mapNumberOfMatchTypeKey?.[
                    key as keyof MapNumberOfMatchTypeKey
                  ]?.key?.reduce(
                    (total: number, keyB) =>
                      total +
                      (numberOfMatchType?.data?.[
                        keyB as keyof NumberOfMatchType
                      ] ?? 0),
                    0
                  )}
                  )
                </span>
              </button>
            ))}
            <button
              className="rounded-full bg-white px-3 text-sm"
              style={{ border: "1px solid #115e3f" }}
              onClick={() => {
                sportFeed.refetch();
              }}
            >
              Refresh
            </button>
            {/* <BetTypeSelectorMobileButton
              onClick={toggle}
              label={`${capitalize(sportTitle)} - ${capitalize(typeTitle)}`}
              isLive={isLive}
              badgeNo={numberOfMatchTypeKey?.reduce(
                (total, key) =>
                  total +
                  (numberOfMatchType?.data?.[key as keyof NumberOfMatchType] ??
                    0),
                0
              )}
            /> */}
            {/* <StyledButtonMobile className="mx-2">
              <RefreshMobile
                key={type}
                onClick={() => {
                  sportFeed.refetch();
                }}
                isLoading={sportFeed.isLoading}
                initialCounter={type === "live" ? 20 : 60}
              />
            </StyledButtonMobile> */}
          </div>
        )}

        <div className="my-3">{children}</div>
      </div>
      {/* <Drawer open={isToggled} onClose={toggle} anchor="bottom">
        {Object.keys(mapNumberOfMatchTypeKey).map((key) => {
          return (
            <BetTypeSelectorMobileButton
              key={key}
              className={classNames("border-b border-gray-200 p-3", {
                "font-bold": key === type,
              })}
              label={`${capitalize(sport)} - ${capitalize(
                key.replace("-", " & ")
              )}`}
              isLive={key === "live"}
              badgeNo={mapNumberOfMatchTypeKey[
                key as keyof MapNumberOfMatchTypeKey
              ]?.key.reduce(
                (total, key) =>
                  total +
                  (numberOfMatchType?.data?.[key as keyof NumberOfMatchType] ??
                    0),
                0
              )}
              Icon={<CheckIcon style={{ fontSize: 22, color: "green" }} />}
              isShowIcon={key === type}
              onClick={() => {
                navigate(
                  mapNumberOfMatchTypeKey[key as keyof MapNumberOfMatchTypeKey]
                    ?.path ?? ""
                );
                toggle();
              }}
            />
          );
        })}
      </Drawer> */}
    </>
  );
};

export default BetTypeSelectorMobile;
