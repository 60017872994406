export const convertHDP = (hdp?: string, isDisplayPlus?: boolean) => {
  if (!hdp) return "";
  const isMinus = hdp.includes("-");
  const isZero = Number(hdp) === 0
 
  const [first, second] = hdp?.replace("-", "")?.split("/") ?? [];
  if (second) {
    return (
      (!isMinus && isDisplayPlus && !isZero ? "+" : "") +
      Number((((Number(first) + Number(second)) / 2) * (isMinus ? -1 : 1)).toFixed(2))
    );
  }

  return (!isMinus && isDisplayPlus && !isZero ? "+" : "") + Number(Number(hdp).toFixed(2));
};
