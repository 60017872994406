import styled from "styled-components";

const Badge = styled.span`
  background-color: red;
  padding: 2px 4px;
  border-radius: 20px;
  font-weight: bold;
  color: white;
`;

export default Badge;
