import styled from "styled-components";

export const TopNavWrapper = styled.div`
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
`;

export const Background = styled.div`
  background-color: ${({ theme }) => theme.global.BackgroundOutside};
  min-height: 100vh;
`;

export const FooterMenuWrapper = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
`;
