import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import mutation from "../../mutation";
import SportTableLiveAndToday from "../../contrainers/SportTableLiveAndToday";
import useRedirectLiveAndToday from "../../hooks/useRedirectLiveAndToday"

const BasketballLiveAndTodayPage = () => {
  useRedirectLiveAndToday("basketball")
  const queryClient = useQueryClient();
  const updateBetTicketInfoPayload = useMutation(
    mutation.updateBetTicketInfoPayload,
    {
      onSuccess: (payload) => {
        queryClient.setQueryData(["betTicketInfoPayload"], payload);
      },
    }
  );

  const updateBetType = useMutation(mutation.updateBetType, {
    onSuccess: (value) => {
      queryClient.setQueryData(["betType"], value);
    },
  });

  const updateOddsType = useMutation(mutation.updateOddsType, {
    onSuccess: (value) => {
      queryClient.setQueryData(["oddsType"], value);
    },
  });

  useEffect(() => {
    updateBetTicketInfoPayload.mutate([]);
    updateBetType.mutate("normal");
    updateOddsType.mutate(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SportTableLiveAndToday
      liveTitle="basketball/live"
      todayTitle="basketball/today"
      sport="basketball"
    />
  );
};

export default BasketballLiveAndTodayPage;
