import { FC, useState, useDeferredValue } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Input from "../../components/Input";
import Checkbox from "../../components/Checkbox";
import { Button, ButtonType } from "../../components/Button";
import { t } from "i18next";

export type FormData = {
  isAllSelected: boolean;
  selectedLeagues: string[];
};

export type LeagueFilterFormProps = {
  onClose?: () => void;
  onSubmit?: (data: FormData) => void;
  options?: { label: string; value: string }[];
  defaultIsAllSelected?: boolean;
  defaultSelectedLeagues?: string[];
};

const LeagueFilterForm: FC<LeagueFilterFormProps> = ({
  onClose,
  options = [],
  defaultIsAllSelected = true,
  defaultSelectedLeagues = [],
  onSubmit = () => {},
}) => {
  const { handleSubmit, control, setValue } = useForm<FormData>({
    defaultValues: {
      isAllSelected: defaultIsAllSelected,
      selectedLeagues: defaultSelectedLeagues,
    },
  });
  const [search, setSearch] = useState<string>("");
  const deferredSearch = useDeferredValue(search);
  const selectedLeagues = useWatch({ control, name: "selectedLeagues" });
  const isAllSelected = useWatch({ control, name: "isAllSelected" });

  const length = options?.length ?? 0;
  const selectedLeaguesNo = isAllSelected
    ? length - selectedLeagues.length
    : selectedLeagues.length;
  const filteredOptions = options.filter(
    (option) => option.label.search(deferredSearch) > -1
  );

  return (
    <form
      className="flex flex-col"
      style={{ minHeight: "100%" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex py-4 justify-between items-center">
        <h1 className="font-bold text-2xl">{t("League")}</h1>
        <button onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <div className="p-3 border-gray-300 border-t border-x rounded-t-md flex items-center justify-between">
        <Input
          startAdornment={<SearchIcon className="absolute left-0 top-2" />}
          className="w-6/12 pl-8 relative"
          placeholder={t("Search") as string}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          value={search}
        />
        <div>
          <Controller
            name="isAllSelected"
            control={control}
            render={({ field }) => (
              <label className="cursor-pointer">
                <Checkbox
                  checked={field.value}
                  onChange={(event) => {
                    setValue("selectedLeagues", []);
                    field.onChange((event.target as HTMLInputElement).checked);
                  }}
                />
                {t("Select / Cancel all")}
              </label>
            )}
          />
        </div>
        <div>
          {selectedLeaguesNo}/{length} {t("selected")}
        </div>
      </div>
      <Controller
        name="selectedLeagues"
        control={control}
        render={({ field }) => (
          <div className="flex flex-wrap overflow-auto border-gray-300 border rounded-b-md">
            {filteredOptions?.length === 0 && (
              <div className="p-8 text-center w-full text-gray-400 text-xl">
                {t("League is not found")}
              </div>
            )}
            {filteredOptions?.map((data: any) => {
              const leagueId = `${data.value}`;
              const isChecked = field.value.includes(leagueId);

              return (
                <div key={data.value} className="w-6/12 p-3">
                  <label className="cursor-pointer">
                    <Checkbox
                      checked={isAllSelected ? !isChecked : isChecked}
                      onChange={() => {
                        if (isChecked) {
                          field.onChange(
                            field.value.filter(
                              (league: string) => league !== leagueId
                            )
                          );
                        } else {
                          field.onChange([...field.value, leagueId]);
                        }
                      }}
                    />
                    {data.label}
                  </label>
                </div>
              );
            })}
          </div>
        )}
      />

      <div className="py-4 flex space-x-5 justify-center">
        <Button className="px-2" type="submit" buttonType={ButtonType.PRIMARY}>
          {t("Confirm")}
        </Button>
        <Button onClick={onClose}>{t("Cancel")}</Button>
      </div>
    </form>
  );
};

export default LeagueFilterForm;
