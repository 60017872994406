import { FC, memo } from "react";
import classNames from "classnames";

import Market from "./Market";
import { Market as MarketType } from "../../types/cricketFeed";
import {
  BetTicketInfoForDisplay,
  BetTicketInfoPayload,
} from "../../types/betTicket";

export type MarketCardProps = {
  leagueId: string;
  matchId: string;
  data: MarketType[];
  isLive?: boolean;
  homeTeam: string;
  awayTeam: string;
  leagueName: string;
  onClickOddPrice?: (
    payload: BetTicketInfoPayload & BetTicketInfoForDisplay
  ) => void;
};

const MarketCard: FC<MarketCardProps> = ({
  leagueId,
  data,
  isLive,
  onClickOddPrice,
  matchId,
  homeTeam,
  awayTeam,
  leagueName,
}) => {
  return (
    <div className="grid grid-cols-2 gap-4 text-sm">
      {data.map(({ name, marketId, selection, position }) => (
        <div
          key={name}
          className={classNames({
            "col-span-2": position === "center",
          })}
        >
          {Boolean(selection.length) && (
            <Market
              leagueId={leagueId}
              matchId={matchId}
              onClickOddPrice={onClickOddPrice}
              isLive={isLive}
              name={name}
              marketId={marketId}
              selection={selection}
              position={position}
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              leagueName={leagueName}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default memo(MarketCard);
