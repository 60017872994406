import axios from "axios";
import { CONFIG } from "../../config";
import { Sport } from "../../types/sport";
import { BetType } from "../../types/common";
import { CricketFeed } from "../../types/cricketFeed";
class FeedService {
  public getNumberOfMatchType = async () => {
    const result = await axios.get(
      `${CONFIG.API_URL}/new-iframe/numberofmatches`
    );

    return result?.data?.data;
  };

  public getResult = async (sportType: Sport, isToday?: boolean) => {
    const sportTypeText = `${sportType}`.toLowerCase();
    const todayParam = isToday ? "td" : "ld";
    const result = await axios.get(
      `${CONFIG.API_URL}/c/m/rs/${todayParam}/${sportTypeText}`
    );

    return result?.data?.data;
  };

  public getFootball = async (
    type?: "today" | "live" | "early",
    betType?: BetType
  ) => {
    const betTypeParam = betType ?? "normal";

    const mapBetType = {
      normal: "fb",
      step: "fbStep",
      comboStep: "comboFb",
    };

    const result = await axios.get(
      `${CONFIG.API_URL}/c/m/feed/${mapBetType[betTypeParam]}/${type}`
    );

    return result?.data?.data;
  };

  public getBasketball = async (
    type?: "today" | "live" | "early",
    betType?: BetType
  ) => {
    const betTypeParam = betType ?? "normal";

    const mapBetType = {
      normal: "bb",
      step: "bbStep",
      comboStep: "comboBb",
    };
    const result = await axios.get(
      `${CONFIG.API_URL}/c/m/feed/${mapBetType[betTypeParam]}/${type}`
    );

    return result?.data?.data;
  };

  public getVolleyball = async (
    type?: "today" | "live" | "early",
    betType?: BetType
  ) => {
    const betTypeParam = betType ?? "normal";

    const mapBetType = {
      normal: "vb",
      step: "vbStep",
      comboStep: "comboVb",
    };
    const result = await axios.get(
      `${CONFIG.API_URL}/c/m/feed/${mapBetType[betTypeParam]}/${type}`
    );

    return result?.data?.data;
  };

  public getTabletennis = async (
    type?: "today" | "live" | "early",
    betType?: BetType
  ) => {
    const betTypeParam = betType ?? "normal";

    const mapBetType = {
      normal: "tt",
      step: "ttStep",
      comboStep: "comboTt",
    };
    const result = await axios.get(
      `${CONFIG.API_URL}/c/m/feed/${mapBetType[betTypeParam]}/${type}`
    );

    return result?.data?.data;
  };

  public getHandball = async (
    type?: "today" | "live" | "early",
    betType?: BetType
  ) => {
    const betTypeParam = betType ?? "normal";

    const mapBetType = {
      normal: "hb",
      step: "hbStep",
      comboStep: "combohb",
    };
    const result = await axios.get(
      `${CONFIG.API_URL}/c/m/feed/${mapBetType[betTypeParam]}/${type}`
    );

    return result?.data?.data;
  };

  public getIcehockey = async (
    type?: "today" | "live" | "early",
    betType?: BetType
  ) => {
    const betTypeParam = betType ?? "normal";

    const mapBetType = {
      normal: "ih",
      step: "ihStep",
      comboStep: "comboih",
    };
    const result = await axios.get(
      `${CONFIG.API_URL}/c/m/feed/${mapBetType[betTypeParam]}/${type}`
    );

    return result?.data?.data;
  };

  public getTennis = async (
    type?: "today" | "live" | "early",
    betType?: BetType
  ) => {
    const betTypeParam = betType ?? "normal";

    const mapBetType = {
      normal: "tn",
      step: "tnStep",
      comboStep: "combotn",
    };
    const result = await axios.get(
      `${CONFIG.API_URL}/c/m/feed/${mapBetType[betTypeParam]}/${type}`
    );

    return result?.data?.data;
  };

  public getSnooker = async (
    type?: "today" | "live" | "early",
    betType?: BetType
  ) => {
    const betTypeParam = betType ?? "normal";

    const mapBetType = {
      normal: "sn",
      step: "snStep",
      comboStep: "combosn",
    };
    const result = await axios.get(
      `${CONFIG.API_URL}/c/m/feed/${mapBetType[betTypeParam]}/${type}`
    );

    return result?.data?.data;
  };

  public getBaseball = async (
    type?: "today" | "live" | "early",
    betType?: BetType
  ) => {
    const betTypeParam = betType ?? "normal";

    const mapBetType = {
      normal: "baseball",
      step: "baseballStep",
      comboStep: "combobaseball",
    };
    const result = await axios.get(
      `${CONFIG.API_URL}/c/m/feed/${mapBetType[betTypeParam]}/${type}`
    );

    return result?.data?.data;
  };

  public getRugby = async (
    type?: "today" | "live" | "early",
    betType?: BetType
  ) => {
    const betTypeParam = betType ?? "normal";

    const mapBetType = {
      normal: "rugby",
      step: "rugbyStep",
      comboStep: "comborugby",
    };
    const result = await axios.get(
      `${CONFIG.API_URL}/c/m/feed/${mapBetType[betTypeParam]}/${type}`
    );

    return result?.data?.data;
  };

  public getAmericanfootball = async (
    type?: "today" | "live" | "early",
    betType?: BetType
  ) => {
    const betTypeParam = betType ?? "normal";

    const mapBetType = {
      normal: "american_football",
      step: "american_footballStep",
      comboStep: "comboamerican_football",
    };
    const result = await axios.get(
      `${CONFIG.API_URL}/c/m/feed/${mapBetType[betTypeParam]}/${type}`
    );

    return result?.data?.data;
  };

  public getBadminton = async (
    type?: "today" | "live" | "early",
    betType?: BetType
  ) => {
    const betTypeParam = betType ?? "normal";

    const mapBetType = {
      normal: "badminton",
      step: "badmintonStep",
      comboStep: "combobadminton",
    };
    const result = await axios.get(
      `${CONFIG.API_URL}/c/m/feed/${mapBetType[betTypeParam]}/${type}`
    );

    return result?.data?.data;
  };

  public getCricket = async (
    type?: "today" | "live" | "early"
  ): Promise<CricketFeed[]> => {
    const result = await axios.get(
      `${CONFIG.API_URL}/c/m/feed/cricket/${type}`
    );

    return result?.data?.data;
  };

  public getSpecialFootball = async (matchId: number) => {
    const result = await axios.get(
      `https://dev-form-odds.askmebet.cloud/form/special-price/soccer/${matchId}`
    );

    return result?.data;
  };

  public getTopeventFoottabll = async () => {
    const result = await axios.get(
      `${CONFIG.API_URL}/new-iframe/topevent/FOOTBALL`
    );

    return result?.data?.data;
  };
}

export default FeedService;
